// External Libraries
import React, { useEffect } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// API hooks
import { useFetchExperienceList } from '../../../api/ExperiencesAPI';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';

const ExperienceList = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const { experienceList, loading, error } = useFetchExperienceList(i18n.resolvedLanguage);

  return (
    <Container>
      <Card>
        <CardHeader className="admin-text" tag={'h4'}>
          {t('menu.content')}
        </CardHeader>
        <CardBody>
          {!experienceList && loading && <Loading />}
          {!experienceList && error && <div>{t('tables.error')}</div>}
          {!experienceList && <Alert color="light">{t('tables.empty')}</Alert>}
          {experienceList && (
            <Table striped>
              <thead>
                <tr>
                  <th className="text-center experiences__column-name">{t('tables.name')}</th>
                  <th className="experiences__column-questions">{t('content.questionsCount')}</th>
                  <th className="text-center">Active</th>
                  <th className="text-center">{t('tables.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {experienceList.map((row) => (
                  <tr key={row.experience_id}>
                    <td className="text-center experiences__column-name">{row.name}</td>
                    <td className="experiences__column-questions">{row.questions}</td>
                    <td className="text-center">{row.active ? 'Yes' : 'No'}</td>
                    <td className="text-center">
                      <Button color="link" size="sm" onClick={() => navigate(`/experiences/${row.experience_id}`)}>
                        {t('content.editDetails')}
                      </Button>
                      <Button
                        color="link"
                        size="sm"
                        onClick={() => navigate(`/experiences/${row.experience_id}/questions`)}
                      >
                        {t('content.seeQuestions')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ExperienceList;
