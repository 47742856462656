import { useCallback, useEffect, useState } from 'react';
import { useCreateMetric, useFetchMetricById, useUpdateMetric } from '../../api/MetricAPI';
import { DECIMAL_REGEXP } from '../../utils/constants';
import { useActor } from '../../storage/storage';

const useMetricForm = (id) => {
  const { getMetricById } = useFetchMetricById();
  const { updateMetric } = useUpdateMetric();
  const { createMetric } = useCreateMetric();
  const actor = useActor();
  const [metric, setMetric] = useState({
    name: '',
    bronze: '',
    silver: '',
    gold: '',
    direction: 'up',
    lower: '',
    upper: '',
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchMetric = async () => {
      if (!id) return;
      try {
        const metricData = await getMetricById(id);
        setMetric(metricData);
      } catch (err) {
        setError(err.message || 'Failed to fetch metric data.');
      }
    };

    fetchMetric();
  }, [id, getMetricById]);

  const handleInputName = useCallback((event) => {
    const { name, value } = event.target;

    setMetric((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleDirectionChange = useCallback((e) => {
    const { value } = e.target;
    setMetric((prev) => ({
      ...prev,
      direction: value,
    }));
  }, []);

  const handleInputValue = useCallback(
    (event) => {
      const { name, value } = event.target;

      if (!DECIMAL_REGEXP.test(value)) {
        event.preventDefault();
        return;
      }
      setMetric((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [setMetric]
  );

  const clearSuggested = useCallback(() => {
    setMetric((prevData) => ({
      ...prevData,
      silver: '',
      upper: '',
      lower: '',
    }));
  }, [setMetric]);

  const calculateSuggested = useCallback(() => {
    if (metric.bronze === null || metric.gold === null) return;
    const bronzeValue = parseFloat(metric.bronze);
    const goldValue = parseFloat(metric.gold);
    const silverValue = parseFloat((bronzeValue + goldValue) / 2);
    const step = goldValue - silverValue;
    setMetric((prevData) => ({
      ...prevData,
      silver: silverValue,
      upper: goldValue + step,
      lower: bronzeValue - step,
    }));
  }, [metric]);

  const validate = useCallback(() => {
    if (!metric.name) return 'The name is required';
    if (!metric.direction) return 'Direction is required';
    if (metric.bronze === undefined || isNaN(parseFloat(metric.bronze))) return 'The bronze level is required';
    if (metric.silver === undefined || isNaN(parseFloat(metric.silver))) return 'The silver level is required';
    if (metric.gold === undefined || isNaN(parseFloat(metric.gold))) return 'The gold level is required';
    if (metric.lower === undefined || isNaN(parseFloat(metric.lower))) return 'Lower is required';
    if (metric.upper === undefined || isNaN(parseFloat(metric.upper))) return 'Upper is required';
    if (metric.direction === 'up' && parseFloat(metric.bronze) >= parseFloat(metric.silver))
      return 'Bronze must be lower than silver';
    if (metric.direction === 'up' && parseFloat(metric.bronze) >= parseFloat(metric.gold))
      return 'Bronze must be lower than gold';
    if (metric.direction === 'up' && parseFloat(metric.silver) >= parseFloat(metric.gold))
      return 'Silver must be lower than gold';
    if (metric.direction === 'down' && parseFloat(metric.bronze) <= parseFloat(metric.silver))
      return 'Bronze must be greater than silver';
    if (metric.direction === 'down' && parseFloat(metric.bronze) <= parseFloat(metric.gold))
      return 'Bronze must be greater than gold';
    if (metric.direction === 'down' && parseFloat(metric.silver) <= parseFloat(metric.gold))
      return 'Silver must be greater than gold';
    return null;
  }, [metric]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);

      setLoading(true);
      const validationError = validate();
      if (validationError) {
        setError(validationError);
        setLoading(false);
        return;
      }
      try {
        if (id) {
          await updateMetric(id, metric);
        } else {
          const enriched = {
            ...metric,
            companyId: actor.company_id,
          };
          await createMetric(enriched);
        }
        setRedirect(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [validate, createMetric, updateMetric, metric, id]
  );

  return {
    metric,
    error,
    loading,
    redirect,
    handleSubmit,
    handleInputName,
    handleDirectionChange,
    handleInputValue,
    clearSuggested,
    calculateSuggested,
  };
};

export default useMetricForm;
