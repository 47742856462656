import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import 'react-phone-number-input/style.css';

import { useActor } from '../../../storage/storage';
import AlertMessage from './AlertMessage';
import InvitationForm from './InvitationForm';
import { useFetchMembershipDetails, useMembershipsByCompany } from '../../../api/MembershipAPI';
import Loading from '../../../components/Loading';
import MembersTable from './MembersTable';

const InviteActor = ({ role }) => {
  const { membership, error: membershipError, loading, fetchMembershipDetails } = useFetchMembershipDetails();
  const { memberships, fetchMembershipsByCompany } = useMembershipsByCompany();
  const actor = useActor();

  const [formError, setFormError] = useState(null);

  useEffect(() => {
    if (actor.company_id) fetchMembershipDetails(actor.company_id);
  }, [actor.company_id, fetchMembershipDetails]);

  useEffect(() => {
    if (actor.company_id) fetchMembershipsByCompany(actor.company_id, false);
  }, [fetchMembershipsByCompany, actor.company_id]);

  const phoneNumbers = memberships.map((member) => member.phone_number);

  if (loading) return <Loading />;

  const errorMessage = membershipError || formError;

  return (
    <>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      {membership && <AlertMessage membership={membership} />}
      {membership && (
        <InvitationForm
          role={role}
          formError={setFormError}
          refreshMemberships={fetchMembershipsByCompany}
          phoneNumbers={phoneNumbers}
          hasCapacity={membership.hasCapacity}
        />
      )}
      {memberships && <MembersTable actionError={setFormError} memberships={memberships} pending={true} />}
    </>
  );
};

export default InviteActor;
