import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from './state/store';

import App from './App';
import './index.css';

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
  const returnTo = appState?.returnTo || window.location.pathname;
  const messageId = appState?.messageId;

  window.history.replaceState({}, document.title, messageId ? `${returnTo}?messageId=${messageId}` : returnTo);
};

const providerConfig = {
  domain: DOMAIN,
  clientId: CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/landing`,
    audience: 'api.galileilearning.com',
    location: window.location.href,
  },
  onRedirectCallback: onRedirectCallback,
};

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
