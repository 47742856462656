import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, Container } from 'reactstrap';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import ExperienceList from '../../components/content/ExperienceList';
import Loading from '../../../components/Loading';
import ReturnButton from '../../components/commons/ReturnButton';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ExperienceMan = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <Container>
      <ButtonGroup>
        <ReturnButton to="/landing" />
        <button className="btn btn-primary" onClick={() => navigate('new')}>
          {t('buttons.add')}
        </button>
      </ButtonGroup>
      <ExperienceList />
    </Container>
  );
};

export default withAuthenticationRequired(ExperienceMan, {
  onRedirecting: () => <Loading />,
});
