import { useDispatch } from 'react-redux';
import { useFetchQuestionsForGame } from '../../../api/GameAPI';
import { useCallback, useEffect, useState } from 'react';
import { useExperiencesForPlayer } from '../../../api/ExperiencesAPI';
import { setExperience, setQuestions, useActor, usePlayer } from '../../../storage/storage';

const useExperience = () => {
  const player = usePlayer();
  const actor = useActor();
  const dispatch = useDispatch();
  const { experiences, loading: loadingExperiences, fetchExperiencesForPlayer } = useExperiencesForPlayer();
  const { questions, loading: loadingQuestions, fetchQuestionsForGame } = useFetchQuestionsForGame();
  const [experienceId, setExperienceId] = useState(null);
  const [loading, setLoading] = useState(false);

  const selectExperience = useCallback(
    (id) => {
      setExperienceId(id);
      setExperience({ experience_id: id }, dispatch);
    },
    [setExperienceId]
  );

  useEffect(() => {
    setLoading(loadingExperiences || loadingQuestions);
  }, [loadingExperiences, loadingQuestions, setLoading]);

  useEffect(() => {
    if (!player) return;
    fetchExperiencesForPlayer(player.player_id);
  }, [player, fetchExperiencesForPlayer]);

  useEffect(() => {
    if (experiences.length === 0) return;
    selectExperience(experiences[0].experience_id);
  }, [experiences, selectExperience]);

  useEffect(() => {
    if (!experienceId) return;
    fetchQuestionsForGame(experienceId, actor.lang);
  }, [experienceId, fetchQuestionsForGame]);

  useEffect(() => {
    if (questions.length === 0) return;
    setQuestions(questions, dispatch);
  }, [questions, dispatch]);

  return {
    experiences,
    loading,
    selectExperience,
  };
};

export default useExperience;
