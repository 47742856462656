import {
  Button,
  Form,
  FormGroup,
  Alert,
  Label,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  Row,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  InputGroup,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import ReturnButton from '../../components/commons/ReturnButton';
import useJackpotForm from '../../hooks/useJackpotForm';
import Loading from '../../../components/Loading';
import { EN, SUPPORTED_LANGUAGES, useLangHandler } from '../../../api/LanguageAPI';
import React, { useState, useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useParams, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import '../../../i18n/i18n';
import 'react-datepicker/dist/react-datepicker.css';
import SelectImage from '../../components/commons/selectImage/SelectImage';
import useImageList from '../../hooks/useImageList';

const JackpotForm = () => {
  const { id } = useParams();
  const {
    jackpot,
    error,
    loading,
    redirect,
    handleSubmit,
    handleJackpotInput,
    addRequirement,
    handleRequirementInput,
    removeRequirement,
    handleDrawnBy,
    handleImage,
    handleImageCover,
  } = useJackpotForm(id);
  const { images, loading: loadingImages } = useImageList();
  const { t, i18n } = useTranslation();
  const { T } = useLangHandler();
  const [activeTab, setActiveTab] = useState(EN);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (redirect) return <Navigate to="/jackpot" />;
  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{id ? t('lottery.edit') : t('lottery.add')}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6" className="d-flex flex-column align-items-center justify-content-center">
                <SelectImage
                  customTitle="Preview Image"
                  images={images}
                  disabled={loadingImages}
                  onSelect={handleImage}
                  selected={jackpot.url}
                  current={jackpot.media_id}
                />
                <SelectImage
                  customTitle="Cover Image"
                  images={images}
                  disabled={loadingImages}
                  onSelect={handleImageCover}
                  selected={jackpot.cover}
                  current={jackpot.cover_media_id}
                />
              </Col>
              <Col>
                <Nav tabs>
                  {SUPPORTED_LANGUAGES.map((lang) => (
                    <NavItem key={lang}>
                      <NavLink href="#" onClick={() => toggle(lang)} active={activeTab === lang}>
                        {t(`content.language.${lang}`)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 border border-top-0">
                  <TabPane tabId={activeTab}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={T(jackpot?.name, activeTab)}
                        onChange={(e) => handleJackpotInput(e, activeTab)}
                      />
                      <Label className="admin-text" for="name">
                        {t('form.name')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <p className="mb-3 text-dark font-weight-bold">
                        <strong>{t('form.reqs')}</strong>
                      </p>
                      {jackpot &&
                        T(jackpot.requirements, activeTab)?.map((req, idx) => (
                          <InputGroup name="requirements">
                            <Input
                              value={T(req, activeTab) || ''}
                              onChange={(event) => handleRequirementInput(event, idx, activeTab)}
                            />
                            <Button
                              className="button-delete btn-danger"
                              disabled={loading}
                              onClick={() => removeRequirement(idx)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </InputGroup>
                        ))}
                    </FormGroup>
                    <FormGroup floating>
                      <Button className="button btn-default" disabled={loading} onClick={addRequirement}>
                        {t('buttons.addRequirement')} <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </FormGroup>
                    <FormGroup floating>
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        value={T(jackpot?.description, activeTab)}
                        onChange={(e) => handleJackpotInput(e, activeTab)}
                        style={{
                          height: '8rem',
                          resize: 'none',
                        }}
                      />
                      <Label className="admin-text" for="description">
                        {t('form.desc')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <p className="mb-3 text-dark font-weight-bold">Drawn By:</p>
                      <DatePicker selected={jackpot.drawn_by} minDate={new Date()} onChange={handleDrawnBy} />
                    </FormGroup>
                    <FormGroup className="my-2">
                      <ReturnButton to={'/jackpot'} />
                      <Button color="primary" type="submit" disabled={loading}>
                        {t('buttons.ok')}
                      </Button>
                    </FormGroup>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(JackpotForm, {
  onRedirecting: () => <Loading />,
});
