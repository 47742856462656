import { useEffect, useState } from 'react';
import useMetric from './useMetric';
import useGame from './useGame';
import useExperience from './useExperience';
import useTerms from './useTerms';
import useInventory from '../../hooks/useInventory';
import useTeam from '../../hooks/useTeam';

const useHome = () => {
  const { metric, loadingMetric } = useMetric();
  const { loadingGame } = useGame();
  const { loadingTerms } = useTerms();
  const { experiences, loading: loadingExperiences, selectExperience } = useExperience();
  useInventory();
  useTeam();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(loadingGame || loadingMetric || loadingTerms || loadingExperiences);
  }, [loadingGame, loadingMetric, loadingTerms, loadingExperiences, setLoading]);

  return {
    metric,
    experiences,
    loading,
    selectExperience,
  };
};

export default useHome;
