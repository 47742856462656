import { ButtonGroup } from 'reactstrap';

import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/commons/ReturnButton';
import ImagesTable from '../../components/commons/ImagesTable';
import AddButton from '../../components/commons/AddButton';
import { useNavigate } from 'react-router-dom';
import useImageList from '../../hooks/useImageList';
import MessageAlert from '../../components/commons/MessageAlert';

const ImageList = () => {
  const { loading, images, message } = useImageList();
  const navigate = useNavigate();

  if (loading) return <Loading />;

  return (
    <>
      <MessageAlert message={message} />
      <ButtonGroup>
        <ReturnButton to={'/landing'} />
        <AddButton action={() => navigate('/images/new')} />
      </ButtonGroup>
      <ImagesTable images={images} />
    </>
  );
};

export default withAuthenticationRequired(ImageList, {
  onRedirecting: () => <Loading />,
});
