import React, { useEffect } from 'react';

import { useSwitchLanguage } from '../../api/ActorAPI';
import { useTranslation } from 'react-i18next';
import { useActor } from '../../storage/storage';
import { EN, ES } from '../../api/LanguageAPI';
import Loading from '../../components/Loading';
import '../../i18n/i18n';

const LanguageSwitch = () => {
  const actor = useActor();
  const { loading, switchLanguage } = useSwitchLanguage();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const handleLanguageChange = async (targetLanguage) => {
    if (targetLanguage === i18n.resolvedLanguage) return;
    await switchLanguage(targetLanguage);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="language-title-container">
        <span className="language-title">{t('game.profile.languageChange')}</span>
      </div>
      <div className="language-container">
        <div className={i18n.resolvedLanguage === ES ? 'active' : ''} onClick={() => handleLanguageChange(ES)}>
          🇲🇽
          <p>{t('content.language.es')}</p>
        </div>
        <div className={i18n.resolvedLanguage === EN ? 'active' : ''} onClick={() => handleLanguageChange(EN)}>
          🇺🇸
          <p>{t('content.language.en')}</p>
        </div>
      </div>
    </>
  );
};

export default LanguageSwitch;
