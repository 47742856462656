import { Table } from 'reactstrap';
import { useLangHandler } from '../../../api/LanguageAPI';
import { formatDateTime } from '../../../utils/date';
import EmptyRow from '../commons/EmptyRow';

const AwardsTable = ({ awards }) => {
  const { T } = useLangHandler();
  return (
    <>
      <Table hover borderless responsive size="sm" striped className="text-center">
        <thead>
          <tr>
            <th>Granted on</th>
            <th>Player</th>
            <th>Pieces</th>
            <th>Tickets</th>
            <th>Reason</th>
            <th>Accepted?</th>
          </tr>
        </thead>
        <tbody>
          {awards.map((award) => (
            <tr key={award.award_id}>
              <td>{formatDateTime(award.created_at)}</td>
              <td>{award.full_name}</td>
              <td>{award.pieces}</td>
              <td>{award.tickets}</td>
              <td>{T(award.reason)}</td>
              <td>{award.accepted ? 'Yes' : 'No'}</td>
            </tr>
          ))}
          {awards.length === 0 && <EmptyRow colSpan={6} message={'No awards yet'} />}
        </tbody>
      </Table>
    </>
  );
};
export default AwardsTable;
