import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLangHandler } from '../../api/LanguageAPI';
import JigsawBuilder from '../JigsawBuilder';
import { DEFAULT_CATALOG_IMG } from '../../utils/constants';
import useImageUrl from '../hooks/useImageUrl';
import { useInventory } from '../../storage/storage';

const PrizeJigSaw = ({ prize, isLocked }) => {
  const inventory = useInventory();
  const prizeDetails = inventory.details[prize.prize_id];
  const { loadingImageUrl, url } = useImageUrl(prize.media_id);
  const { T } = useLangHandler();
  const navigate = useNavigate();
  const getCount = (pieces) => {
    return `${pieces.filter((e) => e > 0).length}/${pieces.length}`;
  };

  const handleDefaultImage = (e) => {
    e.target.src = DEFAULT_CATALOG_IMG[prize.level];
  };

  return (
    <div className="prize-container" onClick={() => navigate(`/prize-redemption/${prize.prize_id}`)}>
      <div className="prize-jigsaw-image">
        {(loadingImageUrl || !url) && <img src={DEFAULT_CATALOG_IMG[prize.level]} alt="jigsaw" />}
        {!loadingImageUrl && url && <img src={url} alt="jigsaw" onError={(e) => handleDefaultImage(e)} />}
        <div className="prize-jigsaw-grid">
          <JigsawBuilder isLocked={isLocked} pieces={prizeDetails.pieceCount} asIcon={false} />
        </div>
      </div>
      <div className="prize-jigsaw-info">
        <div className="jigsaw-info-title">
          <span>{T(prize.name)}</span>
          <div>
            <JigsawBuilder isLocked={false} pieces={prizeDetails.pieceCount} asIcon={true} />
            <span>{getCount(prizeDetails.pieceCount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

PrizeJigSaw.propTypes = {
  prize: PropTypes.shape({
    prize_id: PropTypes.string.isRequired,
    company_id: PropTypes.string.isRequired,
    name: PropTypes.objectOf(PropTypes.string).isRequired,
    level: PropTypes.number.isRequired,
    total_pieces: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    description: PropTypes.objectOf(PropTypes.string).isRequired,
    created_at: PropTypes.string.isRequired,
    pieceCount: PropTypes.arrayOf(PropTypes.number).isRequired,
    repeatedPieces: PropTypes.number.isRequired,
    uniquePieces: PropTypes.number.isRequired,
  }).isRequired,
  isLocked: PropTypes.bool.isRequired,
};

export default PrizeJigSaw;
