import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import RewardsTable from '../../components/commons/RewardsTable';
import ReturnButton from '../../components/commons/ReturnButton';
import MessageAlert from '../../components/commons/MessageAlert';
import useRewardList from '../../hooks/useRewardList';
import { Button, ButtonGroup } from '@mui/material';

const RewardList = () => {
  const { loading, rewards, message, pageInfo, disabled, setToMark, handleRedeem } = useRewardList();

  if (loading) return <Loading />;

  return (
    <>
      {message.isSet && <MessageAlert message={message} />}
      <ButtonGroup variant="contained">
        <ReturnButton to={'/landing'} />
        <Button onClick={handleRedeem} disabled={disabled}>
          Deliver
        </Button>
      </ButtonGroup>
      <RewardsTable rewards={rewards} pageInfo={pageInfo} setToMark={setToMark} />
    </>
  );
};

export default withAuthenticationRequired(RewardList, {
  onRedirecting: () => <Loading />,
});
