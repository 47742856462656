import { v4 as uuidV4 } from 'uuid';
import { useCallback, useEffect, useState } from 'react';
import { useFetchExperienceById, useUpdateQuestion } from '../../api/ExperiencesAPI';
import { SUPPORTED_LANGUAGES } from '../../api/LanguageAPI';
import { QUESTION_DIFFICULTY, QUESTION_PRIORITY_TEXT } from '../../utils/constants';

const MAX_OPTIONS_COUNT = 4;
const MIN_OPTIONS_COUNT = 2;
const DEFAULT_TEXT = SUPPORTED_LANGUAGES.reduce((acc, lang) => ({ ...acc, [lang]: '' }), {});

const createEmptyQuestion = () => ({
  id: uuidV4(),
  text: { ...DEFAULT_TEXT },
  options: Array(MAX_OPTIONS_COUNT)
    .fill(null)
    .map(() => ({
      id: uuidV4(),
      text: { ...DEFAULT_TEXT },
    })),
  priority: 0,
  difficulty: 0,
  validAnswerOptionId: null,
  image: null,
  mediaId: null,
  isHidden: false,
});

const useQuestionForm = (questionId, experienceId, initialQuestion = null) => {
  const { updateQuestion } = useUpdateQuestion();
  const { getExperienceById } = useFetchExperienceById();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const isNewQuestion = questionId === 'new';
  const [question, setQuestion] = useState(() => {
    if (initialQuestion) return initialQuestion;
    return isNewQuestion ? createEmptyQuestion() : null;
  });

  useEffect(() => {
    if (!experienceId) return setError('Experience ID is required.');

    if (isNewQuestion || initialQuestion) {
      setError(null);
      return;
    }

    const fetchExperience = async () => {
      setLoading(true);
      try {
        const experience = await getExperienceById(experienceId);
        const questionFound = experience.questions.find((q) => q.id === questionId);

        if (!questionFound) return setError('Invalid question');

        questionFound.text ||= { ...DEFAULT_TEXT };
        questionFound.options = questionFound.options.map((o) => ({
          ...o,
          text: o.text || { ...DEFAULT_TEXT },
        }));

        setQuestion(questionFound);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExperience();
  }, [experienceId, questionId, getExperienceById]);

  const handleQuestionInput = useCallback((event, language) => {
    setError(null);
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      text: { ...prevQuestion.text, [language]: event.target.value },
    }));
  }, []);

  const handleAnswerInput = useCallback((event, optionId, language) => {
    setError(null);
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      options: prevQuestion.options.map((option) =>
        option.id === optionId ? { ...option, text: { ...option.text, [language]: event.target.value } } : option
      ),
    }));
  }, []);

  const handleCorrectAnswerInput = useCallback((event) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      validAnswerOptionId: event.target.value,
    }));
  }, []);

  const handlePriorityChange = useCallback((event) => {
    setQuestion((prevQuestion) => {
      const newPriority = event.target.value ? QUESTION_PRIORITY_TEXT.indexOf(event.target.value) : null;
      return prevQuestion.priority === newPriority ? prevQuestion : { ...prevQuestion, priority: newPriority };
    });
  }, []);

  const handleDifficultyChange = useCallback((event) => {
    setQuestion((prevQuestion) => {
      const newDifficulty = event.target.value ? QUESTION_DIFFICULTY.indexOf(event.target.value) : null;
      return prevQuestion.difficulty === newDifficulty ? prevQuestion : { ...prevQuestion, difficulty: newDifficulty };
    });
  }, []);

  const handleAddQuestionOptions = () => {
    setQuestion((prevQuestion) => {
      if (prevQuestion.options.length >= MAX_OPTIONS_COUNT) return prevQuestion;
      return {
        ...prevQuestion,
        options: [...prevQuestion.options, { id: uuidV4(), text: { ...DEFAULT_TEXT } }],
      };
    });
  };

  const handleRemoveQuestionOptions = (optionId) => {
    setQuestion((prevQuestion) => {
      if (prevQuestion.options.length <= MIN_OPTIONS_COUNT) return prevQuestion;
      return {
        ...prevQuestion,
        options: prevQuestion.options.filter((o) => o.id !== optionId),
      };
    });
  };

  const handleImageUpdate = (image) => {
    setQuestion((prev) => ({ ...prev, mediaId: image.media_id, imageUrl: image.url, image: image.key }));
  };

  const handleRemoveImage = () => {
    setQuestion((prev) => ({ ...prev, mediaId: null, imageUrl: null, image: null }));
  };

  const handleHideQuestion = useCallback(
    async (question) => {
      if (!question || loading) return;

      const updatedQuestion = {
        ...question,
        isHidden: !Boolean(question?.isHidden),
      };
      setLoading(true);

      try {
        await updateQuestion(experienceId, updatedQuestion);
      } catch (_error) {
        console.error('Error updating question visibility:', _error);
        setError({ message: _error.message || 'Unknown error occurred!' });
      } finally {
        setLoading(false);
      }
    },
    [experienceId, updateQuestion, loading]
  );

  const handleSaveAndAdd = async () => {
    await handleSubmit();
    setQuestion(createEmptyQuestion());
  };

  const handleSubmit = useCallback(
    async (event) => {
      if (event?.preventDefault) event.preventDefault();
      if (!question || loading) return;
      setLoading(true);

      try {
        await updateQuestion(experienceId, question);
      } catch (_error) {
        console.error('Error updating question:', _error);
        setError({ message: _error.message || 'Unknown error occurred!' });
      } finally {
        setLoading(false);
      }
    },
    [experienceId, question, updateQuestion, loading]
  );

  return {
    question,
    error,
    loading,
    handleSaveAndAdd,
    handleQuestionInput,
    handleAnswerInput,
    handleCorrectAnswerInput,
    handlePriorityChange,
    handleDifficultyChange,
    handleAddQuestionOptions,
    handleRemoveQuestionOptions,
    handleHideQuestion,
    handleSubmit,
    handleImageUpdate,
    handleRemoveImage,
  };
};

export default useQuestionForm;
