import { useEffect } from 'react';
import { useCanTrooper } from '../../api/ActorAPI';
import { useActor } from '../../storage/storage';
import { hasRole } from '../../utils/roleUtil';

const useTrooper = () => {
  const actor = useActor();
  const { canTroop, loading: loadingCanTroop, fetchCanTroop } = useCanTrooper();

  useEffect(() => {
    if (!actor || !hasRole(actor, 'SUPREME_LEADER')) return;
    fetchCanTroop(actor.actor_id);
  }, [actor]);

  return {
    canTroop,
    loadingCanTroop,
  };
};

export default useTrooper;
