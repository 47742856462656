import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ConfirmModal = ({ isOpen, toggle, title, message, confirmText, cancelText, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <span className="text-dark"> {title || ''}</span>
      </ModalHeader>
      <ModalBody>
        <p className="text-dark">{message || 'Are you sure?'}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {cancelText || 'Cancel'}
        </Button>
        <Button color="danger" onClick={onConfirm}>
          {confirmText || 'Confirm'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
