import { useEffect } from 'react';
import { setIsPrivacyPolicyAccepted, useActor } from '../../../storage/storage';
import { useFetchAcceptedTerms } from '../../../api/ActorAPI';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useTerms = () => {
  const actor = useActor();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { terms, loading: loadingTerms, fetchAcceptedTerms } = useFetchAcceptedTerms();

  useEffect(() => {
    if (!actor) return;
    fetchAcceptedTerms();
  }, [actor]);

  useEffect(() => {
    if (!terms) return;
    if (terms.accepted_terms === false) setIsPrivacyPolicyAccepted(false, dispatch);
    if (terms.force_enter === true) navigate('/logout');
  }, [terms, setIsPrivacyPolicyAccepted]);
  return {
    loadingTerms,
  };
};

export default useTerms;
