import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { SUPPORTED_LANGUAGES } from '../../../api/LanguageAPI';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const MultiLangTabContent = ({ activeTab, toggle, children }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <>
      <Nav tabs>
        {SUPPORTED_LANGUAGES.map((lang) => (
          <NavItem key={lang}>
            <NavLink href="#" onClick={() => toggle(lang)} active={activeTab === lang}>
              {t(`content.language.${lang}`)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 border border-top-0">
        <TabPane tabId={activeTab}>{children}</TabPane>
      </TabContent>
    </>
  );
};

export default MultiLangTabContent;
