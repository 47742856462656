import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useCanOpenChest } from '../../api/ChestAPI';

import { isSameDay } from '../../utils/date';

import ExperienceCarousel from './components/ExperienceCarousel';
import TeamMetricBar from './components/teamMetricBar/TeamMetricBar';
import HomeButton from './components/HomeButton';
import '../../i18n/i18n';
import '../../assets/css/home.css';

import Loading from '../../components/Loading';
import { hasRole } from '../../utils/roleUtil';
import { useActor } from '../../storage/storage';

import useHome from './hooks/useHome';
import useAwards from '../hooks/useAwards';

const Home = () => {
  const { t, i18n } = useTranslation();
  const actor = useActor();
  const { experiences, metric, loading, selectExperience } = useHome();
  const isChestAvailable = useCanOpenChest();
  const { game, isPlayed } = useSelector((state) => state.game);
  const { questions } = useSelector((state) => state.questions);
  const { awards, loadingAwards } = useAwards();

  useEffect(() => {
    void i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const isMissionCompleted = game && isSameDay(game.started_at);

  if (loading || questions.length === 0) return <Loading />;

  if (!hasRole(actor, 'PLAYER')) return <Navigate to="/landing" />;
  if (!loading && isChestAvailable) return <Navigate to="/chest" />;
  if (!loading && game && !isPlayed) return <Navigate to="/questionary" />;

  return (
    <div className="home-container">
      <div className="experience-header fixed">
        {metric ? <TeamMetricBar metric={metric} /> : <h2>{t('game.home.comingSoon')}</h2>}
      </div>

      <div className="experience-body">
        <ExperienceCarousel experiences={experiences} selectExperience={selectExperience} />
        <div className="experience-button">
          {isMissionCompleted && <span>{t('game.home.timeUp')}</span>}
          <HomeButton isMissionCompleted={isMissionCompleted} isChestAvailable={isChestAvailable} />
        </div>
      </div>
    </div>
  );
};

export default Home;
