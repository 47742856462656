import React, { useMemo } from 'react';
import './TeamMetricBar.css';
import metricIcon from '../../../../assets/img/icons/metric.svg';
import bronzeImage from '../../../../assets/img/profile/bronze.svg';
import silverImage from '../../../../assets/img/profile/silver.svg';
import goldImage from '../../../../assets/img/profile/gold.svg';
import CategoryBadge from './categoryBadge/CategoryBadge';
const TeamMetricBar = ({ metric }) => {
  const { name, current, ...metrics } = metric;
  const [bronze, silver, gold] = [metrics['0'], metrics['1'], metrics['2']];
  const buildMetricTitle = useMemo(() => {
    const currentValue = Number(current);
    const currentFormatted = Number.isInteger(currentValue) ? currentValue : parseFloat(currentValue.toFixed(2));

    return <span>{`${name} = ${currentFormatted}%`}</span>;
  }, [name, current]);
  const { activeCategory, progressColor, backgroundColor } = useMemo(() => {
    if (current >= gold)
      return { activeCategory: 'Gold', progressColor: '#c1a32f', backgroundColor: 'rgba(193, 163, 47, 0.2)' };
    if (current >= silver)
      return { activeCategory: 'Silver', progressColor: '#4a81a5', backgroundColor: 'rgba(74, 129, 165, 0.2)' };
    if (current >= bronze)
      return { activeCategory: 'Bronze', progressColor: '#9c6529', backgroundColor: 'rgba(156, 101, 41, 0.2)' };
    return { activeCategory: 'None', progressColor: 'white', backgroundColor: '#252525' };
  }, [current, bronze, silver, gold]);

  return (
    <div className="team-metric-bar">
      <div className="team-metric-bar__title">
        <img src={metricIcon} alt="Icon" className="team-metric-bar__icon" />
        {buildMetricTitle}
      </div>

      <div className="team-metric-bar__progress-container" style={{ background: backgroundColor }}>
        <div className="team-metric-bar__progress">
          <div className="team-metric-bar__fill" style={{ width: `${current}%`, background: progressColor }} />
        </div>
      </div>

      <div className="team-metric-bar__categories">
        <CategoryBadge
          label="Bronze"
          isActive={activeCategory === 'Bronze'}
          image={bronzeImage}
          position={20}
          color={progressColor}
        />
        <CategoryBadge
          label="Silver"
          isActive={activeCategory === 'Silver'}
          image={silverImage}
          position={50}
          color={progressColor}
        />
        <CategoryBadge
          label="Gold"
          isActive={activeCategory === 'Gold'}
          image={goldImage}
          position={80}
          color={progressColor}
        />
      </div>
    </div>
  );
};

export default TeamMetricBar;
