import { useCallback, useEffect, useState } from 'react';
import { useCreatePrize, useFetchPrizeById, useUpdatePrize } from '../../api/PrizesAPI';
import { useActor } from '../../storage/storage';

const usePrizeForm = (id) => {
  const { getPrizeById } = useFetchPrizeById();
  const { updatePrize } = useUpdatePrize();
  const { createPrize } = useCreatePrize();
  const actor = useActor();
  const [prize, setPrize] = useState({
    name: {
      en: '',
      es: '',
    },
    level: 0,
    total_pieces: 4,
    stock: 0,
    description: {
      en: '',
      es: '',
    },
    is_premium: false,
    media_id: null,
    cover_media_id: null,
    url: null,
    coverUrl: null,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const fetchPrize = async () => {
      if (!id) return;
      try {
        const prizeData = await getPrizeById(id);
        setPrize(prizeData);
      } catch (err) {
        setError(err.message || 'Failed to fetch prize data.');
      }
    };

    fetchPrize();
  }, [id, getPrizeById]);

  const handlePrizeInput = useCallback((event, language) => {
    const { name, value } = event.target;
    setPrize((prevPrize) => ({
      ...prevPrize,
      [name]: name === 'name' || name === 'description' ? { ...prevPrize[name], [language]: value } : value,
    }));
  }, []);

  const handleChecked = useCallback((event) => {
    const { name, checked } = event.target;
    setPrize((prevPrize) => ({
      ...prevPrize,
      [name]: checked,
    }));
  }, []);

  const handleImage = (image) => {
    setPrize((prev) => ({ ...prev, url: image.url, media_id: image.media_id }));
  };

  const handleImageCover = (image) => {
    setPrize((prev) => ({ ...prev, coverUrl: image.url, cover_media_id: image.media_id }));
  };

  const validatePrize = useCallback(() => {
    if (!prize.name || (!prize.name.en && !prize.name.es)) return 'Prize name is required.';
    if (prize.level === undefined || prize.level === null) return 'Level is required.';
    if (!prize.total_pieces) return 'Pieces are required.';
    if (prize.stock == null) return 'Stock is required';
    if (prize.stock <= 0) return 'Stock must be greater than 0';
    if (prize.media_id == null) return 'Image is required';
    if (prize.cover_media_id == null) return 'Cover image is required';
  }, [prize, id]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);

      setLoading(true);
      const validationError = validatePrize();
      if (validationError) {
        setError(validationError);
        setLoading(false);
        return;
      }
      try {
        if (id) {
          await updatePrize(id, prize);
        } else {
          const newPrize = {
            ...prize,
            companyId: actor.company_id,
          };
          await createPrize(newPrize);
        }
        setRedirect(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [validatePrize, createPrize, updatePrize, id]
  );

  return {
    prize,
    error,
    loading,
    redirect,
    handleSubmit,
    handlePrizeInput,
    handleChecked,
    handleImage,
    handleImageCover,
  };
};

export default usePrizeForm;
