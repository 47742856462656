import { useEffect, useState } from 'react';
import { useGetImageUrl } from '../../api/ImageAPI';

const useImageUrl = (mediaId) => {
  const expirationTimeInMillis = 7 * 86400 * 1000; // 7 Days
  const { loading: loadingImageUrl, url: refreshedUrl, getImageUrl } = useGetImageUrl();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (!mediaId) return;
    const imageUrlStr = sessionStorage.getItem(mediaId);
    if (imageUrlStr == null) {
      getImageUrl(mediaId);
      return;
    }
    const imageUrl = JSON.parse(imageUrlStr);
    const diff = Date.now() - imageUrl.ts;
    if (diff > expirationTimeInMillis || imageUrl.url == null) {
      getImageUrl(mediaId);
      return;
    }
    setUrl(imageUrl.url);
  }, [mediaId, getImageUrl]);

  useEffect(() => {
    if (!refreshedUrl || !mediaId) return;
    sessionStorage.setItem(mediaId, JSON.stringify({ url: refreshedUrl, ts: Date.now() }));
    setUrl(refreshedUrl);
  }, [mediaId, refreshedUrl]);

  return {
    loadingImageUrl,
    url,
  };
};

export default useImageUrl;
