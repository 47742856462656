import { useEffect } from 'react';
import { useFetchMetric } from '../../../api/TeamAPI';
import { usePlayer } from '../../../storage/storage';

const useMetric = () => {
  const player = usePlayer();
  const { metric, loading: loadingMetric, fetchMetric } = useFetchMetric();

  useEffect(() => {
    if (!player) return;
    fetchMetric(player.team_id);
  }, [player, fetchMetric]);

  return {
    metric,
    loadingMetric,
  };
};

export default useMetric;
