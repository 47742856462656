import { useEffect } from 'react';
import { useActor } from '../../storage/storage';
import useMessageAlert from './useMessageAlert';
import { useAwardsByCompany } from '../../api/AwardAPI';

const useAwardList = () => {
  const { message, showError, cleanMessage } = useMessageAlert();
  const { loading, awards, error, fetchAwardsByCompany } = useAwardsByCompany();
  const actor = useActor();

  useEffect(() => {
    if (!actor) return;
    cleanMessage();
    fetchAwardsByCompany(actor.company_id);
  }, [fetchAwardsByCompany, actor]);

  useEffect(() => {
    if (!error) return;
    showError(error);
  }, [error, showError]);
  return {
    message,
    loading,
    awards,
  };
};

export default useAwardList;
