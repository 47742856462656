import { useCallback, useState } from 'react';
import { fetchData, patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useRedeemReward = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const redeemReward = async (id) => {
    try {
      setLoading(true);
      setError(null);
      await patchData(`${BACKEND_URL}/rewards/${id}`, {}, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while redeeming the reward.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while redeeming the reward.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, redeemReward };
};

const useRewardsByCompany = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [pageInfo, setPageInfo] = useState({});

  const fetchRewardsByCompany = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const { pageData, pageInfo } = await fetchData(`${BACKEND_URL}/company/${id}/rewards`, getAccessTokenSilently);
        setError(null);
        setRewards(pageData);
        setPageInfo(pageInfo);
      } catch (error) {
        console.error('Error fetching rewards.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching rewards.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { rewards, error, loading, pageInfo, fetchRewardsByCompany };
};

const useCountRewardsForRedemption = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCountRewardForRedemption = useCallback(
    async (playerId, prizeId) => {
      try {
        setLoading(true);
        setError(null);
        const { count } = await fetchData(`${BACKEND_URL}/player/${playerId}/prize/${prizeId}`, getAccessTokenSilently);
        return count;
      } catch (error) {
        console.error('Error fetching count of rewards.');
        const errorMessage =
          error.response?.data?.message || 'Unexpected error occurred while fetching count of rewards.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { error, loading, fetchCountRewardForRedemption };
};

export { useRewardsByCompany, useRedeemReward, useCountRewardsForRedemption };
