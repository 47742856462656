import React from 'react';

const JigsawBuilder = ({ isLocked, pieces, asIcon }) => {
  const fillBox = {
    transformBox: 'fill-box',
    transformOrigin: '50% 50%',
  };

  const show = (idx) => {
    return {
      fill: asIcon ? (pieces[idx] > 0 ? 'var(--x-04)' : 'var(--x-02)') : `rgb(0, 0, 0, ${pieces[idx] > 0 ? 0 : 0.75})`,
      stroke: asIcon ? 'var(--x-03)' : 'none',
      strokeWidth: 1,
    };
  };

  let styles = [
    {
      ...show(0),
    },
    {
      ...show(1),
      ...fillBox,
    },
    {
      ...show(2),
      ...fillBox,
    },
    {
      ...show(3),
      transformOrigin: '74.948px 32.479px',
    },
  ];

  if (pieces.length === 4) {
    return (
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio={asIcon ? 'meet' : 'none'}
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 100 95.934 C 100 98.142 98.142 100 95.935 100 L 49.967 100 L 49.967 83.604 C 48.496 84.455 46.79 84.941 44.971 84.941 C 39.45 84.941 34.975 80.467 34.975 74.948 C 34.975 69.428 39.45 64.956 44.971 64.956 C 46.79 64.956 48.496 65.441 49.967 66.291 L 49.967 49.966 L 66.293 49.966 C 65.442 51.434 64.957 53.14 64.957 54.962 C 64.957 60.481 69.431 64.956 74.949 64.956 C 80.467 64.956 84.943 60.481 84.943 54.962 C 84.943 53.14 84.456 51.434 83.607 49.966 L 100 49.966 L 100 95.934 Z"
          style={styles[0]}
        />

        <path
          d="M 57.462 88.437 C 57.462 90.645 55.672 92.435 53.465 92.435 L 7.497 92.435 L 7.497 76.108 C 6.027 76.959 4.32 77.445 2.501 77.445 C -3.02 77.445 -7.495 72.971 -7.495 67.453 C -7.495 61.933 -3.02 57.459 2.501 57.459 C 4.32 57.459 6.027 57.946 7.497 58.795 L 7.497 42.468 L 23.823 42.468 C 22.973 43.938 22.487 45.645 22.487 47.465 C 22.487 52.985 26.961 57.459 32.48 57.459 C 37.997 57.459 42.473 52.985 42.473 47.465 C 42.473 45.645 41.987 43.938 41.137 42.468 L 57.462 42.468 L 57.462 88.437 Z"
          transform="matrix(0, 1, -1, 0, 0, 0)"
          style={styles[1]}
        />

        <path
          d="M 64.956 45.969 C 64.956 48.176 63.167 49.965 60.96 49.965 L 14.991 49.965 L 14.991 33.639 C 13.52 34.491 11.814 34.976 9.994 34.976 C 4.474 34.976 0 30.503 0 24.985 C 0 19.464 4.474 14.991 9.994 14.991 C 11.814 14.991 13.52 15.476 14.991 16.326 L 14.991 0 L 31.317 0 C 30.467 1.47 29.982 3.177 29.982 4.996 C 29.982 10.516 34.455 14.991 39.974 14.991 C 45.492 14.991 49.967 10.516 49.967 4.996 C 49.967 3.177 49.481 1.47 48.631 0 L 64.956 0 L 64.956 45.969 Z"
          transform="matrix(-1, 0, 0, -1, 0, 0)"
          style={styles[2]}
        />

        <path
          d="M 107.427 53.465 C 107.427 55.673 105.637 57.462 103.43 57.462 L 57.461 57.462 L 57.461 41.135 C 55.99 41.987 54.284 42.472 52.465 42.472 C 46.944 42.472 42.471 37.998 42.471 32.48 C 42.471 26.96 46.944 22.488 52.465 22.488 C 54.284 22.488 55.99 22.972 57.461 23.823 L 57.461 7.496 L 73.787 7.496 C 72.937 8.966 72.452 10.672 72.452 12.492 C 72.452 18.012 76.925 22.488 82.444 22.488 C 87.962 22.488 92.437 18.012 92.437 12.492 C 92.437 10.672 91.952 8.966 91.1 7.496 L 107.427 7.496 L 107.427 53.465 Z"
          transform="matrix(0, -1, 1, 0, 0, 0)"
          style={styles[3]}
        />
      </svg>
    );
  }

  styles = [
    {
      ...show(0),
      ...fillBox,
    },
    {
      ...show(1),
    },
    {
      ...show(2),
      ...fillBox,
    },
    {
      ...show(3),
      ...fillBox,
    },
    {
      ...show(4),
      transformOrigin: '230.648px 148.399px',
    },
    {
      ...show(5),
      transformOrigin: '49.489px 133.562px',
    },
  ];

  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio={asIcon ? 'meet' : 'none'}
      viewBox="0 0 149 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(0.505175, 0, 0, 0.505175, -0.017993, -0.020169)">
        <path
          d="M 128.617 91.035 C 128.617 95.405 125.076 98.946 120.707 98.946 L 29.711 98.946 L 29.711 66.629 C 26.799 68.315 23.422 69.276 19.82 69.276 C 8.893 69.276 0.036 60.42 0.036 49.498 C 0.036 38.57 8.893 29.714 19.82 29.714 C 23.422 29.714 26.799 30.675 29.711 32.357 L 29.711 0.04 L 62.029 0.04 C 60.345 2.949 59.386 6.33 59.386 9.929 C 59.386 20.856 68.24 29.714 79.165 29.714 C 90.088 29.714 98.946 20.856 98.946 9.929 C 98.946 6.33 97.984 2.949 96.302 0.04 L 128.617 0.04 L 128.617 91.035 Z"
          transform="matrix(-1, 0, 0, -1, 0, 0)"
          style={styles[0]}
        />

        <path
          d="M 98.942 98.946 L 98.942 66.625 C 101.853 68.309 105.301 69.179 108.423 69.259 C 118.893 69.526 128.613 60.941 128.613 49.528 C 128.613 37.635 118.588 29.169 107.682 29.746 C 104.506 29.914 101.468 30.895 98.942 32.357 L 98.942 0.04 L 196.033 0.04 L 196.033 32.358 C 198.941 30.674 202.322 29.711 205.924 29.711 C 216.849 29.711 225.708 38.568 225.708 49.491 C 225.708 60.416 216.849 69.272 205.924 69.272 C 202.322 69.272 198.941 68.309 196.033 66.625 L 196.033 98.946 L 98.942 98.946 Z"
          style={styles[1]}
        />

        <path
          d="M 69.267 98.946 L 69.267 131.267 C 72.178 129.583 75.626 128.713 78.748 128.633 C 89.218 128.366 98.938 136.951 98.938 148.364 C 98.938 160.257 88.913 168.723 78.007 168.146 C 74.831 167.978 71.793 166.997 69.267 165.535 L 69.267 197.852 L 166.358 197.852 L 166.358 165.534 C 169.266 167.218 172.647 168.181 176.249 168.181 C 187.174 168.181 196.033 159.324 196.033 148.401 C 196.033 137.476 187.174 128.62 176.249 128.62 C 172.647 128.62 169.266 129.583 166.358 131.267 L 166.358 98.946 L 69.267 98.946 Z"
          transform="matrix(-1, 0, 0, -1, 0, 0)"
          style={styles[2]}
        />

        <path
          d="M 309.776 105.873 C 309.776 110.243 306.235 113.784 301.866 113.784 L 210.87 113.784 L 210.87 81.467 C 207.958 83.153 204.581 84.114 200.979 84.114 C 190.052 84.114 181.195 75.258 181.195 64.336 C 181.195 53.408 190.052 44.552 200.979 44.552 C 204.581 44.552 207.958 45.513 210.87 47.195 L 210.87 14.878 L 243.188 14.878 C 241.504 17.787 240.545 21.168 240.545 24.767 C 240.545 35.694 249.399 44.552 260.324 44.552 C 271.247 44.552 280.105 35.694 280.105 24.767 C 280.105 21.168 279.143 17.787 277.461 14.878 L 309.776 14.878 L 309.776 105.873 Z"
          transform="matrix(0, -1, 1, 0, 0, 0)"
          style={styles[3]}
        />

        <path
          d="M 294.938 189.941 C 294.938 194.311 291.397 197.852 287.028 197.852 L 196.032 197.852 L 196.032 165.535 C 193.12 167.221 189.743 168.182 186.141 168.182 C 175.214 168.182 166.357 159.326 166.357 148.404 C 166.357 137.476 175.214 128.62 186.141 128.62 C 189.743 128.62 193.12 129.581 196.032 131.263 L 196.032 98.946 L 228.35 98.946 C 226.666 101.855 225.707 105.236 225.707 108.835 C 225.707 119.762 234.561 128.62 245.486 128.62 C 256.409 128.62 265.267 119.762 265.267 108.835 C 265.267 105.236 264.305 101.855 262.623 98.946 L 294.938 98.946 L 294.938 189.941 Z"
          style={styles[4]}
        />

        <path
          d="M 113.779 175.104 C 113.779 179.474 110.238 183.015 105.869 183.015 L 14.873 183.015 L 14.873 150.698 C 11.961 152.384 8.584 153.345 4.982 153.345 C -5.945 153.345 -14.802 144.489 -14.802 133.567 C -14.802 122.639 -5.945 113.783 4.982 113.783 C 8.584 113.783 11.961 114.744 14.873 116.426 L 14.873 84.109 L 47.191 84.109 C 45.507 87.018 44.548 90.399 44.548 93.998 C 44.548 104.925 53.402 113.783 64.327 113.783 C 75.25 113.783 84.108 104.925 84.108 93.998 C 84.108 90.399 83.146 87.018 81.464 84.109 L 113.779 84.109 L 113.779 175.104 Z"
          transform="matrix(0, 1, -1, 0, -0, 0)"
          style={styles[5]}
        />
      </g>
    </svg>
  );
};

export default JigsawBuilder;
