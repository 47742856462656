import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateRedeemPrize } from '../../../api/InventoryAPI';
import { isValidUrl } from '../../../utils/componentUtil';
import { useInventory, usePlayer, usePrizes, useTeam } from '../../../storage/storage';
import { DEFAULT_FULL_SCREEN_IMG } from '../../../utils/constants';
import { useCountRewardsForRedemption } from '../../../api/RewardAPI';

export const usePrizeRedemption = () => {
  const { id } = useParams();
  const inventory = useInventory();
  const team = useTeam();
  const prizes = usePrizes();
  const player = usePlayer();
  const { redeemPrize } = useUpdateRedeemPrize();
  const { fetchCountRewardForRedemption } = useCountRewardsForRedemption();

  const prize = prizes.find((p) => p.prize_id === id);
  const prizeDetails = inventory.details[id];

  const canRedeem = (prize, prizeDetails, team) =>
    prizeDetails.uniquePieces === prize.total_pieces &&
    team.prize_level >= prize.level &&
    prizeDetails.redeemed < prizeDetails.stock;

  const [info, setInfo] = useState({
    soldOut: prizeDetails.redeemed === prizeDetails.stock,
    pricesLeft: prizeDetails.stock - prizeDetails.redeemed,
    missingPieces: prizeDetails.uniquePieces !== prize.total_pieces,
    canRedeem: canRedeem(prize, prizeDetails, team),
    locked: team.prize_level < prize.level,
    owned: 0,
  });

  useEffect(() => {
    setInfo((prev) => ({
      ...prev,
      soldOut: prizeDetails.redeemed === prizeDetails.stock,
      pricesLeft: prizeDetails.stock - prizeDetails.redeemed,
      missingPieces: prizeDetails.uniquePieces !== prize.total_pieces,
      canRedeem: canRedeem(prize, prizeDetails, team),
      locked: team.prize_level < prize.level,
    }));
  }, [prize, prizeDetails, team, setInfo]);

  useEffect(() => {
    const getCount = async () => {
      if (!player?.player_id) return;
      const count = await fetchCountRewardForRedemption(player.player_id, prize.prize_id);
      setInfo((prev) => ({
        ...prev,
        owned: count,
      }));
    };
    getCount();
  }, [setInfo, player.player_id, prize.prize_id, fetchCountRewardForRedemption]);

  const redeem = async () => {
    await redeemPrize(inventory.inventory_id, { prizeId: prize.prize_id });
    const count = await fetchCountRewardForRedemption(player.player_id, prize.prize_id);

    setInfo((prev) => ({
      ...prev,
      owned: count,
    }));
  };

  const buildStyle = (url, level) => {
    const validUrl = isValidUrl(url) ? url : DEFAULT_FULL_SCREEN_IMG[level];
    return {
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `linear-gradient(180deg, rgba(5, 18, 22, 0) 50.86%, rgba(5, 18, 22, 0.8) 73.11%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0) 78.38%, rgba(5, 18, 22, 0.8) 97.37%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0.3), rgba(5, 18, 22, 0.3)),
                           url(${validUrl})`,
    };
  };

  return {
    prize,
    prizeDetails,
    info,
    redeem,
    buildStyle,
  };
};
