import React, { useEffect } from 'react';
import GameContainer from '../GameContainer';
import JigsawBuilder from '../JigsawBuilder';

import '../../assets/css/buttons.css';
import '../../assets/css/info-prize.css';
import hot from '../../assets/img/icons/hot.svg';
import backArrow from '../../assets/img/icons/back.svg';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import { usePrizeRedemption } from './hooks/usePrizeRedemption.js';
import PrizeRedemptionButton from './components/PrizeRedemptionButton.js';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading.js';
import { useCreateUserTrack } from '../../api/UserTrackAPI';
import { useActor } from '../../storage/storage.js';
import { useLangHandler } from '../../api/LanguageAPI';
import useImageUrl from '../hooks/useImageUrl.js';

const PrizeRedemption = () => {
  const { prize, prizeDetails, info, redeem, buildStyle } = usePrizeRedemption();
  const { url } = useImageUrl(prize.cover_media_id);
  const { t, i18n } = useTranslation();
  const { T } = useLangHandler();
  const { trackAndNavigate, createUserTrack } = useCreateUserTrack();
  const actor = useActor();
  const PRIZE_HOT_LIMIT = 5;

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);
  useEffect(() => {
    const trackPrizeView = async () => {
      try {
        await createUserTrack({
          companyId: actor.company_id,
          actorId: actor.actor_id,
          event: 'PRIZE_CLICK',
        });
      } catch (error) {
        console.error('Error tracking prize view:', error);
      }
    };
    trackPrizeView();
  }, []);

  return (
    <GameContainer active={'JigSaw'}>
      <div className="info-prize-container">
        <img
          className="info-prize-img-back-btn"
          src={backArrow}
          alt="jigsaw"
          onClick={() => trackAndNavigate('/prize-catalog', 'PRIZE_CLICK')}
        />
        {url && <div className="info-prize-img" style={buildStyle(url, prize.level)}></div>}
        <div className="info-prize-info">
          <div className="jigsaw-info-header">
            <div className="jigsaw-info-title">
              <span>{prize && T(prize.name)}</span>
              <div>
                <JigsawBuilder isLocked={false} pieces={prizeDetails.pieceCount} asIcon={true} />
                <span>
                  {prizeDetails.uniquePieces} / {prize.total_pieces}
                </span>
              </div>
            </div>
            {info.pricesLeft <= PRIZE_HOT_LIMIT && (
              <div className="hot-info">
                <img src={hot} alt="support" />
                <span>
                  {info.pricesLeft > 0 ? t('game.redemption.only') : ''} {info.pricesLeft} {t('game.redemption.left')}
                </span>
              </div>
            )}
            {!info.soldOut && prizeDetails.repeatedPieces > 0 && (
              <div>
                <span>
                  ({prizeDetails.repeatedPieces}) {t('game.redemption.repeated')}
                </span>
              </div>
            )}
            {info.owned > 0 && (
              <div>
                <span>{t('game.redemption.owned', { val: info.owned })}</span>
              </div>
            )}
          </div>
          <p className="info-prize-info-detail">{prize && T(prize.description)}</p>

          <PrizeRedemptionButton info={info} level={prize.level} redeem={redeem} />
        </div>
      </div>
    </GameContainer>
  );
};

export default withAuthenticationRequired(PrizeRedemption, {
  onRedirecting: () => <Loading />,
});
