import { useEffect, useState } from 'react';
import usePrizes from './usePrizes';
import useJackpot from './useJackpot';

const useClient = () => {
  const { loadingPrizes } = usePrizes();
  const { loadingJackpot } = useJackpot();

  const [loadingClient, setLoadingClient] = useState(false);

  useEffect(() => {
    setLoadingClient(loadingPrizes || loadingJackpot);
  }, [loadingPrizes, loadingJackpot, setLoadingClient]);

  return {
    loadingClient,
  };
};

export default useClient;
