import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  experience: null,
};

const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    initExperience: (state, action) => {
      state.experience = action.payload;
    },
  },
});

export const { initExperience } = experienceSlice.actions;
export const selectExperience = (state) => state.experience;
export default experienceSlice.reducer;
