import { useEffect } from 'react';
import { useFetchTeam } from '../../api/TeamAPI';
import { setTeam, usePlayer } from '../../storage/storage';
import { useDispatch } from 'react-redux';

const useTeam = () => {
  const player = usePlayer();
  const dispatch = useDispatch();
  const { team, loading, fetchTeam } = useFetchTeam();

  useEffect(() => {
    if (!player?.team_id) return;
    fetchTeam(player.team_id, true);
  }, [player.team_id, fetchTeam]);

  useEffect(() => {
    if (loading || !team) return;
    setTeam(team, dispatch);
  }, [team, loading]);

  return { team, loading };
};

export default useTeam;
