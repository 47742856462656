import { useState } from 'react';
import { fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setInventory, useActor, useInventory } from '../storage/storage';
import { useCreateUserTrack } from './UserTrackAPI';

const usePatchInventory = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const inventory = useInventory();

  const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/daily-chest`;

  const generateDailyChest = async () => {
    setLoading(true);
    setError(null);

    try {
      const result = await patchData(url, {}, getAccessTokenSilently);
      setResponse(result);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, generateDailyChest };
};

const useUpdateInventoryContext = () => {
  const { getAccessTokenSilently } = useAuth0();
  const inventory = useInventory();
  const dispatch = useDispatch();

  const getUrl = `${BACKEND_URL}/inventory/${inventory.inventory_id}`;

  const updateInventoryContext = async () => {
    try {
      const res = await fetchData(getUrl, getAccessTokenSilently);
      setInventory(res, dispatch);
    } catch (error) {
      console.error('Error updating inventory.');
    }
  };

  return { updateInventoryContext };
};

const useUpdateRedeemPrize = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const { updateInventoryContext } = useUpdateInventoryContext();
  const { createUserTrack } = useCreateUserTrack();
  const actor = useActor();

  const redeemPrize = async (id, prize) => {
    const url = `${BACKEND_URL}/inventory/${id}/prize`;
    setError(null);
    try {
      const result = await patchData(url, prize, getAccessTokenSilently);
      await updateInventoryContext();
      return result;
    } catch (error) {
      console.error('Unexpected error occurred while updating the prize in Inventory.');
      const errorMessage =
        error.response?.data?.message || 'Unexpected error occurred while updating the prize in Inventory.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
      try {
        await createUserTrack({
          companyId: actor.company_id,
          actorId: actor.actor_id,
          event: 'REDEEM_PRIZE',
        });
      } catch (error) {
        console.error('Error tracking user navigation:', error);
      }
    }
  };

  return { error, loading, redeemPrize };
};

const useCreateInventory = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createInventory = async (playerId) => {
    try {
      return await postData(`${BACKEND_URL}/inventory/`, { playerId: playerId }, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the inventory.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the inventory.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createInventory };
};

const useAcceptAwards = () => {
  const { getAccessTokenSilently } = useAuth0();
  const inventory = useInventory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [awards, setAwards] = useState([]);

  const acceptAwards = async () => {
    setLoading(true);
    setError(null);

    try {
      const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/awards`;
      const result = await patchData(url, {}, getAccessTokenSilently);
      setAwards(result);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error accepting awards';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { awards, loading, error, acceptAwards };
};

export { usePatchInventory, useUpdateInventoryContext, useUpdateRedeemPrize, useCreateInventory, useAcceptAwards };
