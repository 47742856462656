import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { useActor, useInventory, useJackpot } from '../../storage/storage';
import { useLangHandler } from '../../api/LanguageAPI';
import { isValidUrl } from '../../utils/componentUtil';
import { DEFAULT_LOTTERY } from '../../utils/constants';
import { colorText } from '../../utils/coloredText';

import GameContainer from '../GameContainer';
import Loading from '../../components/Loading';

import '../../assets/css/buttons.css';
import '../../assets/css/info-prize.css';
import '../../i18n/i18n.js';

import backArrow from '../../assets/img/icons/back.svg';
import galiTicket from '../../assets/img/icons/galiticket.svg';
import lotteryDefault from '../../assets/img/default/lottery-fullscreen.jpg';
import jackpotFlame from '../../assets/img/icons/jackpot-flame.svg';
import useImageUrl from '../hooks/useImageUrl.js';

const JackpotDetail = () => {
  const actor = useActor();
  const jackpot = useJackpot();
  const { url } = useImageUrl(jackpot.cover_media_id);
  const inventory = useInventory();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { T } = useLangHandler();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const buildStyle = (url) => {
    const validUrl = isValidUrl(url) ? url : lotteryDefault;
    return {
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${validUrl})`,
    };
  };

  const translatedDescription = jackpot ? T(jackpot.description) : t(DEFAULT_LOTTERY.description);
  const translatedRequirements =
    jackpot && jackpot.requirements
      ? jackpot.requirements.map((req) => T(req))
      : t(DEFAULT_LOTTERY.requirements, { returnObjects: true });

  const formateDate = (date) => {
    return {
      val: new Date(date),
      formatParams: {
        val: { year: undefined, month: 'long', day: 'numeric' },
      },
    };
  };

  return (
    <GameContainer active={'JigSaw'}>
      <div className="info-prize-container">
        <img
          className="info-prize-img-back-btn"
          src={backArrow}
          alt="jigsaw"
          onClick={() => navigate('/prize-catalog')}
        />
        {!url && <div className="info-prize-img" style={buildStyle(DEFAULT_LOTTERY.url)}></div>}
        {url && <div className="info-prize-img" style={buildStyle(url)}></div>}
        <div className="info-prize-info">
          <div className="info-reward-info-title">
            <div>
              <span>{T(jackpot?.name) || t(DEFAULT_LOTTERY.name)}</span>
              <span style={{ float: 'right' }}>
                <img src={galiTicket} alt="GaliTickets" style={{ width: '20px' }} />
                &nbsp;{inventory.total_tickets}
              </span>
            </div>
            <img src={jackpotFlame} alt={'Win this day!'} style={{ width: '20px', height: '13px' }} />
            <font style={{ color: '#FFA9A9', fontWeight: 'lighter', fontSize: '16px' }}>
              {jackpot
                ? t('game.lottery.drawnBy', formateDate(jackpot.drawn_by))
                : t('game.lottery.default.drawnByDate')}
            </font>
            <br />
            <span>
              <font style={{ fontWeight: 'lighter', fontSize: '16px' }}>
                {t('game.lottery.chances', { val: inventory.total_tickets })}
              </font>
            </span>
          </div>
          <p className="info-reward-info-detail">{colorText(translatedDescription)}</p>
          {translatedRequirements?.length > 0 && (
            <>
              <span className="info-reward-info-requirement">
                Requirements:
                <ul>
                  {translatedRequirements.map((item, i) => (
                    <li key={i} style={{ fontWeight: 'lighter' }}>
                      {colorText(item)}
                    </li>
                  ))}
                </ul>
              </span>
            </>
          )}
        </div>
      </div>
    </GameContainer>
  );
};

export default withAuthenticationRequired(JackpotDetail, {
  onRedirecting: () => <Loading />,
});
