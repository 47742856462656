import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LEVEL_COLORS, LEVEL_NAMES } from '../../../utils/constants';
import '../../../i18n/i18n';

const PrizeLevelBadge = ({ level }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  return <span className={`badge ${LEVEL_COLORS[level]}`}>{t(LEVEL_NAMES[level])}</span>;
};

export default PrizeLevelBadge;
