import { Form, Button, Col, FormGroup, Input, Label } from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton';
import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useAwardForm from '../../hooks/useAwardForm';
import MessageAlert from '../../components/commons/MessageAlert';
import MultiLangTabContent from '../../components/commons/MultiLangTabContent';
import useMultiLangTab from '../../hooks/useMultiLangTab';

const AwardForm = () => {
  const {
    teams,
    teamId,
    message,
    loading,
    players,
    award,
    handleSubmit,
    handleChangeTeam,
    handleChangePlayer,
    handleInputChange,
    handleInputChangeReason,
  } = useAwardForm();
  const { activeTab, toggle } = useMultiLangTab();

  if (loading) return <Loading />;

  return (
    <>
      {message.isSet && <MessageAlert message={message} />}
      <h2 className="admin-text">Awards on Demand</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Col sm={6}>
            <Label className="admin-text" for="team" sm={1}>
              Team:
            </Label>
            <Input id="team" type="select" value={teamId} onChange={(e) => handleChangeTeam(e.target.value)}>
              <option value=""></option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.name}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label className="admin-text" for="player" sm={1}>
              Player
            </Label>
            <Input
              id="player"
              type="select"
              value={award.playerId}
              onChange={(e) => handleChangePlayer(e.target.value)}
            >
              <option value=""></option>
              {players.map((player) => (
                <option key={player.player_id} value={player.player_id}>
                  {player.full_name}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="ticket">
            GaliTickets:
          </Label>
          <Col sm={1}>
            <Input
              id="ticket"
              name="tickets"
              placeholder="0"
              type="text"
              value={award.tickets}
              onChange={handleInputChange}
            />
          </Col>
          <Label className="admin-text" for="piece">
            GaliPieces:
          </Label>
          <Col sm={1}>
            <Input
              id="piece"
              name="pieces"
              placeholder="0"
              type="text"
              value={award.pieces}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="reason">
            Reason:
          </Label>
          <Col sm={6}>
            <MultiLangTabContent activeTab={activeTab} toggle={toggle}>
              <Input
                id="reason"
                name="reason"
                placeholder="Why"
                type="textarea"
                value={award.reason[activeTab]}
                onChange={(e) => handleInputChangeReason(e, activeTab)}
              />
            </MultiLangTabContent>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to="/awards" />
            <Button color="primary" type="submit">
              award!
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(AwardForm, {
  onRedirecting: () => <Loading />,
});
