import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        init: 'This is the beginning of something amazing',
        chooseOption: "Please choose the option you'd like to manage",
        metrics: {
          title: 'Metrics',
          value: 'Value',
          tips: 'Tips',
          success: 'Success!',
        },
        membership: {
          no: 'Looks like you have not been invited yet. Ask your team to invite you!',
          new: 'New Team Member',
          channel: 'Notification Channel',
          terms: `By checking this box, I agree with Galilei Learning's terms and conditions. View our terms`,
          privacy: `By checking this box, I agree with Galilei Learning's privacy policy. View our privacy policy`,
          communication:
            'By checking this box, I agree to get game updates through WhatsApp from Galilei Learning, with a maximum of 3 messages daily. Message & data rates may apply. Contact us to opt out.',
          link: 'here',
          noTerms: 'Terms and Conditions must be accepted',
          noPrivacy: 'Privacy Policy must be accepted',
          noCommunication: 'WhatsApp updates must be accepted.',
          acceptedOn:
            'By proceeding, the user acknowledged and agreed to the Terms and Conditions, Privacy Policy, and Whatsapp Updates on {{date, datetime}} at {{time, datetime}} from device with IP {{ip}}.',
        },
        menu: {
          companies: 'Companies',
          toTrooper: 'Back to Trooper view',
          lottery: 'Lottery',
          catalogue: 'Catalogue',
          content: 'Content',
          invLeader: 'Invite people',
          myTeam: 'My Team',
          play: "Let's Play!",
          teams: 'Teams',
          metrics: 'Metrics',
          dailyChest: 'Daily Chest Configuration',
          booster: 'Boosters Configuration',
          piece: 'Pieces Configuration',
        },
        form: {
          name: 'Name',
          desc: 'Description',
          img: 'Image',
          reqs: 'Requirements',
          logo: 'Logo',
        },
        buttons: {
          login: 'Log in',
          add: 'Add',
          back: 'Return',
          ok: 'Ok',
          redeemedPrizes: 'Redeemed Prizes',
          go: 'Go!',
          reject: 'Reject',
          logout: 'Log out',
          toAdmin: 'Return to manager',
          accept: 'Accept',
          save: 'Save',
          addRequirement: 'Add requirement',
        },
        tables: {
          empty: 'No elements to display',
          name: 'Name',
          sort: 'Sort',
          error: 'Something went wrong while fetching the data',
          available: 'Available',
          redeemed: 'Redeemed',
          select: 'Select',
          quantity: 'Quantity',
          actions: 'Actions',
          active: 'Active',
        },
        config: {
          dailyChest: {
            streak: 'Streak',
            type: 'Chest Type',
            itemQuantity: 'Item Quantity',
            pieceProbability: 'Piece Probability',
            ticketProbability: 'Ticket Probability',
          },
          booster: {
            sequence: 'Sequence',
            type: 'Type',
            small: 'Small',
            medium: 'Medium',
            large: 'Large',
          },
          piece: {
            catalogProbability: 'Catalog Probability',
            catalogType: 'Catalog type',
            pieceProbability: 'Piece Probability',
            pieces: 'Pieces',
            probability: 'Probability',
          },
        },
        lottery: {
          add: 'Add Lottery',
          edit: 'Edit Lottery',
          result: {
            title: 'Lottery Winner!',
            body: "We've sent a notification to the lottery winner:",
          },
          run: 'Get Lottery Winner!',
        },
        catalogue: {
          prizes: 'Prizes',
          level: 'Level',
          pieces: '# Pieces',
          add: 'Add Prize',
          edit: 'Edit Prize',
          stock: 'Stock',
        },
        content: {
          add: 'Add Content',
          edit: 'Edit Content',
          questionsCount: '# Questions',
          stats: 'Stats',
          missing: 'You need to create a lottery and a Catalogue first',
          step1: 'Step 1: Content name',
          step2: 'Step 2: Upload Handbook',
          step3: 'Step 3: Prize Catalogue and Quantities',
          active: 'Enabled',
          inactive: 'Disabled',
          question: 'Question',
          answers: 'Answers',
          correctAns: 'Correct Answer',
          seeQuestions: 'See Questions',
          editDetails: 'Edit Details',
          settings: 'Settings',
          questionText: 'Question Text',
          options: 'Options',
          addOption: 'Add Option',
          priority: {
            name: 'Priority',
            levels: {
              low: 'Low',
              medium: 'Medium',
              high: 'High',
            },
          },
          difficulty: {
            name: 'Difficulty',
            levels: {
              easy: 'Easy',
              medium: 'Medium',
              hard: 'Hard',
            },
          },
          language: {
            en: 'English',
            es: 'Spanish',
            fr: 'French',
          },
          questionSavedSuccessfully: 'Question saved successfully!',
          questionSaveFailed: 'Failed to save the question',
          addOrModifyImage: 'Add or modify image',
          selectImage: 'Select Image',
        },
        company: {
          supreme: 'View As Supreme',
          leaders: 'Total Leaders',
          players: 'Total Players',
        },
        invitation: {
          sent: 'Invitations sent successfully!',
          send: 'Send',
          clear: 'Clear List',
          phone: 'Phone:',
          list: 'Numbers to invite:',
          lang: 'Language:',
          role: 'Role:',
          team: 'Team:',
          ready: {
            title: 'Invite your Team Members!',
            desc: 'Your team is ready to play along new members',
            slots: 'Available Slots:',
            footer: 'Invite them to earn prizes!',
          },
          full: {
            title: 'There are no more spots available for invitations.',
            body1: 'Your team has filled',
            body2: 'slots. You cannot invite new members.',
            footer: 'But wait...contact us to review your upgrade options.',
          },
        },
        game: {
          home: {
            start: 'Start Mission',
            rewards: 'See Rewards',
            playNow: 'Play now',
            mission: 'Mission',
            timeUp: "Time's up for today",
            openChest: 'Open Chest',
            daily: 'Daily Resources',
            explore: 'Explore Rewards',
            comingSoon: 'Coming Soon',
          },
          footer: {
            home: 'Home',
            chest: 'Daily Chest',
            rewards: 'Rewards',
            account: 'Account',
          },
          chest: {
            tab: {
              daily: 'Daily Chest',
              content: 'Chest Content',
              next: 'Next chest unlocks in',
            },
            carousel: {
              collect: 'Collect Rewards',
            },
          },
          catalogue: {
            name: {
              bronze: 'Bronze Catalogue',
              silver: 'Silver Catalogue',
              gold: 'Gold Catalogue',
            },
            bronze: 'Bronze',
            silver: 'Silver',
            gold: 'Gold',
            locked: 'Locked',
            unlocked: 'Unlocked',
            pieces: 'GaliPieces',
            tickets: 'GaliTickets',
          },
          lottery: {
            default: {
              name: 'Jackpot coming soon',
              desc: 'Every GaliTicket is a chance to win the mystery jackpot. The mystery jackpot will be revealed soon. Don’t miss out!',
              requirements: ['Play at least 1 month.', 'Store in Silver or Gold.', 'Game accuracy must be over 80%.'],
              drawnByDate: 'Soon...',
            },
            drawnBy: 'Drawn on {{val, datetime}}',
            chances: 'You currently have ({{val}}) chances to WIN!',
          },
          redemption: {
            soldOut: 'Sold Out',
            missing: 'Missing GaliPieces',
            owned: 'You have {{val}} to collect',
            redeem: 'Redeem Prize',
            only: 'Only',
            left: 'left in stock',
            repeated: 'repeated pieces',
          },
          end: {
            good: 'Good Job!',
            perform: 'Your performance today',
            return: 'Come back tomorrow to keep winning',
            next: 'Next',
            accomplished: 'Mission Accomplished!',
          },
          stats: {
            name: 'Your stats',
            streak: 'Daily Streak',
            hits: 'hits',
            openChest: 'opened chests',
            timePlayed: 'played time',
            correctAnswers: 'Correct Ans.',
            maxScore: 'Max. Score',
            maxAccuracy: 'Max. Accuracy',
            maxTime: 'Longest game',
            score: 'Score',
            accuracy: 'Accuracy',
            newTopScore: 'New Top Score!',
          },
          profile: {
            leader: 'Leader',
            boost: {
              no: 'No Boost Active!',
              active: 'Active the boost for your team',
              ended: 'Boost ended on',
              sameDayEnded: 'Boost ended today on',
              return: 'Return tomorrow to activated it again',
              until: 'Boost activated till',
              tomorrow: ' tomorrow by',
            },
            prizes: {
              keepPlaying: 'Keep playing and redeem prizes to count them here',
              byPlayer: 'Prizes redeemed by your team',
              byTeam: 'Prizes redeemed by other teams',
            },
            level: {
              name: 'Your team metrics',
            },
            error: 'Oops, something wrong happened...',
            languageChange: 'Change language',
          },
          bonus: {
            progress: 'Your Progress',
            earn: 'Earned',
            hits: 'hits!',
            wd: 'Well done!',
          },
        },
        time: {
          sec: 'secs.',
          min: 'min.',
          hours: 'hours',
          days: 'days',
        },
      },
    },
    es: {
      translation: {
        init: 'Este es el inicio de una experiencia sin igual',
        chooseOption: 'Selecciona la opción que deseas gestionar',
        metrics: {
          title: 'Métricas',
          value: 'Valor',
          tips: 'Propinas',
          success: 'Éxito!',
        },
        membership: {
          no: 'Parece que aún no has sido invitado. Pídele a tu equipo que te invite.',
          new: 'Nuevo Miembro de Equipo',
          channel: 'Canal de notificación',
          terms: `Al marcar esta casilla, acepto los términos y condiciones de Galilei learning. Consulta nuestros términos`,
          privacy: `Al marcar esta casilla, acepto la política de privacidad de Galilei learning. Consulta nuestra política de privacidad`,
          communication:
            'Al marcar esta casilla, acepto recibir actualizaciones sobre el juego por WhatsApp de Galilei Learning, con un máximo de 3 mensajes diarios. Pueden aplicarse tarifas de mensajes y datos. Contáctanos para darte de baja.',
          link: 'acá',
          noTerms: 'Los Términos y Condiciones deben ser aceptados',
          noPrivacy: 'La Política de Privacidad debe ser aceptada',
          noCommunication: 'Las actualizaciones por WhatsApp deben ser aceptadas',
          acceptedOn:
            'Al proceder, el usuario reconoció y aceptó los Términos y Condiciones, Política de Privacidad, y Actualizaciones por Whatsapp el {{date, datetime}} a las {{time, datetime}} desde el dispositivo con IP {{ip}}',
        },
        menu: {
          companies: 'Empresas',
          toTrooper: 'Volver a Trooper',
          lottery: 'Lotería',
          catalogue: 'Catálogo de premios',
          content: 'Contenido',
          invLeader: 'Invitar Líderes',
          myTeam: 'Mi Equipo',
          play: '¡Juguemos!',
          teams: 'Equipos',
          metrics: 'Métricas',
        },
        form: {
          name: 'Nombre',
          desc: 'Descripción',
          img: 'Imagen',
          reqs: 'Requisitos',
        },
        buttons: {
          login: 'Ingresar',
          add: 'Agregar nuevo',
          back: 'Regresar',
          ok: 'Aceptar',
          redeemedPrizes: 'Premios Redimidos',
          go: '¡Vamos!',
          reject: 'Rechazar',
          logout: 'Cerrar sesión',
          toAdmin: 'Regresar a Admón',
          accept: 'Aceptar',
          save: 'Guardar',
          addRequirement: 'Agregar nuevo',
        },
        tables: {
          empty: 'No se encontraron registros',
          name: 'Nombre',
          sort: 'Ordenar',
          error: 'Un error ocurrió al consultar los registros',
          available: 'Disponibles',
          redeemed: 'Redimidos',
          select: 'Seleccionado',
          quantity: 'Cantidad',
          actions: 'Acciones',
          active: 'Activo',
        },
        lottery: {
          add: 'Nueva Lotería',
          edit: 'Editar Lotería',
          result: {
            title: '¡Hay un ganador!',
            body: 'Hemos enviado una notificación al ganador de la lotería:',
          },
          run: '¡Lanzar Rifa!',
        },
        catalogue: {
          prizes: 'Premios',
          level: 'Nivel',
          pieces: '# de Piezas',
          add: 'Nuevo Premio',
          edit: 'Editar Premio',
          stock: 'Existencias',
        },
        content: {
          add: 'Agregar Contenido',
          edit: 'Editar Contenido',
          questionsCount: '# de Preguntas',
          stats: 'Estadísticas de juego',
          missing: 'No has creado una lotería o el catálogo de premios.',
          step1: 'Paso 1: Nombre del Contenido',
          step2: 'Paso 2: Manual Operativo',
          step3: 'Paso 3: Asociar Catálogo de Premios y cantidades',
          active: 'Activa',
          inactive: 'Inactiva',
          question: 'Pregunta',
          answers: 'Respuestas',
          correctAns: 'Respuesta Correcta',
          settings: 'Configuración',
          questionText: 'Texto de la Pregunta',
          options: 'Opciones',
          addOption: 'Agregar Opción',
          priority: {
            name: 'Prioridad',
            levels: {
              low: 'Baja',
              medium: 'Media',
              high: 'Alta',
            },
          },
          difficulty: {
            name: 'Dificultad',
            levels: {
              easy: 'Fácil',
              medium: 'Media',
              hard: 'Difícil',
            },
          },
          language: {
            en: 'Inglés',
            es: 'Español',
            fr: 'Francés',
          },
          questionSavedSuccessfully: '¡Pregunta guardada exitosamente!',
          questionSaveFailed: 'Error al guardar la pregunta',
          addOrModifyImage: 'Agregar o modificar imagen',
          selectImage: 'Seleccionar Imagen',
        },
        company: {
          supreme: 'Ver como Líder Supremo',
          leaders: 'Total de Líderes',
          players: 'Total de Jugadores',
        },
        invitation: {
          sent: '¡Invitaciones enviadas satisfactoriamente!',
          send: 'Enviar invitaciones',
          clear: 'Limpiar lista',
          phone: 'Número Móvil:',
          list: 'Lista de números móviles:',
          lang: 'Idioma:',
          role: 'Rol:',
          team: 'Equipo:',
          ready: {
            title: '¡Invita a tus compañeros de equipo!',
            desc: 'Tu equipo está listo para jugar junto con nuevos compañeros',
            slots: 'Plazas disponibles:',
            footer: '¡Invítalos para reclamar premios!',
          },
          full: {
            title: 'No hay más plazas disponibles para invitar.',
            body1: 'Tu equipo ya ocupo sus',
            body2: 'plazas de invitación. Ya no puedes invitar a más miembros a tu equipo.',
            footer: 'Contacta a ventas para revisar tus opciones de invitación.',
          },
        },
        game: {
          home: {
            start: 'Iniciar Misión',
            rewards: 'Ver Recompensas',
            playNow: 'Juega Ya',
            mission: 'Misión',
            timeUp: 'Eso es todo por hoy',
            openChest: 'Abrir Cofre',
            daily: 'Recursos Diarios',
            explore: 'Explorar Recompensas',
            comingSoon: 'Próximamente',
          },
          footer: {
            home: 'Inicio',
            chest: 'Cofre Diario',
            rewards: 'Recompensas',
            account: 'Cuenta',
          },
          chest: {
            tab: {
              daily: 'Cofre Diario',
              content: 'Contenido de Cofre',
              next: 'Próximo cofre',
            },
            carousel: {
              collect: 'Obtener Recompensas',
            },
          },
          catalogue: {
            name: {
              bronze: 'Catálogo Bronce',
              silver: 'Catálogo Plata',
              gold: 'Catálogo Oro',
            },
            bronze: 'Bronce',
            silver: 'Plata',
            gold: 'Oro',
            locked: 'Bloqueado',
            unlocked: 'Desbloqueado',
            pieces: 'GaliPiezas',
            tickets: 'GaliTiquetes',
          },
          lottery: {
            default: {
              name: 'Próximamente revelación del premio mayor',
              desc: 'Prepárate para algo increíble. El premio misterioso se revelará pronto. No te lo pierdas.',
              requirements: [
                'Jugar al menos 1 mes.',
                'Tienda en Categoría Silver o Oro.',
                'La precisión del juego debe ser superior al 80%.',
              ],
              drawnByDate: 'Pronto...',
            },
            drawnBy: 'Juega el {{val, datetime}}',
            chances: 'Actualmente tienes ({{val}}) oportunidades de GANAR!',
          },
          redemption: {
            soldOut: 'Agotado',
            missing: 'GaliPieces Faltantes',
            owned: 'Tu tienes {{val}} por reclamar',
            redeem: 'Reclamar Premio',
            only: 'Sólo',
            left: 'restantes en inventario',
            repeated: 'piezas repetidas',
          },
          end: {
            good: '¡Buen trabajo!',
            perform: 'Tu desempeño de hoy',
            return: 'Vuelve mañana para seguir ganando.',
            next: 'Siguiente',
            accomplished: 'Misión Completada!',
          },
          stats: {
            name: 'Tus estadísticas',
            streak: 'días en racha',
            hits: '% de aciertos',
            openChest: 'cofres abiertos',
            timePlayed: 'tiempo jugado',
            correctAnswers: 'Resp. correctas',
            maxScore: 'Máx. Puntaje',
            maxAccuracy: 'Máx. Precisión',
            maxTime: 'Juego más largo',
            score: 'Puntaje',
            accuracy: 'Precisión',
            newTopScore: 'Nuevo Puntaje Alto!',
          },
          profile: {
            leader: 'Líder',
            boost: {
              no: '¡No has sido boosteado!',
              active: 'Activa el potenciador grupal',
              ended: 'Potenciamiento acabó el',
              sameDayEnded: 'Potenciamiento acabó hoy a las',
              return: 'Vuelve mañana para activarlo nuevamente',
              until: 'Potenciamiento está activo hasta las',
              tomorrow: ' mañana a las',
            },
            prizes: {
              keepPlaying: 'Sigue jugando y redime premios para verlos aquí',
              byPlayer: 'Premios de tu equipo',
              byTeam: 'Premios de equipos',
            },
            level: {
              name: 'Métricas de tu equipo',
            },
            error: 'Oops, ocurrió un error...',
            languageChange: 'Cambiar idioma',
          },
          bonus: {
            progress: 'Tu Progreso',
            earn: 'Ganadas',
            hits: 'aciertos!',
            wd: '¡Bien hecho!',
          },
        },
        time: {
          sec: 'seg.',
          min: 'min.',
          hours: 'horas',
          days: 'días',
        },
      },
    },
  },
});

export default i18n;
