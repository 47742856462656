import { useEffect, useState } from 'react';

import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import '../assets/css/bonus.css';
import galiPiece from '../assets/img/icons/bonus-galipiece-big.svg';
import { BACKEND_URL, POINTS_FOR_BONUS } from '../utils/constants';
import { useActor, useInventory } from '../storage/storage';
import { useNavigate } from 'react-router-dom';
import { patchData } from '../api/axiosClient';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import { useUpdateInventoryContext } from '../api/InventoryAPI';

const Bonus = () => {
  const actor = useActor();
  const inventory = useInventory();
  const { getAccessTokenSilently } = useAuth0();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { updateInventoryContext } = useUpdateInventoryContext();
  const galiPieces = t('game.catalogue.pieces');
  const obtained = parseInt(inventory.bonus_available);
  const hits = obtained * POINTS_FOR_BONUS;
  const plural = obtained > 1 ? galiPieces : galiPieces.substring(0, galiPieces.length - 1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
    if (obtained === 0) navigate('/client');
  }, [i18n, actor, obtained]);

  const handleCollectRewards = async () => {
    const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/bonus`;
    setLoading(true);
    try {
      await patchData(url, {}, getAccessTokenSilently);
      await updateInventoryContext();
      navigate('/prize-catalog');
    } catch (error) {
      console.error(error);
      navigate('/client');
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="bonus">
      <div className="bonus-inner">
        <ProgressBar currentBonus={inventory.bonus_points} pointsForBonus={POINTS_FOR_BONUS} />
        <div className="bonus-body">
          <h1 className="bonus-title bonus-title-1">{plural}</h1>
          <h1 className="bonus-title">{t('game.bonus.earn')}</h1>
          <p>{t('game.bonus.wd')}</p>
          <div className="bonus-hits">
            {hits} {t('game.bonus.hits')}
          </div>
          <div className="bonus-img">
            <img src={galiPiece} alt="Bonus" />
            <div className="bonus-quantity">x{obtained}</div>
          </div>
          <div className="bonus-button">
            <button disabled={loading} className="gali-button open" onClick={handleCollectRewards}>
              + {t('game.chest.carousel.collect')}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default withAuthenticationRequired(Bonus, {
  onRedirecting: () => <Loading />,
});
