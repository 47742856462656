import React, { useRef, useState } from 'react';
import { useTeam } from '../../storage/storage';
import { colorText } from '../../utils/coloredText';

const Question = ({ question, handleAnswerSubmit, clean, setClean, timeUpControl }) => {
  const team = useTeam();
  const [buttonClass, setButtonClass] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const ref = useRef();

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const changeButtonColor = async (selectedId) => {
    setIsClicked(true);
    const isFree = question.validAnswerOptionId === '*';
    const isCorrect = isFree || question.validAnswerOptionId === selectedId;
    const correctClass = 'correct';
    const incorrectClass = 'incorrect';
    if (team.show_answer && !isFree) ref.current.className = correctClass;
    setClean(false);

    setButtonClass({
      class: isCorrect ? correctClass : incorrectClass,
      selectedId,
    });

    await timeout(200);

    await handleAnswerSubmit(selectedId, question.id);
    setIsClicked(false);
  };

  const determineClass = (resultId) => {
    if (clean && ref?.current) ref.current.className = '';
    if (clean) return '';
    return buttonClass?.selectedId === resultId ? buttonClass.class : '';
  };
  const getPropsButton = (result) => {
    const props = {
      className: determineClass(result.id),
      onClick: () => changeButtonColor(result.id),
      disabled: timeUpControl || isClicked,
    };
    if (result.id !== question.validAnswerOptionId) return props;
    return {
      ...props,
      ref: ref,
    };
  };

  return (
    <>
      <div className="container-question-answer">
        <div className="question-area">
          {question.text && !question.imageUrl && (
            <div className="title-box">
              <i></i>
              <p>{colorText(question.text)}</p>
            </div>
          )}
          {!question.text && question.imageUrl && (
            <div className="title-question">
              <img className="question-image" src={question.imageUrl} alt="" />
            </div>
          )}
          {question.text && question.imageUrl && (
            <>
              <div className="title-question">
                {question.imageUrl && <img className="question-image" src={question.imageUrl} alt="" />}
              </div>
              <p className="question-text">{colorText(question.text)}</p>
            </>
          )}
        </div>
        <div className="button-array">
          {question.options.map((result) => (
            <button key={result.id} {...getPropsButton(result)}>
              <span>{result.text}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Question;
