import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Table,
  Badge,
  ButtonGroup,
  UncontrolledTooltip,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { faCheck, faXmark, faPencilAlt, faClone, faEye, faEyeSlash, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReturnButton from '../../components/commons/ReturnButton';
import React, { useEffect, useState } from 'react';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';
import { QUESTION_DIFFICULTY } from '../../../utils/constants';
import QuestionPriorityBadge from '../../components/content/QuestionPriorityBadge';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useExperienceForm from '../../hooks/useExperienceForm';
import AddButton from '../../components/commons/AddButton';
import useEditQuestion from '../../hooks/useQuestionForm';
import ConfirmModal from './ConfirmModal';
import { v4 as uuidV4 } from 'uuid';

const ExperienceQuestions = () => {
  const { id: experienceId } = useParams();
  const { experience, error, loading, updateQuestionOffline } = useExperienceForm(experienceId);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [modalText, setModalText] = useState({ title: '', message: '', confirmText: '' });
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    setLanguage(i18n.resolvedLanguage);
  }, [i18n, setLanguage]);

  const availableLanguage = (text) => text || 'Content not available in your language...';
  const { handleHideQuestion } = useEditQuestion(undefined, experienceId);

  const filteredQuestions = experience?.questions
    .filter((question) => {
      const matchesText =
        question.text?.[language] &&
        typeof question.text[language] === 'string' &&
        question.text[language].toLowerCase().includes(searchQuery.toLowerCase());

      const matchesAnswer = question.options.some(
        (option) =>
          option.text?.[language] &&
          typeof option.text[language] === 'string' &&
          option.text[language].toLowerCase().includes(searchQuery.toLowerCase())
      );

      return matchesText || matchesAnswer;
    })
    .filter((question) => (filter === 'all' ? true : filter === 'hidden' ? question.isHidden : !question.isHidden))
    .sort((a, b) => a.id.localeCompare(b.id));

  const handleDuplicateQuestion = (question) => {
    if (!question) return;
    const optionIdMap = new Map();
    const duplicatedOptions = question.options.map((option) => {
      const newId = uuidV4();
      optionIdMap.set(option.id, newId);
      return { ...option, id: newId };
    });
    const duplicatedQuestion = {
      ...question,
      id: uuidV4(),
      options: duplicatedOptions,
      validAnswerOptionId: optionIdMap.get(question.validAnswerOptionId) || null,
      isHidden: false,
    };
    navigate(`/experiences/${experienceId}/question/new`, {
      state: { question: duplicatedQuestion },
    });
  };

  const handleConfirmHide = (question) => {
    setSelectedQuestion(question);

    setModalText({
      title: question.isHidden ? 'Unhide Question' : 'Hide Question',
      message: question.isHidden
        ? 'Are you sure you want to unhide this question?'
        : 'Are you sure you want to hide this question? Keep in mind that this question may have already been played.',
      confirmText: question.isHidden ? 'Unhide' : 'Hide',
      cancelText: 'Cancel',
    });

    setModalOpen(true);
  };

  const handleConfirmAction = async () => {
    if (selectedQuestion) {
      try {
        await handleHideQuestion(selectedQuestion);
        updateQuestionOffline({ ...selectedQuestion, isHidden: !selectedQuestion.isHidden });
      } catch (err) {
        console.error('Error updating question:', err);
      }
    }
    setModalOpen(false);
  };

  if (loading) return <Loading />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Container className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <ReturnButton to="/experiences" />
          <AddButton action={() => navigate(`/experiences/${experienceId}/question/new`)} />
        </div>
        <Card className="shadow-sm mt-3">
          {experience && (
            <CardHeader className="bg-light">
              <Row className="align-items-center w-100 g-2">
                <Col xs="12" md="5" className="d-flex align-items-center gap-2">
                  <h4 className="mb-0 text-primary">{experience.name[i18n.resolvedLanguage]}</h4>
                  <Badge color={experience.active ? 'success' : 'danger'}>
                    {experience.active ? t('content.active') : t('content.inactive')}
                  </Badge>
                  <Badge color="secondary">{experience.questions.length}</Badge>
                </Col>
                <Col xs="12" md="7" className="d-flex flex-column flex-md-row align-items-stretch gap-2">
                  <Input
                    type="text"
                    placeholder="Search by question or answer..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <ButtonGroup>
                    <Button
                      color={filter === 'all' ? 'primary' : 'secondary'}
                      onClick={() => setFilter('all')}
                      id="filterAll"
                    >
                      <FontAwesomeIcon icon={faFilter} />
                    </Button>
                    <Button
                      color={filter === 'hidden' ? 'danger' : 'secondary'}
                      onClick={() => setFilter('hidden')}
                      id="filterHidden"
                    >
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </Button>
                    <Button
                      color={filter === 'visible' ? 'success' : 'secondary'}
                      onClick={() => setFilter('visible')}
                      id="filterVisible"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <UncontrolledTooltip target="filterAll">Show all questions</UncontrolledTooltip>
              <UncontrolledTooltip target="filterHidden">Show only hidden questions</UncontrolledTooltip>
              <UncontrolledTooltip target="filterVisible">Show only visible questions</UncontrolledTooltip>
            </CardHeader>
          )}
          <CardBody className="p-0">
            {experience && (
              <div className="table-responsive">
                <Table className="table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>{t('content.question')}</th>
                      <th>{t('form.img')}</th>
                      <th>{t('content.answers')}</th>
                      <th>{t('content.correctAns')}</th>
                      <th>{t('content.priority.name')}</th>
                      <th>{t('content.difficulty.name')}</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredQuestions.map((question) => (
                      <tr key={question.id}>
                        <td className="fw-semibold">
                          {question.text ? (
                            availableLanguage(question.text[language])
                          ) : (
                            <FontAwesomeIcon icon={faXmark} className="text-dark" />
                          )}
                        </td>
                        <td className="text-center align-middle">
                          {question.image ? (
                            <img
                              src={question.imageUrl}
                              alt={question.id}
                              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faXmark} className="text-dark" />
                          )}
                        </td>
                        <td>
                          {question.options.map((option) => (
                            <div key={option.id} className="small">
                              • {availableLanguage(option.text[language])}
                            </div>
                          ))}
                        </td>
                        <td className="fw-bold">
                          {question.options
                            .filter((option) => option.id === question.validAnswerOptionId)
                            .map((option) => (
                              <div key={option.id} className="small">
                                {option.text[language]} <FontAwesomeIcon icon={faCheck} className="text-success" />
                              </div>
                            ))}
                        </td>
                        <td>
                          <QuestionPriorityBadge priority={question.priority} />
                        </td>
                        <td>
                          <Badge color="info">{t(QUESTION_DIFFICULTY[question.difficulty])}</Badge>
                        </td>
                        <td>
                          <div className="d-flex flex-column align-items-center gap-3">
                            <Button
                              color="link"
                              size="sm"
                              id={`edit-${question.id}`}
                              onClick={() =>
                                navigate(`/experiences/${experience.experience_id}/question/${question.id}`, {
                                  state: { question, experience },
                                })
                              }
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                            <Button
                              color="link"
                              size="sm"
                              id={`duplicate-${question.id}`}
                              onClick={() => handleDuplicateQuestion(question)}
                            >
                              <FontAwesomeIcon icon={faClone} />
                            </Button>
                            <Button
                              color="link"
                              size="sm"
                              className={`p-1 ${question.isHidden ? 'text-success' : 'text-danger'}`}
                              onClick={() => handleConfirmHide(question)}
                            >
                              <FontAwesomeIcon icon={question.isHidden ? faEye : faEyeSlash} />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      <ConfirmModal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        title={modalText.title}
        message={modalText.message}
        confirmText={modalText.confirmText}
        cancelText={modalText.cancelText}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};

export default withAuthenticationRequired(ExperienceQuestions, { onRedirecting: () => <Loading /> });
