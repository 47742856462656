import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import gameReducer from './game';
import experienceReducer from './experience';
import inventoryReducer from './inventory';
import teamReducer from './team';
import playerReducer from './player';
import companyReducer from './company';
import prizesReducer from './prizes';
import jackpotReducer from './jackpot';
import privacyPolicyReducer from './privacyPolicy';
import questionsReducer from './questions';

const store = configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    experience: experienceReducer,
    game: gameReducer,
    inventory: inventoryReducer,
    jackpot: jackpotReducer,
    player: playerReducer,
    privacyPolicy: privacyPolicyReducer,
    prizes: prizesReducer,
    questions: questionsReducer,
    team: teamReducer,
  },
});

export default store;
