import { useState, useEffect, useCallback } from 'react';
import { useActor } from '../../storage/storage';
import { useSendMessageOnDemand } from '../../api/CompanyAPI';
import { useFetchTeamsByCompany } from '../../api/TeamAPI';

const useSendMessageOnDemandForm = () => {
  const actor = useActor();
  const [loading, setLoading] = useState(false);
  const { teams, loadingTeam, fetchTeamsByCompany } = useFetchTeamsByCompany();
  const { messageOnDemand, loading: loadingMessage, error } = useSendMessageOnDemand();
  const [messageBody, setMessageBody] = useState({ type: '', teamId: '' });

  useEffect(() => {
    setLoading(loadingTeam || loadingMessage);
  }, [loadingTeam, loadingMessage]);
  useEffect(() => {
    if (!actor) return;
    fetchTeamsByCompany(actor.company_id);
  }, [actor, fetchTeamsByCompany]);

  const handleSelectChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setMessageBody((prev) => ({ ...prev, [name]: value }));
    },
    [setMessageBody]
  );

  const handleClick = useCallback(
    async (event) => {
      event.preventDefault();
      if (!messageBody.type || !messageBody.teamId) return;
      await messageOnDemand(actor.company_id, messageBody);
    },
    [teams, messageBody, messageOnDemand]
  );

  return {
    loading,
    error,
    teams,
    messageBody,
    handleClick,
    handleSelectChange,
  };
};

export default useSendMessageOnDemandForm;
