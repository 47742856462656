import React, { useState, useEffect } from 'react';
import '../assets/css/timer.css';
import CountdownSpinner from './questionary/components/CountdownSpinner';

const Timer = ({ game, setTimeUpControl, endGame, worker }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    if (!worker) return;
    worker.postMessage(game);
    worker.onmessage = async function (event) {
      setCountdown(event.data);
      if (event.data.ended) {
        worker.terminate();
        setTimeUpControl(true);
        await endGame();
      }
    };
  }, []);

  return (
    countdown && (
      <div className="timer-container">
        <CountdownSpinner style={countdown.colorStyle} />
        <div className={`custom-timer-text ${countdown.colorStyle}`}>{countdown.remainingTime}</div>
      </div>
    )
  );
};

export default Timer;
