import { createSlice } from '@reduxjs/toolkit';

const storedPrivacy = sessionStorage.getItem('isPrivacyPolicyAccepted');
const initialState = {
  isAccepted: storedPrivacy !== null ? JSON.parse(storedPrivacy) : undefined,
};

const privacyPolicySlice = createSlice({
  name: 'privacyPolicy',
  initialState,
  reducers: {
    acceptPrivacyPolicy: (state, action) => {
      state.isAccepted = action.payload;
      sessionStorage.setItem('isPrivacyPolicyAccepted', JSON.stringify(action.payload));
    },
  },
});

export const { acceptPrivacyPolicy } = privacyPolicySlice.actions;
export default privacyPolicySlice.reducer;
