import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReturnButton = ({ to, className }) => {
  const navigate = useNavigate();
  return (
    <Button className={className} color="secondary" onClick={() => navigate(to)}>
      Return
    </Button>
  );
};

export default ReturnButton;
