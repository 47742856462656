import { ButtonGroup } from 'reactstrap';

import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/commons/ReturnButton';
import AddButton from '../../components/commons/AddButton';
import { useNavigate } from 'react-router-dom';
import MessageAlert from '../../components/commons/MessageAlert';
import useAwardList from '../../hooks/useAwardList';
import AwardsTable from '../../components/awards/AwardsTable';

const AwardList = () => {
  const { loading, awards, message } = useAwardList();
  const navigate = useNavigate();

  if (loading) return <Loading />;

  return (
    <>
      <MessageAlert message={message} />
      <ButtonGroup>
        <ReturnButton to={'/landing'} />
        <AddButton action={() => navigate('/awards/new')} />
      </ButtonGroup>
      <AwardsTable awards={awards} />
    </>
  );
};

export default withAuthenticationRequired(AwardList, {
  onRedirecting: () => <Loading />,
});
