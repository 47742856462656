import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddButton = ({ action, addClassName, customText, disabled }) => {
  return (
    <Button className={addClassName} color="success" disabled={disabled} onClick={action}>
      <FontAwesomeIcon icon={faPlus} className="me-2" />
      {customText || 'Add'}
    </Button>
  );
};

export default AddButton;
