import { Button } from 'reactstrap';
import React, { useState } from 'react';
import SelectImageModal from './SelectImageModal';
import selectImage from '../../../../assets/img/default/select-image.svg';
import './SelectImage.css';

const SelectImage = ({ customTitle, images, disabled, onSelect, onRemove, selected, current }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <h5 className="mb-2 mt-1 text-dark font-weight-bold">{customTitle || `Modify image`}</h5>
        <Button color="success" disabled={disabled} onClick={toggle}>
          Select
        </Button>
        {onRemove && (
          <Button color="danger" onClick={onRemove}>
            Remove
          </Button>
        )}
      </div>
      <div className="image-container">
        <img src={selected || selectImage} alt="Image" className="question-image" />
      </div>
      <SelectImageModal images={images} isOpen={isOpen} toggle={toggle} current={current} onSelect={onSelect} />
    </>
  );
};

export default SelectImage;
