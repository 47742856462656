import { useCallback, useEffect, useState } from 'react';
import { useCreateExperience, useFetchExperienceById, useUpdateExperience } from '../../api/ExperiencesAPI';
import useMessageAlert from './useMessageAlert';
import { useActor } from '../../storage/storage';
import { useNavigate } from 'react-router-dom';

const useExperienceForm = (id) => {
  const actor = useActor();
  const navigate = useNavigate();
  const { message, showError, showSuccess, cleanMessage } = useMessageAlert();
  const { getExperienceById } = useFetchExperienceById();
  const { response, loading: loadingCreate, createExperience } = useCreateExperience();
  const { loading: loadingUpdate, updateExperience } = useUpdateExperience();
  const [experience, setExperience] = useState({
    name: { en: '', es: '' },
    type: 'REGULAR',
    image: null,
    media_id: null,
    questions: [],
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchExperience = async () => {
      if (!id) return;
      setError(null);
      setLoading(true);
      try {
        setExperience(await getExperienceById(id));
      } catch (err) {
        setError(err.message || 'Failed to fetch experience data.');
      } finally {
        setLoading(false);
      }
    };

    fetchExperience();
  }, [id, getExperienceById]);

  useEffect(() => {
    if (!error) return;
    showError(error);
  }, [error]);

  useEffect(() => {
    setLoading(loadingCreate || loadingUpdate);
  }, [loadingCreate, loadingUpdate]);

  useEffect(() => {
    if (!response) return;
    navigate(`/experiences/${response.experience_id}`);
  }, [response]);

  const handleInputName = (value, lang) => {
    setExperience((prev) => ({ ...prev, name: { ...prev.name, [lang]: value } }));
  };

  const handleTypeChange = (value) => setExperience((prev) => ({ ...prev, type: value }));

  const handleImage = (image) => {
    setExperience((prev) => ({ ...prev, image: image.url, media_id: image.media_id }));
  };

  const checkIfValid = useCallback(() => {
    if (experience.name == null) {
      setError('Invalid Name');
      return false;
    }
    if (experience.name.es == null && experience.name.en == null) {
      setError('Invalid Name');
      return false;
    }
    if (experience.name.es == '' && experience.name.en == '') {
      setError('Invalid Name');
      return false;
    }
    if (experience.media_id == null) {
      setError('Invalid Image');
      return false;
    }
    return true;
  }, [experience]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      cleanMessage();
      if (!checkIfValid()) return;
      setError(null);
      setLoading(true);

      try {
        if (id) {
          await updateExperience(id, experience);
        } else {
          const newExperience = {
            ...experience,
            companyId: actor.company_id,
          };
          await createExperience(newExperience);
        }
        showSuccess('Experience saved sucessfully');
      } catch (e) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [
      id,
      experience,
      error,
      actor.company_id,
      showSuccess,
      createExperience,
      updateExperience,
      cleanMessage,
      checkIfValid,
    ]
  );

  const updateQuestionOffline = (updatedQuestion) => {
    if (!updatedQuestion || !updatedQuestion.id) {
      console.error('updateQuestion failed: Invalid question data', updatedQuestion);
      throw new Error('Invalid question data');
    }
    setExperience((prev) => {
      if (!prev || !prev.questions) {
        console.error('updateQuestion failed: Experience or questions data is missing', prev);
        throw new Error('Experience or questions data is missing');
      }
      const existingQuestion = prev.questions.find((q) => q.id === updatedQuestion.id);
      if (!existingQuestion) {
        console.error(`updateQuestion failed: No question found with ID: ${updatedQuestion.id}`);
        throw new Error('Question not found in experience');
      }
      const updatedQuestions = prev.questions.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q));

      return { ...prev, questions: updatedQuestions };
    });
  };

  return {
    message,
    experience,
    error,
    loading,
    updateQuestionOffline,
    handleSubmit,
    handleInputName,
    handleTypeChange,
    handleImage,
  };
};

export default useExperienceForm;
