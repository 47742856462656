const CountdownWorker = () => {
  const EXTRA_TIME_IN_SECS = 4;
  const DANGER_LIMIT_IN_SECS = 15;
  const WARNING_LIMIT_IN_SECS = 60;
  const MIN_LATE_START_IN_MILLIS = 1200;

  let countdownInterval;
  let initCountdown;

  onmessage = function (event) {
    if (event.data.clear) {
      clearInterval(countdownInterval);
      return;
    }
    if (event.data.shouldExtendGame) {
      initCountdown = EXTRA_TIME_IN_SECS;
      return;
    }
    initialize(event.data);
  };

  const initialize = (game) => {
    const expectedEndAt = new Date(game.expected_end_at);
    const now = new Date();
    if (now.getTime() > expectedEndAt.getTime()) {
      postMessage({ ended: true });
      return;
    }
    const startedAt = new Date(game.started_at);
    const start = now.getTime() - startedAt.getTime() > MIN_LATE_START_IN_MILLIS ? now : startedAt;
    initCountdown = (expectedEndAt.getTime() - start.getTime()) / 1000;

    countdownInterval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    calculateRemainingTime();
  };

  const calculateRemainingTime = () => {
    initCountdown--;
    const getColorStyle = () => {
      if (initCountdown <= DANGER_LIMIT_IN_SECS) return 'danger';
      if (initCountdown <= WARNING_LIMIT_IN_SECS) return 'warning';
      return 'game-normal';
    };

    if (initCountdown > 0) {
      const minutes = Math.floor(initCountdown / 60);
      const seconds = Math.floor(initCountdown % 60);
      const message = {
        remainingTime: `0${!minutes ? '0' : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
        ended: false,
        colorStyle: getColorStyle(),
      };
      postMessage(message);
      return;
    }
    const message = {
      remainingTime: '00:00',
      colorStyle: 'danger',
      ended: true,
    };
    postMessage(message);
  };
};

export default CountdownWorker;
