import { useActor } from '../storage/storage';

const EN = 'en';
const ES = 'es';
const SUPPORTED_LANGUAGES = [EN, ES];

const useLangHandler = () => {
  const actor = useActor();
  const T = (multiLangObj, overrideLang = null) => {
    const lang = overrideLang ? overrideLang : new Intl.Locale(actor.lang).language;

    if (multiLangObj === null || multiLangObj === undefined) return multiLangObj;

    switch (multiLangObj.constructor) {
      case {}.constructor:
        if (overrideLang) {
          return multiLangObj[overrideLang];
        }

        let key = multiLangObj.hasOwnProperty(lang) ? lang : Object.keys(multiLangObj)[0];
        return multiLangObj[key];
      default:
        return multiLangObj;
    }
  };

  return { T };
};

export { useLangHandler, EN, ES, SUPPORTED_LANGUAGES };
