import { useState } from 'react';

const useMessageAlert = () => {
  const [message, setMessage] = useState({ isSet: false, type: null, text: null });

  const showMessage = (text, type) => setMessage({ isSet: true, type: type, text: text });
  const showError = (text) => showMessage(text, 'danger');
  const showSuccess = (text) => showMessage(text, 'success');
  const cleanMessage = () => setMessage({ isSet: false, type: null, text: null });

  return {
    message,
    showError,
    showSuccess,
    cleanMessage,
  };
};

export default useMessageAlert;
