import React from 'react';
import { Table, Input } from 'reactstrap';
import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const PieceProbability = ({ config, index, handleInputPieces, t }) => {
  return (
    <Table borderless responsive size="sm" className="text-center">
      <thead>
        <tr>
          <th>{t('config.piece.pieces')}</th>
          <th>{t('config.piece.probability')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>4</td>
          <td>
            {config.piece_probability[4].map((value, id) => (
              <Input
                type="number"
                step="0.01"
                id={id}
                value={value}
                onChange={(e) => handleInputPieces(config.piece_probability[4], index, e)}
              />
            ))}
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>
            {config.piece_probability[6].map((value, id) => (
              <Input
                type="number"
                step="0.01"
                id={id}
                value={value}
                onChange={(e) => handleInputPieces(config.piece_probability[6], index, e)}
              />
            ))}{' '}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PieceProbability;
