import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Home from './home/Home';
import GameContainer from './GameContainer';
import Loading from '../components/Loading';
import '../assets/css/client.css';
import '../assets/css/buttons.css';

const Client = () => {
  return (
    <GameContainer active={'Game'}>
      <Home />
    </GameContainer>
  );
};

export default withAuthenticationRequired(Client, {
  onRedirecting: () => <Loading />,
});
