import axios from 'axios';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { isSameDay, isExpired } from '../../utils/date';
import { applyHeaders } from '../../api/axiosClient.js';
import { BACKEND_URL, POINTS_FOR_BONUS } from '../../utils/constants.js';
import { finishGame, initGame, markAsPlayed, updateExpectedEndAt } from '../../state/game.js';

import CountdownWorker from '../workers/CountdownWorker.js';
import FineCountdownWorker from '../workers/FineCountdownWorker';
import FineModal from './FineModal.js';
import Loading from '../../components/Loading';
import Timer from '../Timer.js';
import ProgressBar from '../ProgressBar.js';
import Question from './Question.js';
import WorkerEnabler from '../workers/WorkerEnabler.js';
import Client from '../Client.js';

import '../../assets/css/questionary.css';
import correctSound from '../../assets/sfx/correct.mp3';
import { useActor, useExperience, useInventory, usePlayer, useQuestions } from '../../storage/storage.js';
import { useUpdateInventoryContext } from '../../api/InventoryAPI.js';
import { getComponents } from './questions.js';
import { Navigate } from 'react-router-dom';

let timerWorker;
let fineWorker;

const Questionary = () => {
  if (!timerWorker) timerWorker = new WorkerEnabler(CountdownWorker);
  if (!fineWorker) fineWorker = new WorkerEnabler(FineCountdownWorker);
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const player = usePlayer();
  const actor = useActor();
  const experience = useExperience();
  const questions = useQuestions();
  const { game, isPlayed } = useSelector((state) => state.game);
  const { updateInventoryContext } = useUpdateInventoryContext();

  const inventory = useInventory();
  const correctAudio = new Audio(correctSound);

  const [isFined, setIsFined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clean, setClean] = useState(false);
  const [timeUpControl, setTimeUpControl] = useState(false);
  const [components, setComponents] = useState(getComponents(questions));
  const [bonusPoints, setBonusPoints] = useState(parseInt(inventory.bonus_points) % POINTS_FOR_BONUS);
  const penaltyReduction =
    inventory.fine_expires_at && !isExpired(inventory.fine_expires_at) ? inventory.penalty_reduction : 0;

  const emptyFn = (e) => e.preventDefault();

  const nextQuestion = () => getQuestions(true);

  const getQuestions = (dirty) => {
    setClean(dirty);
    if (timeUpControl) return;
    setComponents((prevComponents) => getComponents(prevComponents.questions));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', emptyFn);

    return () => {
      window.removeEventListener('beforeunload', emptyFn);
    };
  }, []);

  useEffect(() => {
    if (!player || !experience) return;
    const createGame = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      try {
        setLoading(true);
        const gameParam = {
          playerId: player.player_id,
          experienceId: experience.experience_id,
        };
        const response = await axios.post(`${BACKEND_URL}/game`, gameParam, headers);
        dispatch(initGame(response.data));
      } catch (error) {
        console.error(error);
        dispatch(markAsPlayed());
      } finally {
        setLoading(false);
      }
    };

    const getGame = async () => {
      if (game && !isPlayed && isSameDay(game.started_at)) return;
      await createGame();
    };
    getGame();
  }, [experience, game, dispatch, initGame, player]);

  const handleAnswerSubmit = async (resultId, questionId) => {
    if (isPlayed) return;
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      const answer = {
        questionId: questionId,
        selectedOption: resultId,
        actorId: actor.actor_id,
        selectedAt: components.question.selectedAt,
        answeredAt: Date.now(),
      };
      const { data } = await axios.patch(`${BACKEND_URL}/game/${game.game_id}/score`, answer, headers);
      const { isCorrect, shouldExtendGame, expectedEndAt } = data;
      dispatch(updateExpectedEndAt(expectedEndAt));
      if (!isCorrect && resultId !== components.question.validAnswerOptionId) {
        fineWorker = new WorkerEnabler(FineCountdownWorker);
        setIsFined(true);
        return;
      } else {
        await correctAudio.play();
      }
      if (shouldExtendGame) timerWorker.postMessage({ shouldExtendGame: true });

      const defBonus = bonusPoints + 1;
      setBonusPoints(defBonus > POINTS_FOR_BONUS ? 1 : defBonus);

      nextQuestion();
    } catch (error) {
      console.error('error: ', error);
      if (error.response.status === 400) dispatch(markAsPlayed());
      if (error.response.status === 409) await endGame();
    }
  };

  const onFineComplete = () => {
    fineWorker.postMessage({
      clear: true,
    });
    setIsFined(false);
    nextQuestion();
  };

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const endGame = async () => {
    if (isPlayed) return;
    setLoading(true);
    try {
      const diff = new Date(game.expected_end_at) - Date.now();
      await timeout(diff);
      setIsFined(false);
      timerWorker.postMessage({
        clear: true,
      });
      fineWorker.terminate();
      timerWorker.terminate();
      timerWorker = new WorkerEnabler(CountdownWorker);

      const headers = await applyHeaders(getAccessTokenSilently);
      const res = await axios.patch(`${BACKEND_URL}/game/${game.game_id}`, {}, headers);
      dispatch(finishGame(res.data));
    } catch (error) {
      if (
        error?.response?.status === 400 &&
        error?.response?.data &&
        error?.response?.data?.message.indexOf('plaaaying')
      )
        return;
      console.error('Something went wrong ending the game', error);
      dispatch(markAsPlayed());
    } finally {
      await updateInventoryContext();
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  if (!game) return <Client />;

  if (isPlayed) {
    window.removeEventListener('beforeunload', emptyFn);
    return <Navigate to="/end-game" />;
  }

  return (
    <main className="client client-app">
      <div className="questionary">
        <ProgressBar currentBonus={bonusPoints} pointsForBonus={POINTS_FOR_BONUS} />
        {game && <Timer game={game} setTimeUpControl={setTimeUpControl} endGame={endGame} worker={timerWorker} />}
        {components.question && (
          <Question
            question={components.question}
            handleAnswerSubmit={handleAnswerSubmit}
            clean={clean}
            setClean={setClean}
            timeUpControl={timeUpControl}
          />
        )}
        {isFined && <FineModal onFinish={onFineComplete} worker={fineWorker} penaltyReduction={penaltyReduction} />}
      </div>
    </main>
  );
};

export default withAuthenticationRequired(Questionary, {
  onRedirecting: () => <Loading />,
});
