import axios from 'axios';
import React, { useEffect } from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { hasRole } from '../utils/roleUtil';
import { applyHeaders } from '../api/axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useActor } from '../storage/storage';

import Loading from '../components/Loading';
import '../i18n/i18n';
import useClient from '../client/hooks/useClient';
import useTrooper from '../backOffice/hooks/useTrooper';

const LandingPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const { t, i18n } = useTranslation();
  const { canTroop, loadingCanTroop } = useTrooper();
  const { loadingClient } = useClient();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const makeTrooper = async () => {
    const headers = await applyHeaders(getAccessTokenSilently, 'application/json');
    const url = `${BACKEND_URL}/trooper/${actor.actor_id}`;

    await axios
      .patch(url, {}, headers)
      .then(() => {
        sessionStorage.removeItem('actor');
        window.location.reload();
      })
      .catch((e) => alert(e));
  };

  if (loadingClient || loadingCanTroop) return <Loading />;

  return (
    <div>
      <h3>{t('chooseOption')}</h3>
      {!loadingCanTroop && canTroop && (
        <Button className="btn btn-primary" onClick={makeTrooper}>
          Return to Companies
        </Button>
      )}
      <ListGroup>
        {hasRole(actor, 'TROOPER') && (
          <>
            <ListGroupItem action tag={NavLink} to="/companies">
              {t('menu.companies')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/config/daily-chest">
              {t('menu.dailyChest')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/config/booster">
              {t('menu.booster')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/config/piece">
              {t('menu.piece')}
            </ListGroupItem>
          </>
        )}
        {hasRole(actor, 'SUPREME_LEADER') && (
          <>
            <ListGroupItem action tag={NavLink} to="/images">
              Images
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/jackpot">
              {t('menu.lottery')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/prize">
              {t('menu.catalogue')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/rewards">
              Rewards
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/experiences">
              {t('menu.content')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/invite/new-members">
              {t('menu.invLeader')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/teams">
              {t('menu.teams')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/metrics">
              {t('menu.metrics')}
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/messages-on-demand">
              Messages on Demand
            </ListGroupItem>
            <ListGroupItem action tag={NavLink} to="/awards">
              Awards (Pieces/Tickets)
            </ListGroupItem>
          </>
        )}
        {hasRole(actor, 'PLAYER') && <>{!loadingClient && <Navigate to="/client" />}</>}
      </ListGroup>
    </div>
  );
};

export default withAuthenticationRequired(LandingPage, {
  onRedirecting: () => <Loading />,
});
