import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '../../../storage/storage';

const ExperienceCarousel = ({ experiences, selectExperience }) => {
  const actor = useActor();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    void i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === experiences.length - 1 ? 0 : activeIndex + 1;
    selectExperience(experiences[nextIndex].experience_id);
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? experiences.length - 1 : activeIndex - 1;

    selectExperience(experiences[nextIndex].experience_id);
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    selectExperience(experiences[newIndex].experience_id);
    setActiveIndex(newIndex);
  };

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} ride={null} interval={false}>
      <CarouselIndicators items={experiences} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {experiences.map((item, index) => (
        <CarouselItem key={item.experience_id}>
          <div className="experience-title">
            <h1>
              {t('game.home.mission')} {index + 1}
            </h1>
            <p>{item.name}</p>
            <img src={item.image} alt={item.name} style={{ borderRadius: '50%', width: '75%', aspectRatio: '1/1' }} />
          </div>
        </CarouselItem>
      ))}
      {experiences.length > 1 && (
        <>
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </>
      )}
    </Carousel>
  );
};

export default ExperienceCarousel;
