import { useTranslation } from 'react-i18next';
import { useCreateUserTrack } from '../../../api/UserTrackAPI';
import { useEffect } from 'react';
import { useActor } from '../../../storage/storage';

const HomeButton = ({ isMissionCompleted, isChestAvailable }) => {
  const { t, i18n } = useTranslation();
  const actor = useActor();
  const { trackAndNavigate } = useCreateUserTrack();

  useEffect(() => {
    void i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const innerText = isMissionCompleted ? t('game.home.rewards') : t('game.home.start');
  const navigateTo = isMissionCompleted ? '/prize-catalog' : '/questionary';
  const buttonClass = isMissionCompleted || isChestAvailable ? 'open' : 'primary';
  const event = isMissionCompleted ? 'PRIZE_CATALOG_CLICK' : 'START_MISSION_CLICK';

  return (
    <button className={`gali-button ${buttonClass}`} onClick={() => trackAndNavigate(navigateTo, event)}>
      {innerText}
    </button>
  );
};

export default HomeButton;
