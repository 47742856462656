import { useState } from 'react';
import { EN } from '../../api/LanguageAPI';

const useMultiLangTab = () => {
  const [activeTab, setActiveTab] = useState(EN);
  const toggle = (lang) => {
    if (activeTab !== lang) setActiveTab(lang);
  };

  return { activeTab, toggle };
};

export default useMultiLangTab;
