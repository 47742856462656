import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const TeamSelect = ({ selectedTeam, onChange, teams }) => {
  return (
    <FormGroup floating>
      <Input id="selectTeam" name="teamId" type="select" value={selectedTeam} onChange={onChange}>
        <option />
        {teams.map((team, index) => (
          <option key={index} value={team.team_id}>
            {team.name}
          </option>
        ))}
      </Input>
      <Label className="admin-text" for="selectTeam">
        Select Team
      </Label>
    </FormGroup>
  );
};

export default TeamSelect;
