import React from 'react';
import { Table, Input } from 'reactstrap';
import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import PieceProbability from './PieceProbability';

const PieceContent = ({ pieceConfigList, handleInputCatalog, handleInputPieces, t }) => {
  const catalogTypeNames = {
    0: 'config.booster.small',
    1: 'config.booster.medium',
    2: 'config.booster.large',
  };

  return (
    <Table hover borderless responsive size="sm" striped className="text-center">
      <thead>
        <tr>
          <th>{t('config.piece.catalogProbability')}</th>
          <th>{t('config.piece.catalogType')}</th>
          <th colspan="2">{t('config.piece.pieceProbability')}</th>
        </tr>
      </thead>
      <tbody>
        {pieceConfigList.map((config, index) => (
          <tr key={index}>
            <td>{t(catalogTypeNames[config.catalog_type]) || 'Unknown'}</td>
            <td>
              <Input
                type="text"
                name="catalog_probability"
                value={config.catalog_probability}
                onChange={(e) => handleInputCatalog(config, index, e)}
              />
            </td>
            <td colspan="2">
              <PieceProbability config={config} index={index} handleInputPieces={handleInputPieces} t={t} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PieceContent;
