import React from 'react';
import './CategoryBadge.css';

const CategoryBadge = ({ label, isActive, image, position, color }) => (
  <div
    className={`category-badge ${isActive ? 'category-badge--active' : 'category-badge--disabled'}`}
    style={{ left: `${position}%`, ...(isActive && { background: color }) }}
  >
    <div
      className={`category-badge__indicator ${!isActive ? 'category-badge__indicator--disabled' : ''}`}
      style={{ borderBottomColor: isActive ? color : '#666' }}
    ></div>
    {isActive && (
      <>
        <img src={image} alt={label} className="category-badge__icon" />
      </>
    )}
    <span className="category-badge__text">{label}</span>
  </div>
);

export default CategoryBadge;
