import { useDispatch } from 'react-redux';
import { usePlayer } from '../../../storage/storage';
import { useFetchGame } from '../../../api/GameAPI';
import { useEffect } from 'react';
import { resetGame, resumeGame } from '../../../state/game';

const useGame = () => {
  const player = usePlayer();
  const dispatch = useDispatch();
  const { game, loading: loadingGame, fetchGame } = useFetchGame();

  useEffect(() => {
    if (!player) return;
    fetchGame(player.player_id);
  }, [player, fetchGame]);

  useEffect(() => {
    if (!game) {
      dispatch(resetGame());
      return;
    }
    const gameOnCourse = {
      game: game,
      isPlayed: !!game.ended_at,
    };
    dispatch(resumeGame(gameOnCourse));
  }, [game, resetGame, resumeGame, dispatch]);

  return {
    loadingGame,
  };
};

export default useGame;
