import { useEffect } from 'react';
import { useActor } from '../../storage/storage';
import { useFetchActiveJackpot } from '../../api/JackpotAPI';

const useJackpot = () => {
  const actor = useActor();
  const { loading: loadingJackpot, fetchActiveJackpot } = useFetchActiveJackpot();

  useEffect(() => {
    if (!actor) return;
    fetchActiveJackpot(actor.company_id);
  }, [actor, fetchActiveJackpot]);

  return {
    loadingJackpot,
  };
};

export default useJackpot;
