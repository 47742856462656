import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePlayer, useIsPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted } from '../../../storage/storage';
import { useUpdateAcceptTerms } from '../../../api/ActorAPI';
import { useGetIp } from '../../../api/IpAPI';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const player = usePlayer();
  const isPrivacyPolicyAccepted = useIsPrivacyPolicyAccepted();
  const dispatch = useDispatch();
  const { acceptTerms, isLoading } = useUpdateAcceptTerms();
  const { ip } = useGetIp();

  const [isPolicyRead, setIsPolicyRead] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const isFormValid = isPolicyRead && isTermsAccepted;

  const handleAcceptTerms = async () => {
    if (!isFormValid || !ip) return;
    try {
      await acceptTerms(ip);
      setIsPrivacyPolicyAccepted(true, dispatch);
      window.location.href = '/';
    } catch (error) {
      console.error('Error accepting terms:', error);
    }
  };

  return (
    <div className="privacy-policy">
      <h2 className="privacy-policy__title">Privacy Policy</h2>
      <div className="privacy-policy__card">
        <div className="privacy-policy__content">
          <p>
            GALILEI LEARNING CORPORATION ("Galilei") is a company respectful of the personal data provided by its
            current and potential Platform Users, employees, and possible interested parties in Galilei's services, and,
            therefore, has implemented this Privacy Policy (the "Policy"), where we tell you which Data we process, why
            we process it, what your rights as a User are, and the mechanisms you have to exercise them.
          </p>
          <p>
            The use of the Galilei Platform, the subscription to our Platform, whether free or paid, or the acceptance
            of the Terms of Use and the acceptance of the Policy implies that you accept that we treat your Data under
            this Policy.
          </p>

          <h3>1. Definitions</h3>
          <p>
            <strong>"Authorization":</strong> is the prior, express, and informed consent you give us to process your
            Data as follows: (i) by clicking on "I accept" the Policy; (ii) by logging in or creating an account on the
            Platform and clicking on "accept" the Policy or (iii) by entering into any process with us or acting as a
            supplier, contractor, business partner, user, or customer.
          </p>
          <p>
            <strong>"Data":</strong> is any personal information that serves to identify you.
          </p>
          <p>
            <strong>"Database":</strong> is the organized set of Data subject to Processing.
          </p>
          <p>
            <strong>"Claim":</strong> is the request you make to exercise your rights related to your Data.
          </p>
          <p>
            <strong>"Consultation":</strong> is the request that you, as a User, make to know the information we have
            about you in our Databases.
          </p>
          <p>
            <strong>"Processing":</strong> any operation we perform on your Data, such as the collection, storage,
            processing, analysis, use, circulation, transfer, transmission, updating, correction, or deletion of Data,
            among others. The Processing may be national or international.
          </p>
          <p>
            <strong>"Sensitive Data":</strong> is the Data that affects your privacy or whose improper use may generate
            discrimination, for example, Data revealing racial or ethnic origin, political orientation, religious or
            philosophical convictions, union membership, as well as data relating to health, sex life, and biometric
            data, such as fingerprints, among others.
          </p>
          <p>
            <strong>"User":</strong> is you, the person whose Data is subject to Processing.
          </p>
          <p>
            <strong>"Purpose":</strong> is how Galilei will process your Data.
          </p>
          <p>
            <strong>"Platform":</strong> refers to the Platform through which Galilei Services are provided.
          </p>
          <p>
            <strong>"Services":</strong> means the application programming interface service that offers a gamified
            training platform designed to enhance the onboarding, training, and retention of frontline employees,
            particularly in the quick-service restaurant (QSR) industry.
          </p>
          <p>
            <strong>"Terms of Use":</strong> refers to the terms of use of the Platform and the Services offered by
            Galilei, which can be found at{' '}
            <a href="https://www.galileilearning.com/legal/tos" target="_blank" rel="noopener noreferrer">
              www.galileilearning.com/legal/tos
            </a>
          </p>

          <h3>2. Scope</h3>
          <p>
            This Policy applies to all Data held by Galilei, which collects and processes Data in the performance of the
            Services.
          </p>

          <h3>3. When do we collect your Data, and what do we collect?</h3>
          <h4>Data Collection</h4>
          <p>Galilei collects Data in the following events:</p>
          <ul>
            <li>When the Users subscribe to the Platform.</li>
            <li>
              When the Users provide Data voluntarily, for example, when they register to the Platform, create or modify
              an account within it.
            </li>
            <li>When Users acquire any of our Services.</li>
            <li>When the Users use Galilei's Services.</li>
            <li>When the Users contact the Platform or any Galilei Team member.</li>
            <li>
              When the Users express interest in joining the Galilei team, submit their résumé, or undergo a selection
              process.
            </li>
            <li>
              When the Users enter into an agreement with Galilei, such as employment, service provision, or any other
              type.
            </li>
          </ul>

          <h4>Information Collected</h4>
          <p>Galilei may ask you for or collect the following information from you:</p>
          <ul>
            <li>
              Data we collect from all Users: first and last name, phone number, email. If the User is an entity, we
              will ask for the type of entity, unique tax registration number, corporate name, and billing email.
            </li>
          </ul>

          <h4>Image Data</h4>
          <p>
            By participating in any videoconference or event sponsored by Galilei, you accept and authorize that your
            name and images appear in publications and other advertising media. Additionally, they may be used in any
            material for promotional or commercial purposes that Galilei wishes to create for a term of 50 years,
            without any obligation to indemnify or compensate. By accepting this Policy, you waive any claim against
            Galilei for image rights.
          </p>

          <h3>4. Why do we collect your Data?</h3>
          <h4>General Purpose</h4>
          <p>
            In general, Galilei will collect, store, organize, circulate, use, and otherwise process Data following the
            purposes set forth below:
          </p>
          <ul>
            <li>
              <strong>Purposes common to everyone:</strong> To provide the Services; to comply with legal, accounting,
              commercial, and regulatory duties that apply to Galilei; to control and preserve the security of persons,
              property, and information; to verify the identity of Users; to perform authentication processes of
              registered accounts (if applicable); to ensure the effectiveness and security of transactions and
              operations carried out on Galilei's Platform; to detect unauthorized uses of the Platform in accordance
              with the Terms of Use; to administer Galilei's website and operate the Platform; to host the User’s Data
              on Galilei's servers; to disclose information to comply with laws, regulations, or legal processes; to
              stop or prevent fraud, attacks on security, and technical issues; to verify references of Users in
              Databases; to maintain direct communication with Users regarding Services, Platform updates, and policy
              changes; to respond to complaints, requests, or suggestions; to transmit, transfer, and provide User Data
              to the holding company or other companies within Galilei’s business group, including existing and future
              entities; to share Data with commercial allies, national or international companies, or service providers
              responsible for processing Data; to conduct statistical, financial, and administrative analysis; to
              provide information to governmental or regulatory authorities when required by law or judicial order; to
              contact Users through internet, phone calls, text messages, or email; to conduct marketing activities such
              as market research and service promotions; to invite Users to events; and to use Data as evidence in any
              legal or administrative process.
            </li>
            <li>
              <strong>Purposes for which we process User Data:</strong> In addition to the common purposes, Galilei
              processes User Data to operate the Platform and its Services; to invoice Services to Users; to ensure
              compliance with the service level agreements and value propositions offered to Users; to contact Users via
              messaging platforms, email, or other means of communication regarding Service-related inquiries; to
              perform profiling, analytics, pattern definition, and network effect analysis, with or without the use of
              artificial intelligence tools, to enhance Services; to define consumption profiles of Users' Content; to
              optimize Platform functionalities; and to inform Users about the scope and characteristics of Galilei’s
              Services, including new and existing solutions.
            </li>
          </ul>

          <h3>5. What happens if we use your Data for another Purpose?</h3>
          <p>
            Galilei will request your Authorization for new uses of your Data, for which we will publish the changes in
            our Privacy Policy on our Platform or in any medium we deem appropriate, and we will notify you about the
            change. If you disagree with the new Purposes, you may revoke the Authorization, and we will delete your
            Data if we do not have any legal duty to keep it.
          </p>

          <h3>6. What happens if you do not allow us to collect certain information?</h3>
          <p>If Users refrain from providing certain information, you understand and agree that:</p>
          <ul>
            <li>Galilei will not be able to proceed with creating the User's account within the Platform.</li>
            <li>Users will not be able to access the Services properly.</li>
            <li>Certain functions within the Platform may be limited, at Galilei's discretion.</li>
          </ul>

          <h3>7. What are "Cookies," and what do we use them for?</h3>
          <h4>Cookies</h4>
          <p>
            A cookie is a harmless file downloaded to your device when you access certain web pages or applications.
            Cookies allow us, among other things, to store and retrieve information about a user's browsing habits and
            trends on a user’s device. Galilei uses its own and third-party cookies.
          </p>

          <h4>Cookies store information</h4>
          <p>
            Cookies store information of a technical nature, such as personal preferences, content personalization,
            previously entered form information to avoid re-entry, and interactions with our Platform, among other data
            that allows us to remember you.
          </p>

          <h4>Purposes for which we use the information collected through cookies</h4>
          <p>Galilei uses cookies to:</p>
          <ul>
            <li>
              Provide a high value-added service by saving your preferences, browsing habits, and usage patterns on our
              Platform.
            </li>
            <li>Personalize your browsing experience.</li>
            <li>Generate metrics and analytics about visitors to our Platform.</li>
            <li>Gather information that allows us to improve content and services.</li>
          </ul>
          <p>
            You may refuse to use cookies by adjusting the appropriate settings on your device. However, please be aware
            that doing so will prevent us from tracking any information about you when you visit or use our Platform,
            and certain Platform functions may not work properly. If you disable cookies, we will only store the
            necessary cookies to remember your preference not to use cookies.
          </p>

          <h3>
            8. What does it mean when you click 'I authorize processing my data' as described in Galilei's Privacy
            Policy?
          </h3>
          <p>
            By voluntarily providing your data, granting written authorization, or clicking “I authorize the processing
            of my data,” you declare that:
          </p>
          <ul>
            <li>
              You voluntarily provide us with your Data and expressly and unequivocally authorize us to collect your
              Data and any other information you provide, as well as to perform any other Processing on your Data,
              following the provisions of this Policy.
            </li>
            <li>
              Galilei informed you, and you understand that Sensitive Data affects your privacy and that improper use
              may generate discrimination. Sensitive Data includes information related to racial or ethnic origin,
              political orientation, religious or philosophical beliefs, union membership, social organizations, health
              status, sex life, and biometric data.
            </li>
            <li>
              You were informed that, in case of collection of Sensitive Data, you have the right not to provide the
              requested Data or Authorization.
            </li>
            <li>
              You were informed about the purposes for which your Data and Sensitive Data collected will be used, as
              described in this Policy.
            </li>
            <li>
              You were informed and understand the security measures that Galilei implements to protect the Data it
              collects; therefore, you accept them.
            </li>
            <li>You were informed about your rights concerning your Data and the mechanisms to exercise them.</li>
            <li>
              If you are a User, you were informed that Galilei needs to maintain permanent contact with you, and your
              right to update your Data will be subject to verification that you have access to the new contact
              information you provide.
            </li>
          </ul>
          <p>
            We collect and use your Data solely for purposes related to our services, including but not limited to
            account verification, service updates, and relevant notifications.
          </p>
          <p>
            We are committed to maintaining compliance with all applicable privacy standards, and we will not share your
            mobile phone number with third parties for marketing or other purposes without your explicit consent. We
            respect your privacy and will only use your information in accordance with the purposes outlined in our
            Privacy Policy.
          </p>

          <h3>9. For how long will we process your Data?</h3>
          <p>
            We will process your Data from the time you authorize us and for as long as necessary to provide the
            Services, comply with legal obligations, promote safety, integrity, and security, and operate the Platform.
          </p>

          <h4>Archiving of your Data</h4>
          <p>The Data will be archived in the Databases as historical information of the Data owners.</p>

          <h3>10. How do we take care of your Data?</h3>
          <h4>Security measures for the protection of Data and other information</h4>
          <p>
            Galilei's security measures seek to protect the User’s Data to prevent its adulteration, loss, use, and
            unauthorized access. Galilei diligently implements human, administrative, and technical protection measures
            that are reasonably within reach, such as sharing information in encrypted form and having adequate computer
            protection. Through this Policy, you accept these forms of protection and declare that you consider them
            appropriate and sufficient to protect your Data.
          </p>
          <p>
            The Data of Galilei's Users will be stored in a Database on third-party servers that may be located in the
            United States or France.
          </p>

          <h4>Policy for the deletion and suppression of Data</h4>
          <p>
            Once the Purpose of the Processing of your Data has been fulfilled or when the User requests it directly to
            Galilei, and provided that no contractual or legal duty or obligation prevents it, Galilei will proceed to
            the deletion or elimination of your Data, according to the following parameters:
          </p>
          <ul>
            <li>
              Galilei will suppress or delete the User Data if: (i) the User freely and voluntarily requests it; (ii)
              when the authorization term is fulfilled according to this Policy; or (iii) if we consider that it is no
              longer necessary for the purposes outlined in this Policy. To proceed, you must request deletion of your
              Data through the email <a href="mailto:help@galileilearning.com">help@galileilearning.com</a> or enter the
              privacy settings of your account to make the corresponding request.
            </li>
            <li>
              Galilei will annually evaluate the information it has about the Users and, according to its
              characteristics and status, will choose the Data that should be deleted or eliminated. This process will
              consider whether there are any rights of the User that prevent deletion or any judicial, legal, or
              contractual duties that require retention.
            </li>
            <li>
              Galilei will inform Users of the decision and the basis for the deletion of their Data. Users may exercise
              their right to file Queries, Petitions, or Claims regarding such decisions within fifteen (15) business
              days following notification. If there is no Claim or request, the Data will be deleted.
            </li>
            <li>
              Galilei will keep a record of the processes of deletion and elimination of the User’s Data for
              traceability of the procedure.
            </li>
          </ul>

          <h4>Confidentiality</h4>
          <p>Galilei will take appropriate measures to ensure confidentiality, including:</p>
          <ul>
            <li>Refraining from disclosing confidential information to unauthorized individuals or entities.</li>
            <li>
              Refraining from using, exploiting, employing, publishing, or disclosing confidential information in a
              manner different from that authorized in the Policy.
            </li>
            <li>
              Instructing all those with access to confidential information on proper handling, usage, and security
              measures to maintain protection.
            </li>
            <li>
              Ensuring confidential information is managed in accordance with ethical principles and best practices in
              Data protection.
            </li>
          </ul>

          <h4>Policies of Truthfulness and Quality of Information</h4>
          <p>
            Galilei will take necessary measures to ensure that the information in its Databases is true, complete,
            accurate, current, verifiable, and understandable. To achieve this, Galilei will:
          </p>
          <ul>
            <li>
              Ensure that the information collected from Users is complete, accurate, updated, verifiable, and
              understandable by implementing due diligence measures in data collection.
            </li>
            <li>Refrain from processing User Data that is partial, incomplete, fragmented, or confusing.</li>
            <li>
              Verify the identity of Users and the information they provide by requesting necessary accreditation
              documents when applicable, including identification documents and certifications from private or public
              entities.
            </li>
          </ul>

          <h3>11. What can you do with your Data as a User?</h3>
          <h4>Rights of the Users</h4>
          <p>
            You have the right to know, update, rectify your information, and revoke the Authorization for Processing
            your Data. Below are your specific rights:
          </p>
          <ul>
            <li>
              <strong>Information, access, and rectification:</strong> You may access, free of charge, the Data that has
              been processed about you, correct or update your information through the privacy settings in your account,
              be informed about how your data has been used, and request additional details regarding the data collected
              or inferred about you. To ensure privacy and security, we may verify your identity before granting access
              to your data or fulfilling requests for correction, updates, or deletion, thereby safeguarding personal
              information and maintaining confidentiality.
            </li>
            <li>
              <strong>Deleting or limiting the Processing of Data:</strong> You may revoke the Authorization or request
              the deletion, either partial or total, of your Data. You can use your account settings to manage your
              information and privacy. However, the request to delete information and revoke Authorization will not
              proceed when you have a legal or contractual duty to remain in Galilei's Database.
            </li>
          </ul>

          <h4>Procedure to exercise your rights</h4>
          <p>
            If you wish to exercise your rights, please email{' '}
            <a href="mailto:help@galileilearning.com">help@galileilearning.com</a>, which Galilei's data protection team
            will handle. Your email must include:
          </p>
          <ul>
            <li>(i) Name and identification of the User or the person entitled.</li>
            <li>(ii) A precise and complete description of the facts that give rise to the Claim.</li>
            <li>(iii) The specific Claim.</li>
            <li>(iv) A physical or electronic address to send the response.</li>
            <li>(v) Documents and other relevant evidence that you want to assert.</li>
          </ul>

          <h4>Procedure followed for such communication</h4>
          <ul>
            <li>
              When the User requests access to information held in the Database, Galilei will respond within a maximum
              of fourteen (14) business days.
            </li>
            <li>
              When Users believe that information contained in the Databases should be corrected, updated, or deleted,
              or when they believe that Galilei is failing to comply with its duties, they may file a claim with
              Galilei. The process follows these rules:
            </li>
            <ul>
              <li>
                The Claim must be addressed to Galilei and include: (i) User identification, (ii) a description of the
                facts giving rise to the Claim, (iii) an address, and (iv) supporting documents.
              </li>
              <li>
                If the Claim is incomplete, Galilei may request the missing information within ten (10) days after
                receipt. If the User does not provide the required information within two (2) months from the request
                date, the Claim will be considered withdrawn.
              </li>
              <li>
                If Galilei is not competent to resolve the Claim, it will be forwarded to the appropriate entity within
                fifteen (15) business days, with notification to the User.
              </li>
              <li>
                Galilei will respond to the Claim within fourteen (14) business days from the reception date. If more
                time is required, the User will be informed of the delay and the estimated resolution date.
              </li>
              <li>
                The withdrawal or deletion of Data will not proceed if there is a contractual duty to maintain the Data
                in Galilei's Database.
              </li>
            </ul>
          </ul>

          <h3>12. What are Galilei's Responsibilities in Processing Your Data?</h3>
          <p>
            Galilei's duties regarding the Processing of Data include ensuring compliance with the following
            obligations:
          </p>
          <ul>
            <li>Guarantee, at all times, the full and effective exercise of your rights mentioned in this Policy.</li>
            <li>
              Keep the information under security conditions necessary to prevent its alteration, loss, unauthorized
              consultation, use, or fraudulent access.
            </li>
            <li>Update the information when necessary.</li>
            <li>Rectify your Data when appropriate.</li>
            <li>Treat Data classified as sensitive with due diligence and protection.</li>
          </ul>

          <h3>13. How do we respond to legal requests and comply with applicable law?</h3>
          <p>
            Notwithstanding Galilei's duties under this Policy, Galilei will access, preserve, and share your
            information:
          </p>
          <ul>
            <li>
              In response to lawful requests, such as court orders, subpoenas, or summonses from government authorities.
            </li>
            <li>If a law requires us to keep your information for any reason.</li>
            <li>To promote the safety and security of the Platform, Users, and the public.</li>
          </ul>

          <h4>Applicable Law and Jurisdiction</h4>
          <p>
            This Privacy Policy shall be governed by and construed according to the laws of the State of Delaware. Any
            dispute arising out of or relating to this Privacy Policy shall be subject to the exclusive jurisdiction of
            the courts located in the State of Delaware.
          </p>
          <p>
            The parties agree that any habeas data policy applicable to the User's Data shall be incorporated into this
            Privacy Policy and interpreted in accordance with its provisions.
          </p>

          <h4>Contact Us</h4>
          <p>
            Any questions or additional information requests will be received and processed via email at{' '}
            <a href="mailto:help@galileilearning.com">help@galileilearning.com</a>.
          </p>
        </div>
      </div>

      {player && !isPrivacyPolicyAccepted && (
        <div className="privacy-policy__footer">
          <div className="privacy-policy__checkbox">
            <input
              type="checkbox"
              id="readPolicy"
              checked={isPolicyRead}
              onChange={(e) => setIsPolicyRead(e.target.checked)}
            />
            <label htmlFor="readPolicy">I have read and understood the Privacy Policy</label>
          </div>
          <div className="privacy-policy__checkbox">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={isTermsAccepted}
              onChange={(e) => setIsTermsAccepted(e.target.checked)}
            />
            <label htmlFor="agreeTerms">I agree to the terms and conditions outlined in the Privacy Policy</label>
          </div>
          <button
            className={`privacy-policy__button ${!isFormValid || isLoading ? 'privacy-policy__button--disabled' : ''}`}
            disabled={!isFormValid || isLoading}
            onClick={handleAcceptTerms}
          >
            Accept
          </button>
        </div>
      )}

      {isPrivacyPolicyAccepted && !!player && (
        <div className="privacy-policy__footer">
          <button className="privacy-policy__button" onClick={() => (window.location.href = '/')}>
            Back
          </button>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
