import { Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../../components/Loading';
import './ExperienceQuestionForm.css';
import MessageAlert from '../../components/commons/MessageAlert.js';
import MultiLangTabContent from '../../components/commons/MultiLangTabContent.js';
import useExperienceForm from '../../hooks/useExperienceForm.js';
import useMultiLangTab from '../../hooks/useMultiLangTab.js';
import SelectImage from '../../components/commons/selectImage/SelectImage.js';
import useImageList from '../../hooks/useImageList.js';

const ExperienceForm = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { activeTab, toggle } = useMultiLangTab();
  const { experience, loading, message, handleSubmit, handleInputName, handleTypeChange, handleImage } =
    useExperienceForm(id);
  const { images, loading: loadingImages } = useImageList();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (loading) return <Loading />;

  return (
    <>
      <MessageAlert message={message} />
      <Card className="my-3">
        <CardHeader>
          <h3>{id ? 'Edit Content' : 'Add Content'}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-start">
              <Col md="6" className="d-flex flex-column align-items-center justify-content-center">
                <SelectImage
                  images={images}
                  disabled={loadingImages}
                  onSelect={handleImage}
                  selected={experience.image}
                  current={experience.media_id}
                />
              </Col>
              <Col md="6">
                <h5 className="mb-3 text-dark font-weight-bold">Name</h5>
                <MultiLangTabContent activeTab={activeTab} toggle={toggle}>
                  <Input
                    name="name"
                    placeholder="name"
                    type="text"
                    value={experience.name[activeTab]}
                    onChange={(e) => handleInputName(e.target.value, activeTab)}
                  />
                </MultiLangTabContent>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <Row className="align-items-center">
                  <Col md="4">
                    <Label className="text-dark font-weight-bold">Type</Label>
                  </Col>
                  <Col md="8">
                    <Input type="select" value={experience.type} onChange={(e) => handleTypeChange(e.target.value)}>
                      {['ONBOARDING', 'REGULAR'].map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <ReturnButton to="/experiences" />
                <Button color="success" type="submit" disabled={loading} className="float-end">
                  {t('buttons.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(ExperienceForm, {
  onRedirecting: () => <Loading />,
});
