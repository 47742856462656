import { useState, useCallback } from 'react';
import { patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

import { useCreateUserTrack } from './UserTrackAPI';
import { setActor, useActor } from '../storage/storage';
import { fetchData } from './axiosClient';
import { useDispatch } from 'react-redux';

const useUpdateAcceptTerms = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [datetime, _] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const actor = useActor();
  const { createUserTrack } = useCreateUserTrack();

  const acceptTerms = async (ip) => {
    setIsLoading(true);
    const url = `${BACKEND_URL}/actor/${actor.actor_id}/terms`;
    setError(null);
    const body = {
      datetime,
      ip,
    };
    try {
      await patchData(url, body, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while accepting terms.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while accepting terms.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setIsLoading(false);
      try {
        await createUserTrack({
          companyId: actor.company_id,
          actorId: actor.actor_id,
          event: 'ACCEPT_TERMS',
        });
      } catch (error) {
        console.error('Error tracking user navigation:', error);
      }
    }
  };

  return { error, isLoading, acceptTerms };
};

const useFetchAcceptedTerms = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(null);
  const actor = useActor();

  const fetchAcceptedTerms = useCallback(async () => {
    if (!actor?.actor_id) {
      setError('Invalid actor data.');
      return;
    }

    setLoading(true);
    setError(null);
    const url = `${BACKEND_URL}/actor/${actor.actor_id}/terms`;

    try {
      const data = await fetchData(url, getAccessTokenSilently);
      setTerms(data);
    } catch (error) {
      console.error('Error fetching accepted terms:', error);
      setError(error.response?.data?.message || 'Unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  }, [actor, getAccessTokenSilently]);

  return { error, loading, terms, fetchAcceptedTerms };
};

const useSwitchLanguage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const actor = useActor();
  const dispatch = useDispatch();

  const switchLanguage = async (language) => {
    try {
      setLoading(true);
      const res = await patchData(`${BACKEND_URL}/actor/${actor.actor_id}/${language}`, {}, getAccessTokenSilently);
      setActor(res, dispatch);
    } catch (error) {
      console.error('Unexpected error occurred while updating the actor language.', error);
      const errorMessage =
        error.response?.data?.message || 'Unexpected error occurred while updating the actor language.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, switchLanguage };
};

const useCanTrooper = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [canTroop, setCanTroop] = useState(false);

  const fetchCanTroop = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      const url = `${BACKEND_URL}/actor/${id}/trooper`;

      try {
        const data = await fetchData(url, getAccessTokenSilently);
        setCanTroop(data);
      } catch (error) {
        console.error('Error fetching accepted terms:', error);
        setError(error.response?.data?.message || 'Unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { error, loading, canTroop, fetchCanTroop };
};

export { useCanTrooper, useUpdateAcceptTerms, useFetchAcceptedTerms, useSwitchLanguage };
