import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledAlert } from 'reactstrap';

const MessageAlert = ({ message }) => {
  if (!message.isSet) return <></>;
  const icon =
    message.type === 'success' ? faCheckCircle : message.type === 'warning' ? faExclamationTriangle : faXmark;
  return (
    <UncontrolledAlert color={message.type}>
      <FontAwesomeIcon icon={icon} className="me-2" />
      {message.text}
    </UncontrolledAlert>
  );
};

export default MessageAlert;
