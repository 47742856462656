import { useEffect } from 'react';
import { usePlayer } from '../../storage/storage';
import { useAwardByPlayer } from '../../api/AwardAPI';

const useAwards = () => {
  const player = usePlayer();
  const { awards, loading: loadingAwards, fetchUnacceptedAwardsByPlayer } = useAwardByPlayer();

  useEffect(() => {
    if (!player?.player_id) return;
    fetchUnacceptedAwardsByPlayer(player.team_id, true);
  }, [player, fetchUnacceptedAwardsByPlayer]);

  return {
    awards,
    loadingAwards,
  };
};

export default useAwards;
