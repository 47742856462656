import React, { useEffect } from 'react';
import { useCreateUserTrack } from '../api/UserTrackAPI';

import home from '../assets/img/footer/home.svg';
import homeActive from '../assets/img/footer/home-active.svg';
import chest from '../assets/img/footer/chest.svg';
import chestActive from '../assets/img/footer/chest-active.svg';
import account from '../assets/img/footer/account.svg';
import accountActive from '../assets/img/footer/account-active.svg';
import galiPiece from '../assets/img/footer/galipiece.svg';
import galiPieceActive from '../assets/img/footer/galipiece-active.svg';

import '../assets/css/footer.css';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useActor } from '../storage/storage';

const Footer = ({ active }) => {
  const actor = useActor();
  const { t, i18n } = useTranslation();
  const { trackAndNavigate } = useCreateUserTrack();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const displayExperienceRelated = active !== 'Menu';
  const isPlayerProfile = active === 'PlayerProfile';
  const normalText = 'text-wrapper';
  const activeText = 'text-wrapper active';

  return (
    <footer>
      {displayExperienceRelated && !isPlayerProfile && (
        <div className="frame">
          <div>
            <img
              src={active === 'Game' ? homeActive : home}
              alt="game"
              onClick={() => trackAndNavigate('/client', 'HOME_CLICK')}
            />
            <div className={active === 'Game' ? activeText : normalText}>{t('game.footer.home')}</div>
          </div>
          <div>
            <img
              src={active === 'Chest' ? chestActive : chest}
              alt="chest"
              onClick={() => trackAndNavigate('/chest', 'CHEST_CLICK')}
            />
            <div className={active === 'Chest' ? activeText : normalText}>{t('game.footer.chest')}</div>
          </div>
          <div>
            <img
              src={active === 'JigSaw' ? galiPieceActive : galiPiece}
              alt="JigSaw"
              onClick={() => trackAndNavigate('/prize-catalog', 'REWARDS_CLICK')}
            />
            <div className={active === 'JigSaw' ? activeText : normalText}>{t('game.footer.rewards')}</div>
          </div>
          <div>
            <img
              src={active === 'GamePlayerProfile' ? accountActive : account}
              alt="profile"
              onClick={() => trackAndNavigate('/profile', 'PROFILE_CLICK')}
            />
            <div className={active === 'GamePlayerProfile' ? activeText : normalText}>{t('game.footer.account')}</div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
