import { useEffect } from 'react';
import { useUpdateInventoryContext } from '../../api/InventoryAPI';

const useInventory = () => {
  const { updateInventoryContext } = useUpdateInventoryContext();

  useEffect(() => {
    updateInventoryContext();
  }, []);
};

export default useInventory;
