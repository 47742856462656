import axios from 'axios';
import QueryString from 'qs';
import { getTimezone } from '../utils/date';

export const applyHeaders = async (getToken, contentType = 'application/json') => {
  const token = await getToken();
  return getHeader(token, contentType);
};

export const getHeader = (token, contentType) => {
  return {
    headers: {
      'X-TZ': getTimezone(),
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchData = async (url, getToken) => {
  try {
    const headers = await applyHeaders(getToken);
    const response = await axios.get(url, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDataWithQueryArray = async (url, inputParams, getToken) => {
  const headers = await applyHeaders(getToken);
  const params = {
    ...headers,
    params: inputParams,
    paramsSerializer: (params) => {
      return QueryString.stringify(params);
    },
  };
  try {
    const response = await axios.get(url, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postData = async (url, data, getToken, contentType = 'application/json') => {
  try {
    const headers = await applyHeaders(getToken, contentType);
    const response = await axios.post(url, data, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const patchData = async (url, data, getToken, contentType = 'application/json') => {
  try {
    const headers = await applyHeaders(getToken, contentType);
    const response = await axios.patch(url, data, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const putData = async (url, data, getToken, contentType = 'application/json') => {
  try {
    const headers = await applyHeaders(getToken, contentType);
    const response = await axios.put(url, data, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteData = async (url, getToken, contentType = 'application/json') => {
  try {
    const headers = await applyHeaders(getToken, contentType);
    const response = await axios.delete(url, headers);
    return response.data;
  } catch (error) {
    throw error;
  }
};
