import Loading from '../../../components/Loading.js';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Alert,
  Label,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton.js';
import { LEVEL_OPTIONS, PIECE_OPTIONS } from '../../../utils/constants.js';
import '../../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import usePrizeForm from '../../../backOffice/hooks/usePrizeForm.js';
import { EN, SUPPORTED_LANGUAGES, useLangHandler } from '../../../api/LanguageAPI';
import useImageList from '../../hooks/useImageList.js';
import SelectImage from '../../components/commons/selectImage/SelectImage.js';

const PrizeForm = () => {
  const { id } = useParams();
  const {
    prize,
    error,
    loading,
    redirect,
    handlePrizeInput,
    handleSubmit,
    handleChecked,
    handleImage,
    handleImageCover,
  } = usePrizeForm(id);
  const { t, i18n } = useTranslation();
  const { T } = useLangHandler();
  const { images, loading: loadingImages } = useImageList();
  const [activeTab, setActiveTab] = useState(EN);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (redirect) return <Navigate to="/prize" />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{id ? t('catalogue.edit') : t('catalogue.add')}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row sm={2}>
              <Col md="6" className="d-flex flex-column align-items-center justify-content-center">
                <SelectImage
                  customTitle="Preview Image"
                  images={images}
                  disabled={loadingImages}
                  onSelect={handleImage}
                  selected={prize.url}
                  current={prize.media_id}
                />
                <SelectImage
                  customTitle="Cover Image"
                  images={images}
                  disabled={loadingImages}
                  onSelect={handleImageCover}
                  selected={prize.coverUrl}
                  current={prize.cover_media_id}
                />
              </Col>
              <Col>
                <Nav tabs>
                  {SUPPORTED_LANGUAGES.map((lang) => (
                    <NavItem key={lang}>
                      <NavLink href="#" onClick={() => toggle(lang)} active={activeTab === lang}>
                        {t(`content.language.${lang}`)}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab} className="p-3 border border-top-0">
                  <TabPane tabId={activeTab}>
                    <FormGroup floating>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        value={T(prize?.name, activeTab) || ''}
                        onChange={(e) => handlePrizeInput(e, activeTab)}
                      />
                      <Label className="admin-text" for="name">
                        {t('form.name')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <Input
                        id="level"
                        name="level"
                        type="select"
                        defaultValue={-1}
                        value={prize?.level}
                        onChange={handlePrizeInput}
                      >
                        {LEVEL_OPTIONS.map((o) => (
                          <option key={o.value} value={o.value}>
                            {t(o.label)}
                          </option>
                        ))}
                      </Input>
                      <Label className="admin-text" for="level">
                        {t('catalogue.level')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <Input
                        id="pieces"
                        name="total_pieces"
                        placeholder={t('catalogue.pieces')}
                        type="select"
                        value={prize?.total_pieces}
                        onChange={handlePrizeInput}
                      >
                        {PIECE_OPTIONS.map((o) => (
                          <option key={o.value} value={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Input>
                      <Label className="admin-text" for="pieces">
                        {t('catalogue.pieces')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <Input
                        id="description"
                        name="description"
                        type="textarea"
                        value={T(prize?.description, activeTab) || ''}
                        onChange={(e) => handlePrizeInput(e, activeTab)}
                        style={{
                          height: '8rem',
                          resize: 'none',
                        }}
                      />
                      <Label className="admin-text" for="description">
                        {t('form.desc')}
                      </Label>
                    </FormGroup>
                    <FormGroup floating>
                      <Input
                        id="stock"
                        name="stock"
                        type="number"
                        value={prize?.stock || ''}
                        onChange={handlePrizeInput}
                      />
                      <Label className="admin-text" for="stock">
                        {t('catalogue.stock')}
                      </Label>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="admin-text" for="is_premium">
                        <Input
                          id="is_premium"
                          name="is_premium"
                          type="checkbox"
                          checked={prize.is_premium}
                          onChange={handleChecked}
                        />
                        &nbsp;Premium
                      </Label>
                    </FormGroup>
                    <FormGroup className="my-2">
                      <ReturnButton to={'/prize'} />
                      <Button color="primary" type="submit" disabled={loading}>
                        {t('buttons.ok')}
                      </Button>
                    </FormGroup>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(PrizeForm, {
  onRedirecting: () => <Loading />,
});
