import React, { useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import './SelectImageModal.css';

const SelectImageModal = ({ images, isOpen, toggle, onSelect, current }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredImages = useMemo(() => {
    return images.filter((img) => img.upload_name?.toLowerCase().includes(searchQuery.toLowerCase())) || [];
  }, [images, searchQuery]);

  const handleImageSelect = (image) => {
    onSelect(image);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className="text-dark">
        Select an image
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          placeholder="Search by name..."
          className="select-image__search text-dark"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {filteredImages.length === 0 ? (
          <p className="select-image__empty text-dark text-center">No images available.</p>
        ) : (
          <div className="select-image__grid">
            {filteredImages.map((img) => (
              <div key={img.media_id}>
                <div
                  className={`select-image__item ${current === img.media_id ? 'select-image__item--selected' : ''}`}
                  onClick={() => handleImageSelect(img)}
                >
                  <div className="select-image__thumbnail">
                    <img src={img.url} alt={img.upload_name} className="select-image__preview" />
                  </div>
                </div>
                <p className="select-image__name text-dark">{img.upload_name}</p>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectImageModal;
