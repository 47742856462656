import React from 'react';
import { useEffect, useState, useTransition } from 'react';
import { Alert, ButtonGroup, Card, CardBody, CardHeader, Button, Table, FormGroup, Input } from 'reactstrap';
import Loading from '../../../components/Loading';
import { Navigate } from 'react-router-dom';
import { applyHeaders } from '../../../api/axiosClient';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { BACKEND_URL } from '../../../utils/constants';
import ReturnButton from '../../components/commons/ReturnButton';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/piece-config.css';
import PieceContent from './PieceContent';

const PieceConfig = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [pieceConfigList, setPieceConfigList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getPieceConfig = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/config/pieces`, headers)
        .then((response) => setPieceConfigList(response.data.data))
        .catch((e) => setFetchError(e.response.data.message));
    };
    getPieceConfig();
  }, [getAccessTokenSilently, setFetchError]);

  const handleInputCatalog = (config, index, event) => {
    setState({ ...state, error: null });
    const newConfigData = structuredClone(pieceConfigList);
    newConfigData[index] = { ...config, [event.target.name]: event.target.value };
    setPieceConfigList(newConfigData);
  };

  const handleInputPieces = (config, index, event) => {
    setState({ ...state, error: null });
    const newConfigData = structuredClone(pieceConfigList);
    event.target.value = event.target.value.replace(',', '.');
    newConfigData[index].piece_probability[config.length][event.target.id] = !event.target.value
      ? 0
      : parseFloat(event.target.value);
    setPieceConfigList(newConfigData);
  };

  const submit = async (event) => {
    event.preventDefault();
    setState({ ...state, error: null });
    setLoading(true);

    const headers = await applyHeaders(getAccessTokenSilently);
    await axios
      .patch(`${BACKEND_URL}/config/pieces`, pieceConfigList, headers)
      .then((r) => setState({ ...state, error: null }))
      .catch((e) => {
        console.error(e);
        setState({
          ...state,
          error: e.response.data.message,
        });
      })
      .finally(() => setLoading(false));
  };

  if (state.redirect) return <Navigate to="/landing" />;
  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      {fetchError && <Alert color="danger">{fetchError}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.piece')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <Button disabled={loading} className="btn-primary" onClick={submit}>
              {t('buttons.ok')}
            </Button>
          </ButtonGroup>
          <FormGroup row>{!pieceConfigList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {pieceConfigList && (
            <PieceContent
              pieceConfigList={pieceConfigList}
              handleInputCatalog={handleInputCatalog}
              handleInputPieces={handleInputPieces}
              t={t}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(PieceConfig, {
  onRedirecting: () => <Loading />,
});
