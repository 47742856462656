const ImageInTable = ({ url, alt }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <img
        className="img-fluid"
        src={url}
        alt={alt}
        style={{ maxWidth: '100px', height: '100px', objectFit: 'cover' }}
      />
    </div>
  );
};
export default ImageInTable;
