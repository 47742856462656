import { useCallback, useEffect, useState } from 'react';
import { useFetchPlayersByTeam, useFetchTeamsByCompany } from '../../api/TeamAPI';
import { DECIMAL_REGEXP } from '../../utils/constants';
import { useActor } from '../../storage/storage';
import { useCreateAward } from '../../api/AwardAPI';
import useMessageAlert from './useMessageAlert';

const useAwardForm = () => {
  const actor = useActor();
  const { message, showError, showSuccess, cleanMessage } = useMessageAlert();
  const [loading, setLoading] = useState(false);
  const { players, loading: loadingPlayers, error, fetchPlayersByTeam } = useFetchPlayersByTeam();
  const { teams, loading: loadingTeams, error: errorTeam, fetchTeamsByCompany } = useFetchTeamsByCompany();
  const { loading: loadingCreate, error: errorCreate, createAward } = useCreateAward();
  const [teamId, setTeamId] = useState('');
  const [formError, setFormError] = useState(null);
  const [award, setAward] = useState({
    playerId: '',
    pieces: 0,
    tickets: 0,
    reason: { en: '', es: '' },
  });

  const handleChangeTeam = useCallback(
    async (teamId) => {
      cleanMessage();
      await fetchPlayersByTeam(teamId);
      setTeamId(teamId);
      if (error) {
        setTeamId('');
        showError('Error fetching players');
      }
    },
    [error, loadingPlayers, setLoading, fetchPlayersByTeam, setTeamId]
  );

  const handleChangePlayer = useCallback(
    (playerId) => {
      if (!playerId) return;
      setAward((prev) => ({
        ...prev,
        playerId,
      }));
    },
    [setAward]
  );

  useEffect(() => {
    if (!actor?.company_id) return;
    fetchTeamsByCompany(actor.company_id);
  }, [fetchTeamsByCompany, actor.company_id]);

  useEffect(() => {
    setLoading(loadingPlayers || loadingTeams || loadingCreate);
  }, [loadingPlayers, loadingTeams, loadingCreate]);

  useEffect(() => {
    if (!error && !errorTeam && !errorCreate) return;
    showError(error || errorTeam || errorCreate);
  }, [error, errorTeam, errorCreate, showError]);

  const handleInputChangeReason = useCallback(
    (e, lang) => {
      const { value } = e.target;
      setAward((prev) => ({
        ...prev,
        reason: {
          ...prev.reason,
          [lang]: value,
        },
      }));
    },
    [setAward]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (!DECIMAL_REGEXP.test(value)) {
        e.preventDefault();
        return;
      }
      setAward((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
    },
    [setAward]
  );

  const clean = useCallback(() => {
    setFormError(null);
    cleanMessage();
  }, [cleanMessage, setFormError]);

  const validateAward = useCallback(() => {
    if (!award.playerId) {
      setFormError('Select a player');
      return false;
    }
    if (award.tickets <= 0 && award.pieces <= 0) {
      setFormError('Tickets and Pieces must be greater than 0');
      return false;
    }
    if (!award.reason.es) {
      setAward((prev) => ({
        ...prev,
        reason: { ...prev.reason, es: null },
      }));
    }
    if (!award.reason.en) {
      setAward((prev) => ({
        ...prev,
        reason: { ...prev.reason, en: null },
      }));
    }
    return true;
  }, [award, setFormError]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      clean();
      if (!validateAward()) {
        showError(formError);
        return;
      }
      setLoading(true);
      await createAward(award);
      if (!errorCreate) showSuccess('Award created successfully');
      setLoading(false);
    },
    [award, errorCreate, setLoading, clean, validateAward, createAward]
  );

  return {
    teamId,
    teams,
    award,
    message,
    loading,
    players,
    handleSubmit,
    handleChangeTeam,
    handleChangePlayer,
    handleInputChange,
    handleInputChangeReason,
  };
};

export default useAwardForm;
