import { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Table, FormGroup, Alert, Button, ButtonGroup } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { BACKEND_URL } from '../../../utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../../components/Loading';
import ReturnButton from '../../components/commons/ReturnButton';
import { applyHeaders } from '../../../api/axiosClient';
import { useActor } from '../../../storage/storage';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const CompanyList = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [companyList, setCompanyList] = useState();
  const [state, setState] = useState({
    error: null,
    redirect: false,
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const makeSupremeLeader = async (companyId) => {
    const headers = await applyHeaders(getAccessTokenSilently);
    const url = `${BACKEND_URL}/trooper/${actor.actor_id}/company/${companyId}`;

    await axios
      .patch(url, { companyId: companyId }, headers)
      .then(() => {
        sessionStorage.removeItem('actor');
        window.location.reload();
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    const getCompanyList = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/company`, headers)
        .then((response) => setCompanyList(response.data.data))
        .catch((error) => {
          if (error.response.status === 404) {
            setCompanyList(null);
            return;
          }
          if (error.response.status === 401) {
            setState({
              ...state,
              redirect: true,
            });
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getCompanyList();
  }, [getAccessTokenSilently, actor, setState, state]);

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  if (state.redirect) return <Navigate to="/landing" />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.companies')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            <NavLink className="btn btn-primary" to="new">
              {t('buttons.add')}
            </NavLink>
          </ButtonGroup>
          <FormGroup row>{!companyList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {companyList && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('tables.name')}</th>
                  <th>{t('tables.actions')}</th>
                  <th>{t('company.supreme')}</th>
                </tr>
              </thead>
              <tbody>
                {companyList
                  .filter((company) => !company.name.toLowerCase().includes('admin'))
                  .map((company) => (
                    <tr key={company.name}>
                      <td>{company.name}</td>
                      <td>
                        <Button
                          color="link"
                          title="Invite"
                          size="sm"
                          onClick={() => navigate(`${company.company_id}/new-members`)}
                        >
                          <FontAwesomeIcon icon={faUserPlus} className="faWhite" />
                        </Button>
                        <Button
                          color="link"
                          size="sm"
                          className="view-icon"
                          onClick={() => navigate(`${company.company_id}`)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>
                      </td>
                      <td>
                        <Button className="btn btn-primary" onClick={() => makeSupremeLeader(company.company_id)}>
                          {t('buttons.go')}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(CompanyList, {
  onRedirecting: () => <Loading />,
});
