import { Button, Table } from 'reactstrap';
import ImageInTable from './ImageInTable';
import EmptyRow from './EmptyRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ChangeImageNameModal from '../../pages/images/ChangeImageNameModal';
import useImageChangeName from '../../hooks/useImageChangeName';
import MessageAlert from './MessageAlert';

const ImagesTable = ({ images }) => {
  const { message, toEdit, setToEdit, handleSubmit, handleNameChange } = useImageChangeName();
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);
  const showModal = (image) => {
    setToEdit({ id: image.media_id, name: image.upload_name });
    toggle();
  };
  return (
    <>
      {message.isSet && <MessageAlert message={message} />}
      <Table hover borderless responsive size="sm" striped className="text-center">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {images.map((image) => (
            <tr key={image.media_id}>
              <td>
                <ImageInTable url={image.url} alt={image.upload_name} />
              </td>
              <td>{image.upload_name}</td>
              <td>
                <Button color="link" size="sm" onClick={() => showModal(image)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </td>
            </tr>
          ))}
          {images.length === 0 && <EmptyRow colSpan={3} message={'No images yet'} />}
        </tbody>
      </Table>
      <ChangeImageNameModal
        name={toEdit.name}
        onNameChange={handleNameChange}
        onSave={handleSubmit}
        isOpen={isOpen}
        toggle={toggle}
      />
    </>
  );
};
export default ImagesTable;
