import { Button, Table } from 'reactstrap';
import ImageInTable from './ImageInTable';
import EmptyRow from './EmptyRow';
import { filesize } from 'filesize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const ImagesUploadTable = ({ images, errors, remove, uploads, processing }) => {
  return (
    <Table hover borderless responsive size="sm" striped className="text-center">
      <thead>
        <tr>
          <th>Preview</th>
          <th>Name</th>
          <th>Type</th>
          <th>Size</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {images.map((image, index) => (
          <tr key={image.name}>
            <td>
              <ImageInTable url={URL.createObjectURL(image)} alt={image.name} />
            </td>
            <td>{image.name}</td>
            <td>{image.type}</td>
            <td>{filesize(image.size)}</td>
            <td>{errors[index] || uploads[index]}</td>
            <td>
              {!processing && (
                <Button className="button-delete btn-danger" onClick={() => remove(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </td>
          </tr>
        ))}
        {images.length === 0 && <EmptyRow colSpan={6} message="No images to upload yet" />}
      </tbody>
    </Table>
  );
};
export default ImagesUploadTable;
