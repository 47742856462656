import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Card, CardBody, CardImg, Col, Input } from 'reactstrap';
import Loading from '../../../components/Loading';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { BACKEND_URL } from '../../../utils/constants';
import { applyHeaders } from '../../../api/axiosClient';
import ReturnButton from '../../components/commons/ReturnButton';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useActor } from '../../../storage/storage';

const CompanyDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [company, setCompany] = useState({
    name: null,
    maxPlayers: 5,
    maxLeaders: 3,
  });
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    const getCompany = async () => {
      if (!id) return;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/company/${id}`, headers)
        .then((response) => setCompany(response.data))
        .catch((e) => setFetchError(e.response.data.message));
    };
    getCompany();
  }, [getAccessTokenSilently, id, setFetchError]);

  const handleInput = async (event) => {
    setState({ ...state, error: null });
    setCompany({
      ...company,
      [event.target.name]: event.target.value,
    });
  };

  const submit = async (event) => {
    event.preventDefault();
    setState({ ...state, error: null });

    if (!company.name) {
      setState({
        ...state,
        error: 'Invalid name',
      });
      return;
    }
    const leaders = parseInt(company.maxLeaders);
    const players = parseInt(company.maxPlayers);
    if (!leaders || isNaN(leaders) || !players || isNaN(players) || players < leaders || leaders < 0 || players < 0) {
      setState({
        ...state,
        error: 'Invalid Players or Leaders',
      });
      return;
    }
    setLoading(true);

    const newCompany = {
      name: company.name,
      maxLeaders: leaders,
      maxPlayers: players,
      actorId: actor.actor_id,
    };

    const headers = await applyHeaders(getAccessTokenSilently);
    const url = `${BACKEND_URL}/company/${id || ''}`;
    const operation = id ? axios.patch(url, newCompany, headers) : axios.post(url, newCompany, headers);
    await operation
      .then(() => navigate('/companies'))
      .catch((e) =>
        setState({
          ...state,
          error: e.response.data.message,
        })
      )
      .finally(() => setLoading(false));
  };

  if (state.redirect) return <Navigate to="/companies" />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      {fetchError && <Alert color="danger">{fetchError}</Alert>}
      <h2 className="admin-text">Company</h2>
      <Form onSubmit={submit}>
        <FormGroup row>
          <Label className="admin-text" for="name" sm={2}>
            {t('form.name')}
          </Label>
          <Col sm={6}>
            <Input
              id="name"
              name="name"
              placeholder={t('form.name')}
              type="text"
              value={company && company.name}
              onChange={handleInput}
              valid={!!company.name}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="file" sm={2}>
            {t('form.logo')}
          </Label>
          <Col sm={6}>
            <Card>
              <CardBody>
                {company && (
                  <CardImg
                    id="image"
                    alt="Logo"
                    src={company.url}
                    style={{
                      width: '24rem',
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="maxLeaders" sm={2}>
            {t('company.leaders')}
          </Label>
          <Col sm={6}>
            <Input
              id="maxLeaders"
              name="maxLeaders"
              placeholder={t('company.leaders')}
              type="number"
              value={company && company.maxLeaders}
              min={0}
              defaultValue={3}
              onChange={handleInput}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="maxPlayers" sm={2}>
            {t('company.players')}
          </Label>
          <Col sm={6}>
            <Input
              id="maxPlayers"
              name="maxPlayers"
              placeholder={t('company.players')}
              type="number"
              value={company && company.maxPlayers}
              onChange={handleInput}
              defaultValue={5}
              min={0}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/companies'} />
            <Button color="primary" type="submit" disabled={loading}>
              {t('buttons.ok')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(CompanyDetail, {
  onRedirecting: () => <Loading />,
});
