import React, { useEffect, useState } from 'react';

import nextChest from '../../assets/img/countdown/next-chest.svg';
import openedChest from '../../assets/img/chest/chest-opened-home.svg';

import '../../assets/css/chest.css';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useActor } from '../../storage/storage';

const NextChestCountdown = ({ worker, startedAt, isHome, autoRestart }) => {
  const [countdown, setCountdown] = useState(null);
  const actor = useActor();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  useEffect(() => {
    if (!worker) return;

    const starts = new Date(startedAt);
    const ends = new Date(startedAt);
    ends.setSeconds(0);
    ends.setMinutes(0);
    ends.setHours(0);
    ends.setDate(ends.getDate() + 1);

    worker.postMessage({
      startedAt: startedAt,
      durationInSeconds: (ends.getTime() - starts.getTime()) / 1000,
      autoRestart: autoRestart,
    });
    worker.onmessage = async function (event) {
      setCountdown(event.data);
    };
  }, [setCountdown]);

  if (isHome) {
    return (
      <div className="home-chest-countdown">
        <div className="home-chest-available-right">
          <span className="last">{t('game.chest.tab.next')}</span>
          {countdown ? <span className="first">{countdown.remainingTime}</span> : <span>--:--:--</span>}
        </div>
        <div className="home-chest-available-left">
          <img src={openedChest} alt="Next Chest " />
        </div>
      </div>
    );
  }

  return (
    <div className="chest-countdown-container">
      <div className="chest-countdown">
        <div className="chest-countdown-legend">
          <span>{t('game.chest.tab.next')}</span>
        </div>
        <div className="chest-countdown-clock">
          {countdown ? <span>{countdown.remainingTime}</span> : <span>--:--:--</span>}
        </div>
      </div>
      <img src={nextChest} className="img-countdown" alt="nextChest" />
    </div>
  );
};

export default NextChestCountdown;
