import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input } from 'reactstrap';
import Loading from '../../../components/Loading';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/commons/ReturnButton';
import { useActor } from '../../../storage/storage';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useCreateTeam } from '../../../api/TeamAPI';

const NewTeam = () => {
  const { error: createTeamError, createTeam } = useCreateTeam();
  const actor = useActor();
  const [formData, setFormData] = useState({
    name: '',
    actor: actor.actor_id,
    companyId: actor.company_id,
  });
  const [formError, setFormError] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevTeam) => ({
      ...prevTeam,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = validateFormData();
    if (error) {
      setFormError(error);
      return;
    }
    setFormData(null);
    try {
      await createTeam(formData);
      setRedirect(true);
    } catch (error) {
      setFormError('Error submitting the form');
    }
  };

  const validateFormData = () => {
    if (!formData.name) return 'The name is required';
    if (formData.name.length > 50) return 'Name must be less than 100 characters';
    return null;
  };

  if (redirect) return <Navigate to="/teams" />;

  const messageError = formError || createTeamError;

  return (
    <>
      {messageError && <Alert color="danger">{messageError}</Alert>}
      <h2 className="admin-text">{t('menu.myTeam')}</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label className="admin-text" for="name" sm={2}>
            {t('form.name')}
          </Label>
          <Col sm={6}>
            <Input id="name" name="name" placeholder={t('form.name')} type="text" onChange={handleInputChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/teams'} />
            <Button color="primary" type="submit">
              {t('buttons.add')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(NewTeam, {
  onRedirecting: () => <Loading />,
});
