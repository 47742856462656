import { formatDateTime } from '../../../utils/date';
import { useLangHandler } from '../../../api/LanguageAPI';
import PaginatedTable from './PaginatedTable';
import PrizeLevelBadge from './PrizeLevelBadge';

const RewardsTable = ({ rewards, pageInfo, setToMark }) => {
  const { T } = useLangHandler();

  const rewardsColumnDef = [
    { field: 'created_at', headerName: 'Obtained at', renderCell: (params) => formatDateTime(params.value) },
    { field: 'prizename', headerName: 'Prize', valueGetter: (value) => T(value) },
    {
      field: 'level',
      headerName: 'Level',
      renderCell: (params) => <PrizeLevelBadge level={params.value} />,
    },
    { field: 'teamname', headerName: 'Team' },
    { field: 'full_name', headerName: 'Player' },
    { field: 'phone_number', headerName: 'Phone Number' },
  ];

  return <PaginatedTable columnDef={rewardsColumnDef} data={rewards} pageInfo={pageInfo} setToMark={setToMark} />;
};
export default RewardsTable;
