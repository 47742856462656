import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

const MessageTypeSelect = ({ messageType, onChange }) => {
  const types = [
    { name: 'Money Away', key: 'money-away-batch' },
    { name: 'Daily Reminder', key: 'daily-reminder-batch' },
  ];
  return (
    <FormGroup floating>
      <Input id="messageType" name="type" type="select" value={messageType} onChange={onChange}>
        <option />
        {types.map((type) => (
          <option key={type.key} value={type.key}>
            {type.name}
          </option>
        ))}
      </Input>
      <Label className="admin-text" for="messageType">
        Message Type
      </Label>
    </FormGroup>
  );
};

export default MessageTypeSelect;
