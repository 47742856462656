import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../../components/Loading';
import useEditQuestion from '../../hooks/useQuestionForm';
import { QUESTION_DIFFICULTY, QUESTION_PRIORITY_TEXT } from '../../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SUPPORTED_LANGUAGES, EN, useLangHandler } from '../../../api/LanguageAPI';
import './ExperienceQuestionForm.css';
import useImageList from '../../hooks/useImageList.js';
import SelectImage from '../../components/commons/selectImage/SelectImage.js';
import AddButton from '../../components/commons/AddButton.js';

const MAX_OPTIONS = 4;
const MIN_OPTIONS = 2;

const ExperienceQuestionForm = () => {
  const { t, i18n } = useTranslation();
  const { T } = useLangHandler();
  const [activeTab, setActiveTab] = useState(EN);
  const { id, questionId } = useParams();
  const location = useLocation();
  const duplicatedQuestion = location.state?.question || null;
  const [message, setMessage] = useState({ type: null, text: null });
  const {
    question,
    error,
    loading,
    handleSaveAndAdd,
    handleQuestionInput,
    handleAnswerInput,
    handleCorrectAnswerInput,
    handlePriorityChange,
    handleDifficultyChange,
    handleAddQuestionOptions,
    handleRemoveQuestionOptions,
    handleSubmit,
    handleImageUpdate,
    handleRemoveImage,
  } = useEditQuestion(questionId, id, duplicatedQuestion);
  const { images, loading: loadingImages } = useImageList();
  const navigate = useNavigate();
  const options = question?.options || [];
  const hasEnoughOptions = options.length >= MIN_OPTIONS;
  const allOptionsHaveText = options.every((option) => {
    const textValue = option?.text?.[activeTab];

    return typeof textValue === 'string' ? textValue.trim().length > 0 : typeof textValue === 'number';
  });
  const hasReachedMaxOptions = options.length >= MAX_OPTIONS;
  const hasValidAnswer = question?.validAnswerOptionId !== null;
  const isSaveDisabled = !(hasEnoughOptions && allOptionsHaveText && hasValidAnswer);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (error) {
      setMessage({ type: 'danger', text: error });
    }
  }, [error]);

  const toggleTab = (tab) => (activeTab !== tab ? setActiveTab(tab) : null);

  const handleAddOption = () => {
    if (hasReachedMaxOptions) {
      setMessage({ type: 'warning', text: t('content.maxOptionsReached') });
      return;
    }
    handleAddQuestionOptions();
  };

  const handleFormSubmit = async (event) => {
    if (event?.preventDefault) event.preventDefault();
    setMessage({ type: null, text: null });
    try {
      await handleSubmit();
      if (questionId === 'new' && question?.id) {
        navigate(`/experiences/${id}/question/${question.id}`);
      }
      setMessage({ type: 'success', text: t('content.questionSavedSuccessfully') });
    } catch (_error) {
      console.error('Error updating the question:', _error);
      setMessage({ type: 'danger', text: _error.message || t('content.questionSaveFailed') });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      {message.text && (
        <Alert color={message.type}>
          <FontAwesomeIcon
            icon={
              message.type === 'success' ? faCheckCircle : message.type === 'warning' ? faExclamationTriangle : null
            }
            className="me-2"
          />
          {message.text}
        </Alert>
      )}
      <Card className="my-3">
        <Form onSubmit={handleFormSubmit}>
          <CardHeader>
            <h3>
              {t('content.question')}
              <AddButton addClassName="float-end" disabled={loading || isSaveDisabled} action={handleSaveAndAdd} />
              <Button color="primary" type="submit" disabled={loading || isSaveDisabled} className="float-end">
                Save
              </Button>
              <ReturnButton className="float-end" to={`/experiences/${id}/questions`} />
            </h3>
          </CardHeader>
          {question && (
            <CardBody>
              <Row className="align-items-start">
                <Col md="6" className="d-flex flex-column align-items-center justify-content-center">
                  <SelectImage
                    images={images}
                    disabled={loadingImages}
                    selected={question.imageUrl}
                    current={question.mediaId}
                    onSelect={handleImageUpdate}
                    onRemove={handleRemoveImage}
                  />
                </Col>
                <Col md="6">
                  <h5 className="mb-3 text-dark font-weight-bold">{t('content.questionText')}</h5>
                  <Nav tabs>
                    {SUPPORTED_LANGUAGES.map((lang) => (
                      <NavItem key={lang}>
                        <NavLink href="#" onClick={() => toggleTab(lang)} active={activeTab === lang}>
                          {t(`content.language.${lang}`)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 border border-top-0">
                    <TabPane tabId={activeTab}>
                      <Input
                        type="textarea"
                        value={T(question.text, activeTab) || ''}
                        onChange={(event) => handleQuestionInput(event, activeTab)}
                        className="question-textarea"
                      />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="6">
                  <h5 className="mb-3 text-dark font-weight-bold">{t('content.settings')}</h5>
                  <Row className="align-items-center">
                    <Col md="4">
                      <Label className="text-dark font-weight-bold">{t('content.priority.name')}</Label>
                    </Col>
                    <Col md="8">
                      <Input
                        type="select"
                        value={QUESTION_PRIORITY_TEXT[question?.priority] || ''}
                        onChange={handlePriorityChange}
                      >
                        <option />
                        {QUESTION_PRIORITY_TEXT.map((o) => (
                          <option key={o} value={o}>
                            {t(o)}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-2">
                    <Col md="4">
                      <Label className="text-dark font-weight-bold">{t('content.difficulty.name')}</Label>
                    </Col>
                    <Col md="8">
                      <Input
                        type="select"
                        value={QUESTION_DIFFICULTY[question?.difficulty] || ''}
                        onChange={handleDifficultyChange}
                      >
                        <option />
                        {QUESTION_DIFFICULTY.map((o) => (
                          <option key={o} value={o}>
                            {t(o)}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <h5 className="mb-3 text-dark font-weight-bold">{t('content.options')}</h5>
                  {options.map((option) => (
                    <Row key={option.id} className="align-items-center my-2">
                      <Col>
                        <Label className="option-container w-100">
                          <Input
                            value={T(option.text, activeTab) || ''}
                            onChange={(event) => handleAnswerInput(event, option.id, activeTab)}
                          />
                          <Button
                            className="button-delete btn-danger"
                            disabled={loading}
                            onClick={() => handleRemoveQuestionOptions(option.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                          <Input
                            type="radio"
                            className="custom-checkbox"
                            value={option.id}
                            checked={option.id === question?.validAnswerOptionId}
                            onChange={handleCorrectAnswerInput}
                          />
                        </Label>
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
              <Button
                color="primary"
                disabled={loading || hasReachedMaxOptions}
                onClick={handleAddOption}
                className="mt-3 mb-4"
              >
                {t('content.addOption')}
              </Button>
              <Row className="mt-4">
                <Col></Col>
              </Row>
            </CardBody>
          )}
        </Form>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(ExperienceQuestionForm, {
  onRedirecting: () => <Loading />,
});
