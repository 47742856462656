import { useEffect } from 'react';
import { useImagesByCompany } from '../../api/ImageAPI';
import { useActor } from '../../storage/storage';
import useMessageAlert from './useMessageAlert';

const useImageList = () => {
  const { message, showError, cleanMessage } = useMessageAlert();
  const { loading, images, error, fetchImagesByCompany } = useImagesByCompany();
  const actor = useActor();

  useEffect(() => {
    if (!actor) return;
    cleanMessage();
    fetchImagesByCompany(actor.company_id);
  }, [fetchImagesByCompany, actor]);

  useEffect(() => {
    if (!error) return;
    showError(error);
  }, [error, showError]);
  return {
    message,
    loading,
    images,
  };
};

export default useImageList;
