import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    initQuestions: (state, action) => {
      state.questions = action.payload;
    },
  },
});

export const { initQuestions } = questionsSlice.actions;
export const selectQuestions = (state) => state.questions;
export default questionsSlice.reducer;
