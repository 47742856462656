import { useCallback, useState } from 'react';
import { validateImageFile } from '../../utils/componentUtil';
import { useUploadImage } from '../../api/ImageAPI';
import { useActor } from '../../storage/storage';

const useImagesForm = () => {
  const [message, setMessage] = useState({ isSet: false, type: null, text: null });
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [progress, setProgress] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { uploadImage } = useUploadImage();
  const actor = useActor();

  const handleImages = useCallback(
    (event) => {
      for (const img of event.target.files) {
        const index = images.findIndex((image) => image.name === img.name);
        removeImage(index);
        setImages((prev) => [...prev, img]);
        setErrors((prev) => [...prev, validateImageFile(img)]);
      }
    },
    [images]
  );

  const removeImage = useCallback((index) => {
    setImages((prev) => prev.filter((image, i) => i !== index));
    setErrors((prev) => prev.filter((e, i) => i !== index));
  }, []);

  const changeUploads = useCallback(
    (index, status) => {
      setUploads((prev) => {
        const newUps = [...prev];
        newUps[index] = status;
        return newUps;
      });
    },
    [setUploads]
  );

  const processImage = useCallback(
    async (img, i) => {
      try {
        await uploadImage(actor.company_id, img);
        changeUploads(i, '✅');
      } catch (error) {
        console.error('Unexpected error occurred while uploading the image.', error);
        changeUploads(i, `❌ ${error.message}`);
      }
    },
    [actor.company_id, uploadImage, setUploads]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setProcessing(true);
      setMessage({ isSet: false, type: null, text: null });
      setLoading(true);
      if (errors.some((e) => e)) {
        setMessage({ isSet: true, type: 'danger', text: 'Some images have errors' });
        setLoading(false);
        return;
      }

      for (let i = 0; i < images.length; i++) {
        setUploads((prev) => [...prev, 'Uploading...']);
        await processImage(images[i], i);
        setProgress(i + 1);
      }
      setLoading(false);
    },
    [errors, images, setUploads, setProgress, setLoading, processImage]
  );

  return { message, errors, images, uploads, progress, processing, loading, handleSubmit, handleImages, removeImage };
};

export default useImagesForm;
