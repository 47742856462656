import { useState, useCallback } from 'react';
import { fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setPrizes } from '../storage/storage';

const useFetchPrizesByCompany = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPrizes = useCallback(
    async (id) => {
      try {
        setLoading(true);
        setError(null);
        const { data } = await fetchData(`${BACKEND_URL}/company/${id}/prize`, getAccessTokenSilently);
        setPrizes(data, dispatch);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the prizes list!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading, setPrizes, getAccessTokenSilently]
  );

  return { loading, error, fetchPrizes };
};

const useFetchPrizeById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getPrizeById = useCallback(
    async (prizeId) => {
      const url = `${BACKEND_URL}/prize/${prizeId}`;
      setLoading(true);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the prize by Id!', error);
        const errorMessage = error.response?.data?.message || 'There was an error getting the prize by Id!';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getPrizeById };
};

const useCreatePrize = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createPrize = async (prize) => {
    try {
      await postData(`${BACKEND_URL}/prize`, prize, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the prize.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the prize.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createPrize };
};

const useUpdatePrize = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const updatePrize = async (id, prize) => {
    try {
      await patchData(`${BACKEND_URL}/prize/${id}`, prize, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while updating the prize.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while updating the prize.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, updatePrize };
};

export { useFetchPrizesByCompany, useFetchPrizeById, useCreatePrize, useUpdatePrize };
