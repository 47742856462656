import { useEffect, useState, useCallback } from 'react';
import { fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchMetricList = (companyId) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = `${BACKEND_URL}/company/${companyId}/metrics`;

  useEffect(() => {
    const fetchMetricList = async () => {
      try {
        const { data } = await fetchData(url, getAccessTokenSilently);
        setData(data);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the Metric list!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetricList();
  }, [url, getAccessTokenSilently]);

  return { data, loading, error };
};

const useFetchMetricById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getMetricById = useCallback(
    async (metricId) => {
      const url = `${BACKEND_URL}/metrics/${metricId}`;
      setLoading(true);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the metric by Id!', error);
        const errorMessage = error.response?.data?.message || 'There was an error getting the metric by Id!';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getMetricById };
};

const useCreateMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createMetric = async (metric) => {
    try {
      await postData(`${BACKEND_URL}/metrics`, metric, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the metric.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the metric.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createMetric };
};

const useUpdateMetric = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const updateMetric = async (id, metric) => {
    try {
      await patchData(`${BACKEND_URL}/metrics/${id}`, metric, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while updating the metric.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while updating the metric.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, updateMetric };
};

export { useFetchMetricList, useFetchMetricById, useCreateMetric, useUpdateMetric };
