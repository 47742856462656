import useImageUrl from '../hooks/useImageUrl.js';

const ImageCarousel = ({ award }) => {
  const { url } = useImageUrl(award.mediaId);
  return (
    <>
      {!url && <img src={award.defaultImg} className="img-piece" alt="reward" />}
      {url && <img src={url} className="img-piece" alt="reward" />};
    </>
  );
};

export default ImageCarousel;
