import { useCallback, useState } from 'react';
import { fetchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { usePlayer } from '../storage/storage';

const useAwardByPlayer = () => {
  const player = usePlayer();
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [awards, setAwards] = useState([]);

  const fetchUnacceptedAwardsByPlayer = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await fetchData(`${BACKEND_URL}/player/${player.player_id}/awards`, getAccessTokenSilently);
      setError(null);
      setAwards(data);
    } catch (error) {
      if (error.status === 404) return;
      console.error('Error fetching awards.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching awards.';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);
  return { awards, error, loading, fetchUnacceptedAwardsByPlayer };
};

const useCreateAward = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const createAward = useCallback(
    async (award) => {
      try {
        setLoading(true);
        setError(null);
        await postData(`${BACKEND_URL}/awards`, award, getAccessTokenSilently);
      } catch (error) {
        console.error('Unexpected error occurred while creating the award.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the award.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { error, loading, createAward };
};

const useAwardsByCompany = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [awards, setAwards] = useState([]);

  const fetchAwardsByCompany = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const { data } = await fetchData(`${BACKEND_URL}/company/${id}/awards`, getAccessTokenSilently);
        setError(null);
        setAwards(data);
      } catch (error) {
        if (error.status === 404) return;
        console.error('Error fetching awards.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching awards.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { awards, error, loading, fetchAwardsByCompany };
};

export { useAwardByPlayer, useCreateAward, useAwardsByCompany };
