import React, { useEffect } from 'react';
import { LEVEL_OPTIONS } from '../../utils/constants';
import '../../assets/css/profile/metrics.css';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

import MetricLevel from './MetricLevel';
import { useActor } from '../../storage/storage';

const MetricLevels = ({ level }) => {
  const actor = useActor();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  return (
    <div className="metrics-container">
      {LEVEL_OPTIONS.map((element, idx) => (
        <MetricLevel key={idx} active={level === idx} text={t(element.label)} />
      ))}
    </div>
  );
};

export default MetricLevels;
