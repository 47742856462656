import { useCallback, useState } from 'react';
import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchGame = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchGame = useCallback(
    async (playerId) => {
      try {
        setLoading(true);
        setError(false);
        const data = await fetchData(`${BACKEND_URL}/game?playerId=${playerId}`, getAccessTokenSilently);
        setGame(data);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setError, setLoading, setGame, getAccessTokenSilently]
  );

  return { game, loading, error, fetchGame };
};

const useFetchQuestionsForGame = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const fetchQuestionsForGame = useCallback(
    async (experienceId, lang) => {
      try {
        setLoading(true);
        const { data } = await fetchData(
          `${BACKEND_URL}/experience/${experienceId}/questions-game?lang=${lang}`,
          getAccessTokenSilently
        );
        setError(null);
        setQuestions(data);
      } catch (error) {
        if (error.status === 404) return;
        console.error('Error fetching questions for game.');
        const errorMessage =
          error.response?.data?.message || 'Unexpected error occurred while fetching questions for game.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { questions, error, loading, fetchQuestionsForGame };
};

export { useFetchGame, useFetchQuestionsForGame };
