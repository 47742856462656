import { Badge, Button, Table } from 'reactstrap';

import { useExtendMembership, useSendNotification } from '../../../api/MembershipAPI';

import { isExpired } from '../../../utils/date';
import EmptyRow from './EmptyRow';

const MembersTable = ({ memberships, actionError, pending }) => {
  const { sendNotification, error, loading } = useSendNotification();
  const { extendMembership, error: errorExtending, loading: loadingExtending } = useExtendMembership();
  const title = (pending ? 'Pending' : 'Accepted') + ' memberships';

  const handleSendInvitation = async (membershipId) => {
    actionError(null);
    await sendNotification(membershipId);
    actionError(error);
  };

  const handleExtendInvitation = async (membershipId) => {
    actionError(null);
    await extendMembership(membershipId);
    actionError(errorExtending);
    if (!errorExtending) window.location.reload();
  };

  const getBadge = (membership) => {
    const text = membership.accepted ? 'Accepted' : isExpired(membership.expires_at) ? 'Expired' : 'Pending';
    const color = membership.accepted ? 'success' : isExpired(membership.expires_at) ? 'danger' : 'warning';
    return (
      <Badge color={color} pill>
        {text}
      </Badge>
    );
  };

  const getActionButton = (membership) => {
    const text = isExpired(membership.expires_at) ? 'Extend' : 'Send';
    const handler = isExpired(membership.expires_at) ? handleExtendInvitation : handleSendInvitation;
    const disabler = isExpired(membership.expires_at) ? loadingExtending : loading;
    return (
      <Button color="primary" disabled={disabler} onClick={() => handler(membership.membership_id)}>
        {text}
      </Button>
    );
  };

  return (
    <Table hover borderless responsive size="sm" striped className="text-center">
      <thead>
        <tr>
          <th colSpan="7">{title}</th>
        </tr>
        <tr>
          <th>Phone</th>
          <th>Team</th>
          <th>Lang</th>
          <th>Role</th>
          <th>Status</th>
          <th>Actions</th>
          <th>Notification</th>
        </tr>
      </thead>
      <tbody>
        {memberships &&
          memberships.map((membership) => (
            <tr key={membership?.membership_id}>
              <td>{membership?.phone_number}</td>
              <td>{membership?.team_name}</td>
              <td>{membership?.lang}</td>
              <td>{membership?.role}</td>
              <td>{getBadge(membership)}</td>
              <td>{getActionButton(membership)}</td>
              <td>
                <Badge>{membership.notification_state?.toUpperCase()}</Badge>
              </td>
            </tr>
          ))}
        {memberships.length === 0 && <EmptyRow colSpan={7} message={`No ${title} yet`} />}
      </tbody>
    </Table>
  );
};
export default MembersTable;
