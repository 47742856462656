import { useMemo, useRef, useState, useEffect } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';

const PaginatedTable = ({ columnDef, data, pageInfo, setToMark }) => {
  const PAGE_SIZE_OPTIONS = [10, 20, 30];
  const apiRef = useGridApiRef();
  const customAutosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    outliersFactor: 1,
    expand: true,
  };

  useEffect(() => {
    apiRef.current.autosizeColumns(customAutosizeOptions);
  }, [apiRef.current]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const rowCountRef = useRef(pageInfo?.totalRowCount || 0);
  const rowCount = useMemo(() => {
    if (pageInfo?.totalRowCount !== undefined) {
      rowCountRef.current = pageInfo.totalRowCount;
    }

    return rowCountRef.current;
  }, [pageInfo?.totalRowCount]);

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        apiRef={apiRef}
        rows={data}
        autosizeOnMount
        autosizeOptions={customAutosizeOptions}
        getRowId={(row) => row.reward_id}
        columns={columnDef}
        rowCount={rowCount}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        checkboxSelection
        onRowSelectionModelChange={setToMark}
        density="compact"
      />
    </div>
  );
};

export default PaginatedTable;
