import React, { useEffect, useState } from 'react';
import { Form, Button, FormGroup, Alert, Col } from 'reactstrap';
import Loading from '../../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ReturnButton from '../../components/commons/ReturnButton';
import useSendMessageOnDemandForm from '../../hooks/useMessageOnDemandForm';
import MessageTypeSelect from './MessageTypeSelect';
import TeamSelect from './TeamSelect';

const MessageOnDemand = () => {
  const { loading, error, teams, messageBody, handleClick, handleSelectChange } = useSendMessageOnDemandForm();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setIsDisabled(loading || !messageBody.type || !messageBody.teamId);
  }, [loading, messageBody]);

  if (loading) return <Loading />;
  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <h2 className="admin-text">Send Message On Demand</h2>
      <Form onSubmit={handleClick}>
        <FormGroup row>
          <MessageTypeSelect messageType={messageBody.type} onChange={handleSelectChange} />
          <TeamSelect selectedTeam={messageBody.teamId} onChange={handleSelectChange} teams={teams} />
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/landing'} />
            <Button color="primary" disabled={isDisabled} type="submit">
              Send
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(MessageOnDemand, { onRedirecting: () => <Loading /> });
