import { useEffect } from 'react';
import { useFetchPrizesByCompany } from '../../api/PrizesAPI';
import { useActor } from '../../storage/storage';

const usePrizes = () => {
  const actor = useActor();
  const { loading: loadingPrizes, fetchPrizes } = useFetchPrizesByCompany();

  useEffect(() => {
    if (!actor) return;
    fetchPrizes(actor.company_id);
  }, [actor, fetchPrizes]);

  return {
    loadingPrizes,
  };
};

export default usePrizes;
