import { useCallback, useState } from 'react';
import { fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchTeam = () => {
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchTeam = useCallback(
    async (id, isForGame = false) => {
      try {
        const gameParam = isForGame ? '?forGame=true' : '';
        const data = await fetchData(`${BACKEND_URL}/team/${id}${gameParam}`, getAccessTokenSilently);
        setTeam(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { team, loading, error, fetchTeam };
};

const useFetchTeamsByCompany = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchTeamsByCompany = useCallback(
    async (id) => {
      try {
        const { data } = await fetchData(`${BACKEND_URL}/company/${id}/team`, getAccessTokenSilently);
        setTeams(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { teams, loading, error, fetchTeamsByCompany };
};

const useFetchPlayersByTeam = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchPlayersByTeam = useCallback(
    async (id) => {
      setPlayers([]);
      if (!id) return;
      try {
        setLoading(true);
        setError(null);
        const { data } = await fetchData(`${BACKEND_URL}/team/${id}/player`, getAccessTokenSilently);
        setPlayers(data);
      } catch (error) {
        if (error.response.status === 404) return;
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { players, loading, error, fetchPlayersByTeam };
};

const useCreateTeam = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createTeam = async (team) => {
    try {
      await postData(`${BACKEND_URL}/team`, team, getAccessTokenSilently);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the team.';
      console.error(errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createTeam };
};

const useUpdateMetricValue = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateMetricValue = async (id, metricValue) => {
    try {
      await patchData(`${BACKEND_URL}/team/${id}/metrics`, { metricValue: metricValue }, getAccessTokenSilently);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while updating the metric.';
      console.error(errorMessage);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, updateMetricValue };
};

const useAssignMetric = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const assignMetric = useCallback(
    async (id, metricId) => {
      try {
        await patchData(`${BACKEND_URL}/team/${id}/metrics/${metricId}`, {}, getAccessTokenSilently);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, assignMetric };
};

const useFetchMetric = () => {
  const [metric, setMetric] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const fetchMetric = useCallback(
    async (id) => {
      setError(null);
      setLoading(true);
      try {
        const data = await fetchData(`${BACKEND_URL}/team/${id}/metrics`, getAccessTokenSilently);
        setMetric(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { metric, loading, error, fetchMetric };
};

export {
  useFetchTeam,
  useCreateTeam,
  useFetchPlayersByTeam,
  useFetchTeamsByCompany,
  useUpdateMetricValue,
  useAssignMetric,
  useFetchMetric,
};
