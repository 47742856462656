import { useState, useCallback, useEffect } from 'react';
import { fetchData, postData, putData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { setJackpot, useActor } from '../storage/storage';
import { useDispatch } from 'react-redux';

const useFetchJackpotList = () => {
  const actor = useActor();
  const [data, setData] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  const fetchJackpotsList = useCallback(async () => {
    const url = `${BACKEND_URL}/company/${actor.company_id}/jackpot`;
    setIsLoading(true);
    try {
      const { data } = await fetchData(url, getAccessTokenSilently);
      setData(data);
    } catch (error) {
      console.error('There was an error fetching the jackpots list!', error);
      setIsError('There was an error fetching the jackpots list!');
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, actor]);

  useEffect(() => {
    fetchJackpotsList();
  }, [fetchJackpotsList]);

  return { data, isLoading, isError, refetch: fetchJackpotsList };
};

const useFetchActiveJackpot = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchActiveJackpot = useCallback(
    async (companyId) => {
      const url = `${BACKEND_URL}/company/${companyId}/jackpot-for-game`;
      setLoading(true);
      setError(null);
      try {
        const data = await fetchData(url, getAccessTokenSilently);
        setJackpot(data, dispatch);
      } catch (error) {
        console.error('There was an error getting the active jackpot', error);
        setError('There was an error getting the active jackpot');
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently, dispatch]
  );

  return { loading, error, fetchActiveJackpot };
};

const useFetchJackpotById = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchJackpotById = useCallback(
    async (jackpotId) => {
      const url = `${BACKEND_URL}/jackpot/${jackpotId}`;
      setLoading(true);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the jackpot by Id!', error);
        const errorMessage = error.response?.data?.message || 'There was an error getting the jackpot by Id!';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, fetchJackpotById };
};

const useCreateJackpot = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const createJackpot = async (jackpot) => {
    try {
      await postData(`${BACKEND_URL}/jackpot`, jackpot, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while creating the jackpot.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while creating the jackpot.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, createJackpot };
};

const useUpdateJackpot = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const updateJackpot = async (id, jackpot) => {
    try {
      await putData(`${BACKEND_URL}/jackpot/${id}`, jackpot, getAccessTokenSilently);
    } catch (error) {
      console.error('Unexpected error occurred while updating the jackpot.');
      const errorMessage = error.response?.data?.message || 'Unexpected error occurred while updating the jackpot.';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, updateJackpot };
};

export { useFetchJackpotList, useFetchActiveJackpot, useFetchJackpotById, useCreateJackpot, useUpdateJackpot };
