import Loading from '../../../components/Loading.js';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Button, Form, FormGroup, Alert, Label, Col, Input, Row } from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton.js';
import '../../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import useMetricForm from '../../hooks/useMetricForm.js';

const MetricForm = () => {
  const { id } = useParams();
  const {
    metric,
    error,
    redirect,
    handleInputName,
    handleInputValue,
    handleDirectionChange,
    clearSuggested,
    calculateSuggested,
    handleSubmit,
  } = useMetricForm(id);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  if (redirect) return <Navigate to="/metrics" />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <h2 className="admin-text">{id ? 'Edit Metric' : 'Create new Metric'}</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <FormGroup>
            <Label className="admin-text" for="name" sm={2}>
              {t('form.name')}
            </Label>
            <Col sm={6}>
              <Input
                id="name"
                name="name"
                placeholder={t('form.name')}
                type="text"
                value={metric.name}
                onChange={handleInputName}
              />
            </Col>
          </FormGroup>
        </Row>
        <Row>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text">
                <Input
                  id="up"
                  name="direction"
                  type="radio"
                  checked={metric.direction === 'up'}
                  value="up"
                  onChange={handleDirectionChange}
                />
                &nbsp;Ascending
              </Label>
            </FormGroup>
          </Col>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text">
                <Input
                  id="down"
                  name="direction"
                  type="radio"
                  checked={metric.direction === 'down'}
                  value="down"
                  onChange={handleDirectionChange}
                />
                &nbsp;Descending
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text" for="level-0">
                {t('game.catalogue.bronze')}
              </Label>
              <Input id="level-0" name="bronze" type="text" value={metric.bronze} onChange={handleInputValue} />
            </FormGroup>
          </Col>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text" for="level-2">
                {t('game.catalogue.gold')}
              </Label>
              <Input id="level-2" name="gold" type="text" value={metric.gold} onChange={handleInputValue} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <Button onClick={clearSuggested}>Clear</Button>
          </Col>
          <Col sm={2}>
            <Button onClick={calculateSuggested}>Fill</Button>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text" for="level-1">
                {t('game.catalogue.silver')}
              </Label>
              <Input id="level-1" name="silver" type="text" value={metric.silver} onChange={handleInputValue} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text">Lower Limit</Label>
              <Input name="lower" type="text" value={metric.lower} onChange={handleInputValue} />
            </FormGroup>
          </Col>
          <Col sm={2}>
            <FormGroup>
              <Label className="admin-text">Upper Limit</Label>
              <Input name="upper" type="text" value={metric.upper} onChange={handleInputValue} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup row>
          <Col sm={6}>
            <ReturnButton to={'/metrics'} />
            <Button color="primary" type="submit">
              {t('buttons.ok')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(MetricForm, {
  onRedirecting: () => <Loading />,
});
