import { useState, useEffect, useCallback } from 'react';
import { fetchData, postData, patchData, putData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useActor } from '../storage/storage';

const useFetchExperienceList = (lang) => {
  const actor = useActor();
  const { getAccessTokenSilently } = useAuth0();
  const [experienceList, setExperienceList] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!actor) return;

    const fetchExperienceList = async () => {
      try {
        const { data: experienceList } = await fetchData(
          `${BACKEND_URL}/company/${actor.company_id}/experiences?lang=${lang}`,
          getAccessTokenSilently
        );
        setExperienceList(experienceList);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the experiences list!', error);
        setError(error);
      }
    };

    fetchExperienceList();
  }, [actor, lang, setExperienceList, getAccessTokenSilently]);

  return { experienceList, error };
};

const useExperiencesForPlayer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const fetchExperiencesForPlayer = useCallback(
    async (playerId) => {
      try {
        const url = `${BACKEND_URL}/player/${playerId}/experiences`;
        setLoading(true);
        const { data } = await fetchData(url, getAccessTokenSilently);
        setError(null);
        setExperiences(data);
      } catch (error) {
        if (error.status === 404) return;
        console.error('Error fetching experiences.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching experiences.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { experiences, error, loading, fetchExperiencesForPlayer };
};

const useFetchExperienceById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getExperienceById = useCallback(
    async (experienceId) => {
      const url = `${BACKEND_URL}/experience/${experienceId}?raw=true`;
      setLoading(true);
      setError(null);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the experience by Id!', error);
        const errorMessage = error.response?.data?.message || 'There was an error getting the experience by Id!';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getExperienceById };
};

const useCreateExperience = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const createExperience = async (data) => {
    setLoading(true);

    try {
      const result = await postData(`${BACKEND_URL}/experience`, data, getAccessTokenSilently);
      setResponse(result);
    } catch (error) {
      console.error('There was an error creating the experience!', error);
      const errorMessage = error.response?.data?.message || 'Error creating the experience!';
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, createExperience };
};

const useUpdateExperience = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const updateExperience = async (id, data) => {
    setLoading(true);
    try {
      const result = await putData(`${BACKEND_URL}/experience/${id}`, data, getAccessTokenSilently);
      setResponse(result);
    } catch (error) {
      console.error('There was an error updating the experience!', error);
      const errorMessage = error.response?.data?.message || 'Error updating the experience!';
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, updateExperience };
};

const useUpdateQuestion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const updateQuestion = async (experienceId, questionData) => {
    const url = `${BACKEND_URL}/experience/${experienceId}/questions`;
    setLoading(true);
    setError(null);

    try {
      await patchData(url, questionData, getAccessTokenSilently);
    } catch (error) {
      console.error('There was an error updating the question!', error);
      const errorMessage = error.response?.data?.message || 'Error updating the question!';
      setError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, updateQuestion };
};

export {
  useFetchExperienceList,
  useExperiencesForPlayer,
  useFetchExperienceById,
  useCreateExperience,
  useUpdateExperience,
  useUpdateQuestion,
};
