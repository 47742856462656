import { useCallback, useEffect, useState } from 'react';
import { useFetchJackpotById, useUpdateJackpot, useCreateJackpot } from '../../api/JackpotAPI';

import { useActor } from '../../storage/storage';
import { SUPPORTED_LANGUAGES } from '../../api/LanguageAPI';

const useJackpotForm = (id) => {
  const { fetchJackpotById } = useFetchJackpotById();
  const { updateJackpot } = useUpdateJackpot();
  const { createJackpot } = useCreateJackpot();
  const [jackpot, setJackpot] = useState({
    name: { en: '', es: '' },
    description: { en: '', es: '' },
    requirements: [],
    drawn_by: new Date(),
    url: null,
    cover: null,
    media_id: null,
    cover_media_id: null,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const actor = useActor();

  useEffect(() => {
    const fetchJackpot = async () => {
      if (!id) return;
      try {
        const jackpotData = await fetchJackpotById(id);
        setJackpot(jackpotData);
      } catch (err) {
        setError(err.message || 'Failed to fetch jackpot data.');
      }
    };

    fetchJackpot();
  }, [id, fetchJackpotById]);

  const handleJackpotInput = useCallback((event, language) => {
    const { name, value } = event.target;
    setJackpot((prevJackpot) => ({
      ...prevJackpot,
      [name]: name === 'name' || name === 'description' ? { ...prevJackpot[name], [language]: value } : value,
    }));
  }, []);

  const handleImage = (image) => {
    setJackpot((prev) => ({ ...prev, url: image.url, media_id: image.media_id }));
  };

  const handleImageCover = (image) => {
    setJackpot((prev) => ({ ...prev, cover: image.url, cover_media_id: image.media_id }));
  };

  const validateJackpot = useCallback(() => {
    if (!jackpot || !jackpot.name) return 'Jackpot name is required.';
    if (jackpot.description === undefined || jackpot.description === null) return 'Jackpot description is required.';
    if (!jackpot.requirements || jackpot.requirements.length === 0) return 'Requirements are required.';
    if (jackpot.media_id == null) return 'Image is required';
    if (jackpot.cover_media_id == null) return 'Cover image is required';
  }, [jackpot, id]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setError(null);

      setLoading(true);
      const validationError = validateJackpot();
      if (validationError) {
        setError(validationError);
        setLoading(false);
        return;
      }
      try {
        if (id) {
          await updateJackpot(id, jackpot);
        } else {
          const newJackpot = {
            ...jackpot,
            companyId: actor.company_id,
          };
          await createJackpot(newJackpot);
        }
        setRedirect(true);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [jackpot, validateJackpot, createJackpot, updateJackpot, id]
  );

  const addRequirement = useCallback(() => {
    const req = {};
    SUPPORTED_LANGUAGES.map((lang) => (req[lang] = ''));

    setJackpot((prevJackpot) => ({
      ...prevJackpot,
      requirements: [...prevJackpot.requirements, req],
    }));
  }, [jackpot, setJackpot]);

  const handleRequirementInput = useCallback(
    (event, idx, language) => {
      event.preventDefault();
      const { value } = event.target;
      let reqs = jackpot.requirements;

      reqs[idx][language] = value;

      setJackpot((prevJackpot) => ({
        ...prevJackpot,
        requirements: reqs,
      }));
    },
    [jackpot, setJackpot]
  );
  const removeRequirement = useCallback(
    (idx) => {
      const reqs = jackpot.requirements.filter((r, i) => i !== idx);
      setJackpot((prevJackpot) => ({
        ...prevJackpot,
        requirements: reqs,
      }));
    },
    [jackpot, setJackpot]
  );

  const handleDrawnBy = useCallback(
    (date) => {
      setJackpot((prevJackpot) => ({
        ...prevJackpot,
        drawn_by: date,
      }));
    },
    [jackpot, setJackpot]
  );

  return {
    jackpot,
    error,
    loading,
    redirect,
    handleSubmit,
    handleJackpotInput,
    addRequirement,
    handleRequirementInput,
    removeRequirement,
    handleDrawnBy,
    handleImage,
    handleImageCover,
  };
};

export default useJackpotForm;
