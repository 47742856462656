import { useCallback, useState } from 'react';
import { fetchData, patchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useImagesByCompany = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const fetchImagesByCompany = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const { data } = await fetchData(`${BACKEND_URL}/company/${id}/images`, getAccessTokenSilently);
        setError(null);
        setImages(data);
      } catch (error) {
        if (error.status === 404) return;
        console.error('Error fetching images.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching images.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { images, error, loading, fetchImagesByCompany };
};

const useGetImageUrl = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);

  const getImageUrl = useCallback(
    async (id) => {
      try {
        setLoading(true);
        const data = await fetchData(`${BACKEND_URL}/images/${id}/url`, getAccessTokenSilently);
        setError(null);
        setUrl(data);
      } catch (error) {
        console.error('Error fetching image url.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while fetching image url.';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );
  return { url, error, loading, getImageUrl };
};

const useUploadImage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const uploadImage = useCallback(
    async (companyId, image) => {
      try {
        setLoading(true);
        setError(null);
        const data = new FormData();
        data.append('companyId', companyId);
        data.append('image', image);
        await postData(`${BACKEND_URL}/images`, data, getAccessTokenSilently, 'multipart/form-data');
      } catch (error) {
        console.error('Unexpected error occurred while uploading the image.');
        const errorMessage = error.response?.data?.message || 'Unexpected error occurred while uploading the image.';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { error, loading, uploadImage };
};

const useChangeName = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const changeName = useCallback(
    async (id, data) => {
      try {
        setLoading(true);
        setError(null);
        await patchData(`${BACKEND_URL}/images/${id}/name`, data, getAccessTokenSilently);
      } catch (error) {
        console.error('Unexpected error occurred while changing the image name.');
        const errorMessage =
          error.response?.data?.message || 'Unexpected error occurred while changing the image name.';
        setError(errorMessage);
        throw new Error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { error, loading, changeName };
};

export { useImagesByCompany, useUploadImage, useChangeName, useGetImageUrl };
