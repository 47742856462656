import '../../assets/css/chest.css';
import chest from '../../assets/img/footer/chest.svg';
import { CHEST_AVAILABLE_TABS } from '../../utils/constants.js';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useActor } from '../../storage/storage';
const DEFAULT_ITEM_CLASSNAME = 'chest-tab';

const TabContainer = ({ selectedItem, setSelectedItem }) => {
  const { t, i18n } = useTranslation();
  const actor = useActor();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  const determineClass = (currentItem) => {
    if (currentItem !== selectedItem) return DEFAULT_ITEM_CLASSNAME;
    return DEFAULT_ITEM_CLASSNAME + ' chest-tab-active';
  };

  return (
    <div className="chest-tab-container">
      <div name="daily" className={determineClass(CHEST_AVAILABLE_TABS.DAILY_CHEST)}>
        <button onClick={() => setSelectedItem(CHEST_AVAILABLE_TABS.DAILY_CHEST)}>
          <img src={chest} alt="daily-chest" />
          {t('game.chest.tab.daily')}
        </button>
      </div>
      <div name="content" className={determineClass(CHEST_AVAILABLE_TABS.CHEST_CONTENT)}>
        <button onClick={() => setSelectedItem(CHEST_AVAILABLE_TABS.CHEST_CONTENT)}>
          {t('game.chest.tab.content')}
        </button>
      </div>
    </div>
  );
};

export default TabContainer;
