import React, { useEffect } from 'react';

import dailyStreakImg from '../../assets/img/icons/daily-streak.svg';
import correctAnswersImg from '../../assets/img/icons/correct-answers.svg';
import playTimeImg from '../../assets/img/icons/play-time.svg';
import accuracyImg from '../../assets/img/icons/accuracy.svg';

import '../../assets/css/profile/profile-stats.css';
import { useActor, usePlayer } from '../../storage/storage';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import StatsItem from './StatsItem.js';
import { secondsToMMSS } from '../../utils/date.js';

const Stats = () => {
  const player = usePlayer();
  const actor = useActor();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(actor.lang);
  }, [i18n, actor]);

  return (
    <>
      <div className="stats-profile-container">
        <StatsItem img={dailyStreakImg} value={player.streak} text={t('game.stats.streak')} />
        <StatsItem
          img={playTimeImg}
          value={secondsToMMSS(player.max_time_played_in_secs)}
          text={t('game.stats.maxTime')}
        />
      </div>
      <div className="stats-profile-container">
        <StatsItem img={accuracyImg} value={`${player.max_accuracy}%`} text={t('game.stats.maxAccuracy')} />
        <StatsItem img={correctAnswersImg} value={player.max_score} text={t('game.stats.maxScore')} />
      </div>
    </>
  );
};

export default Stats;
