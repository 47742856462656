import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input } from 'reactstrap';
import { Navigate, useNavigate, useParams, Link } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useAcceptMembership, useFetchMembership } from '../../api/MembershipAPI';
import { useCreateInventory } from '../../api/InventoryAPI';
import { setActor, setInventory, setPlayer, useAppAuthenticated } from '../../storage/storage';

import Loading from '../../components/Loading';

import '../../i18n/i18n';

import openInNewTab from '../../assets/img/icons/open-in-new-tab.svg';
import { useGetIp } from '../../api/IpAPI';

const tosUrl = 'https://www.galileilearning.com/tos';
const privacyUrl = 'https://www.galileilearning.com/privacy';

const NewMember = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { membership, error: fetchMembershipError, fetchMembership } = useFetchMembership();
  const { error: acceptMembershipError, acceptMembership } = useAcceptMembership();
  const { ip } = useGetIp();
  const { createInventory } = useCreateInventory();
  const isAppAuthenticated = useAppAuthenticated();

  const [error, setError] = useState('');
  const [redirect, setRedirect] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    channel: 'wa',
    terms: false,
    privacy: false,
    communication: false,
  });

  const [datetime, _] = useState(new Date());

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (!id) return;
    try {
      fetchMembership(id);
    } catch (error) {
      handleError(fetchMembershipError || error.message);
    }
  }, [id, fetchMembership]);

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errorForm = validateFormData();
    if (errorForm) {
      handleError(errorForm);
      return;
    }
    handleError('');
    try {
      const newMember = await acceptMembership(id, {
        ...formData,
        datetime,
        ip,
        lang: i18n.resolvedLanguage,
      });
      await saveLocalStorageNewMember(newMember);
      setRedirect(true);
    } catch (error) {
      handleError(acceptMembershipError || error.message);
    }
  };

  const validateFormData = () => {
    if (!formData.fullName) return 'The name is required.';
    if (!formData.terms) return t('membership.noTerms');
    if (!formData.privacy) return t('membership.noPrivacy');
    if (!formData.communication) return t('membership.noCommunication');
    return null;
  };

  const saveLocalStorageNewMember = async (newMember) => {
    if (newMember && newMember.player) {
      const inventory = await createInventory(newMember.player.player_id);
      setInventory(inventory, dispatch);
      setActor(newMember.actor, dispatch);
      setPlayer(newMember.player, dispatch);
    } else {
      setActor(newMember, dispatch);
    }
  };

  const translationValues = () => {
    return {
      date: datetime,
      time: datetime,
      ip,
      formatParams: {
        date: { year: 'numeric', month: 'long', day: 'numeric' },
        time: {
          year: undefined,
          month: undefined,
          day: undefined,
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        },
      },
    };
  };

  if (isAppAuthenticated || redirect) return <Navigate to="/landing" />;

  if (!membership && !fetchMembershipError)
    return (
      <div className="text-center Init my-5">
        <p className="lead">{t('membership.no')}</p>
        <Button onClick={() => navigate('/logout')} color="primary">
          {t('buttons.back')}
        </Button>
      </div>
    );

  if (fetchMembershipError)
    return (
      <>
        <Alert color="danger">{fetchMembershipError}</Alert>
        <Button color="primary" onClick={() => navigate('/logout')}>
          {t('buttons.back')}
        </Button>
      </>
    );
  if (membership && (membership.isAccepted || membership.isExpired)) return <Navigate to="/no-membership" />;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      <h2 className="admin-text">{t('membership.new')}</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label className="admin-text" for="fullName" sm={1}>
            {t('form.name')}:
          </Label>
          <Col sm={6}>
            <Input
              id="fullName"
              name="fullName"
              placeholder={t('form.name')}
              type="text"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <h3>{t('membership.channel')}</h3>
          {['wa'].map((option) => (
            <Label key={option} className="admin-text" sm={8}>
              <Input
                id={option}
                name="channel"
                type="radio"
                checked={formData.channel === option}
                value={option}
                onChange={handleInputChange}
              />
              &nbsp;{option === 'wa' ? 'WhatsApp' : 'SMS'}
            </Label>
          ))}
        </FormGroup>
        <FormGroup row>
          <Label className="admin-text" for="terms" sm={8}>
            <Input id="terms" name="terms" type="checkbox" checked={formData.terms} onChange={handleInputChange} />
            &nbsp;{t('membership.terms')}&nbsp;
            <Link to={`${tosUrl}`} target={'_blank'}>
              {t('membership.link')}
            </Link>
            &nbsp;
            <img src={openInNewTab} alt={'Open in new tab'} style={{ width: '15px', height: '15px' }} />.
          </Label>
          <Label className="admin-text" for="privacy" sm={8}>
            <Input
              id="privacy"
              name="privacy"
              type="checkbox"
              checked={formData.privacy}
              onChange={handleInputChange}
            />
            &nbsp;{t('membership.privacy')}&nbsp;
            <Link to={`${privacyUrl}`} target={'_blank'}>
              {t('membership.link')}
            </Link>
            &nbsp;
            <img src={openInNewTab} alt={'Open in new tab'} style={{ width: '15px', height: '15px' }} />.
          </Label>
          <Label className="admin-text" for="communication" sm={8}>
            <Input
              id="communication"
              name="communication"
              type="checkbox"
              checked={formData.communication}
              onChange={handleInputChange}
            />
            &nbsp;{t('membership.communication')}
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Button color="primary" type="submit" disabled={!!validateFormData()}>
              {t('buttons.accept')}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Label className="admin-text">
              <i>{t('membership.acceptedOn', translationValues())}</i>
            </Label>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(NewMember, {
  onRedirecting: () => <Loading />,
});
