import { useEffect, useState } from 'react';

const useGetIp = () => {
  const [ip, setIP] = useState(null);

  useEffect(() => {
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => setIP(data.ip))
      .catch((error) => console.error('Error fetching IP:', error));
  }, [setIP]);

  return { ip };
};

export { useGetIp };
