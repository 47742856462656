import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';

const ChangeImageNameModal = ({ name, isOpen, toggle, onNameChange, onSave }) => {
  const ok = async () => {
    await onSave();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-dark">
        New Image Name
      </ModalHeader>
      <ModalBody>
        <Input
          type="textarea"
          placeholder="Image name..."
          className="text-dark"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={ok}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeImageNameModal;
