import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useRunLottery } from '../../../api/LotteryAPI';
import Loading from '../../../components/Loading';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const isEmpty = (object) => !object || Object.keys(object).length === 0;

const LotteryModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { winner, loading, error, getWinner } = useRunLottery();
  const toggle = () => setShowModal(!showModal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    if (showModal) {
      getWinner();
    }
  }, [i18n, showModal, getWinner]);

  return (
    <>
      <Button color="info" onClick={toggle}>
        {t('lottery.run')}
      </Button>
      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader className="admin-text" toggle={toggle}>
          {isEmpty(winner) && error && t('game.profile.error')}
          {winner && t('lottery.result.title')}
        </ModalHeader>
        <ModalBody>
          {isEmpty(winner) && loading && <Loading />}
          {isEmpty(winner) && error && <div className="admin-text">{t('tables.error')}</div>}
          {winner && (
            <div className="admin-text">
              {t('lottery.result.body')} <b>{winner.full_name}</b>.
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            {t('buttons.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

LotteryModal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LotteryModal;
