import { useCallback, useState } from 'react';
import { useChangeName } from '../../api/ImageAPI';
import useMessageAlert from './useMessageAlert';

const useImageChangeName = () => {
  const { message, showError, showSuccess, cleanMessage } = useMessageAlert();
  const [toEdit, setToEdit] = useState({
    id: '',
    name: '',
  });
  const { loading, changeName } = useChangeName();

  const handleNameChange = (value) => setToEdit((prev) => ({ ...prev, name: value }));

  const handleSubmit = useCallback(async () => {
    cleanMessage();
    if (toEdit.name == null || toEdit.name === '') {
      showError('Invalid Name');
      return;
    }
    if (toEdit.id == null || toEdit.id === '') {
      showError('Invalid id');
      return;
    }

    try {
      await changeName(toEdit.id, { name: toEdit.name });
      showSuccess('Image name saved successfully');
    } catch (error) {
      showError(error);
    }
  }, [toEdit, changeName, showError, cleanMessage, showSuccess]);

  return { message, toEdit, loading, setToEdit, handleSubmit, handleNameChange };
};

export default useImageChangeName;
