import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../state/auth';
import { initExperience } from '../state/experience';
import { initInventory } from '../state/inventory';
import { initPlayer } from '../state/player';
import { updateTeam } from '../state/team';
import { initCompany } from '../state/company';
import { initPrizes } from '../state/prizes';
import { initJackpot } from '../state/jackpot';
import { acceptPrivacyPolicy } from '../state/privacyPolicy';
import { initQuestions } from '../state/questions';

const useActor = () => {
  const { actor } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return actor ? actor : getStorage('actor', dispatch, login);
};

const useAppAuthenticated = () => {
  const actor = useActor();
  return !!actor;
};

const useIsPrivacyPolicyAccepted = () => {
  const isAccepted = useSelector((state) => state.privacyPolicy?.isAccepted);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAccepted) return;
    const storedValue = sessionStorage.getItem('isPrivacyPolicyAccepted');
    if (storedValue !== null) dispatch(acceptPrivacyPolicy(JSON.parse(storedValue)));
  }, [isAccepted, dispatch]);
  return isAccepted;
};

const useExperience = () => {
  const { experience } = useSelector((state) => state.experience);
  const dispatch = useDispatch();
  return experience ? experience : getStorage('experience', dispatch, initExperience);
};

const useInventory = () => {
  const { inventory } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  return inventory ? inventory : getStorage('inventory', dispatch, initInventory);
};

const usePlayer = () => {
  const { player } = useSelector((state) => state.player);
  const dispatch = useDispatch();
  return player ? player : getStorage('player', dispatch, initPlayer);
};

const usePrizes = () => {
  const { prizes } = useSelector((state) => state.prizes);
  const dispatch = useDispatch();
  return prizes.length ? prizes : getStorage('prizes', dispatch, initPrizes);
};

const useTeam = () => {
  const { team } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  return team ? team : getStorage('team', dispatch, updateTeam);
};

const useCompany = () => {
  const { company } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const defaultValue = {
    company_id: 'c892cedc-3230-46b1-9033-02b5533ffe34',
    url: '/logo192.png',
  };
  return company ? company : getStorage('company', dispatch, initCompany, defaultValue);
};

const useJackpot = () => {
  const { jackpot } = useSelector((state) => state.jackpot);
  const dispatch = useDispatch();
  return jackpot ? jackpot : getStorage('jackpot', dispatch, initJackpot);
};

const useQuestions = () => {
  const { questions } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  return questions.length ? questions : getStorage('questions', dispatch, initQuestions);
};

const setJackpot = (entry, dispatch) => {
  setStorage('jackpot', entry, dispatch, initJackpot);
};

const setPrizes = (entry, dispatch) => {
  setStorage('prizes', entry, dispatch, initPrizes);
};

const setInventory = (entry, dispatch) => {
  setStorage('inventory', entry, dispatch, initInventory);
};

const setPlayer = (entry, dispatch) => {
  setStorage('player', entry, dispatch, initPlayer);
};

const setTeam = (entry, dispatch) => {
  setStorage('team', entry, dispatch, updateTeam);
};

const setActor = (entry, dispatch) => {
  setStorage('actor', entry, dispatch, login);
};

const setExperience = (entry, dispatch) => {
  setStorage('experience', entry, dispatch, initExperience);
};

const setCompany = (entry, dispatch) => {
  setStorage('company', entry, dispatch, initCompany);
};

const setQuestions = (entry, dispatch) => {
  setStorage('questions', entry, dispatch, initQuestions);
};

const setIsPrivacyPolicyAccepted = (accepted, dispatch) => {
  sessionStorage.setItem('isPrivacyPolicyAccepted', JSON.stringify(accepted));
  dispatch(acceptPrivacyPolicy(accepted));
};

const getStorage = (key, dispatch, dispatcher, defaultValue) => {
  const entry = sessionStorage.getItem(key);
  if (entry) {
    const parsedEntry = JSON.parse(entry);
    dispatch(dispatcher(parsedEntry));
    return parsedEntry;
  }
  return defaultValue;
};

const setStorage = (key, entry, dispatch, dispatcher) => {
  dispatch(dispatcher(entry));
  sessionStorage.setItem(key, JSON.stringify(entry));
};

export {
  useActor,
  useAppAuthenticated,
  useExperience,
  useInventory,
  usePlayer,
  usePrizes,
  useTeam,
  useCompany,
  useJackpot,
  useQuestions,
  setJackpot,
  setPrizes,
  setInventory,
  setPlayer,
  setTeam,
  setActor,
  setExperience,
  setCompany,
  setQuestions,
  useIsPrivacyPolicyAccepted,
  setIsPrivacyPolicyAccepted,
};
