import { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Table, FormGroup, Alert, Button, ButtonGroup, Input } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { DECIMAL_REGEXP } from '../../../utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faRankingStar } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../../components/Loading';
import ReturnButton from '../../components/commons/ReturnButton';
import { useActor } from '../../../storage/storage';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useFetchTeamsByCompany, useUpdateMetricValue } from '../../../api/TeamAPI';
import PrizeLevelBadge from '../../components/commons/PrizeLevelBadge';

const TeamList = () => {
  const { t, i18n } = useTranslation();
  const { teams, loading, fetchTeamsByCompany } = useFetchTeamsByCompany();
  const { updateMetricValue } = useUpdateMetricValue();
  const [inputValues, setInputValues] = useState({});
  const actor = useActor();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (actor.company_id) fetchTeamsByCompany(actor.company_id);
  }, [actor.company_id, fetchTeamsByCompany]);

  useEffect(() => {
    if (!teams.length) return;
    const initialValues = teams.reduce((acc, team) => {
      acc[team.team_id] = team.metric_value;
      return acc;
    }, {});
    setInputValues(initialValues);
  }, [teams]);

  const handleInputChange = (teamId, e) => {
    const inputValue = e.target.value;
    if (DECIMAL_REGEXP.test(inputValue)) {
      setInputValues((prev) => ({ ...prev, [teamId]: inputValue }));
    } else {
      e.preventDefault();
    }
  };

  const handleButtonClick = async (teamId) => {
    await updateMetricValue(teamId, inputValues[teamId]);
    await fetchTeamsByCompany(actor.company_id);
  };

  if (loading) return <Loading />;

  return (
    <Card className="my-2" color="light" outline>
      <CardHeader>
        <h3>{t('menu.myTeam')}</h3>
      </CardHeader>
      <CardBody>
        <ButtonGroup>
          <ReturnButton to={'/landing'} />
          <NavLink className="btn btn-primary" to="new">
            {t('buttons.add')}
          </NavLink>
        </ButtonGroup>
        <FormGroup row>{!teams.length && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

        {teams.length && (
          <Table hover borderless responsive size="sm" striped className="text-center">
            <thead>
              <tr>
                <th>{t('tables.name')}</th>
                <th>{t('catalogue.level')}</th>
                <th>Metric Name</th>
                <th style={{ width: '200px' }}>Metric Value</th>
                <th colSpan={2}>{t('tables.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team) => (
                <tr key={team.name}>
                  <td>{team.name}</td>
                  <td>
                    <PrizeLevelBadge level={team.prize_level} />
                  </td>
                  <td>{team.metricname ? team.metricname : 'unassigned'}</td>
                  <td>
                    {team.metricname && parseInt(team.members) > 0 ? (
                      <div className="d-flex align-items-center">
                        <Input
                          type="text"
                          min="0"
                          className="me-1"
                          value={inputValues[team.team_id]}
                          onChange={(e) => handleInputChange(team.team_id, e)}
                        />
                        <Button color="primary" onClick={() => handleButtonClick(team.team_id)}>
                          {t('buttons.ok')}
                        </Button>
                      </div>
                    ) : (
                      team.metric_value
                    )}
                  </td>
                  <td>
                    <Button color="link" title="See players" size="sm" onClick={() => navigate(`${team.team_id}`)}>
                      <FontAwesomeIcon icon={faPeopleGroup} className="faWhite" />
                    </Button>
                    {!team.metricDetails && (
                      <Button
                        color="link"
                        title="Assign metric"
                        size="sm"
                        onClick={() => navigate(`${team.team_id}/metrics`)}
                      >
                        <FontAwesomeIcon icon={faRankingStar} className="faWhite" />
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
};
//TODO: Use i18n
export default withAuthenticationRequired(TeamList, {
  onRedirecting: () => <Loading />,
});
