import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Row } from 'reactstrap';
import ReturnButton from '../../components/commons/ReturnButton';
import React, { useRef } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../../components/Loading';
import MessageAlert from '../../components/commons/MessageAlert';
import useImagesForm from '../../hooks/useImagesForm';
import ImagesUploadTable from '../../components/commons/ImagesUploadTable';
import AddButton from '../../components/commons/AddButton';

const ImagesForm = () => {
  const { images, errors, uploads, progress, processing, message, loading, handleSubmit, handleImages, removeImage } =
    useImagesForm();
  const inputImagesRef = useRef();

  const handleClick = () => inputImagesRef.current.click();

  return (
    <>
      <MessageAlert message={message} />
      <Card className="my-3">
        <CardHeader>
          <h3>New Images</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <FormGroup>
                <input
                  type="file"
                  name="images"
                  style={{ display: 'none' }}
                  accept="image/*"
                  ref={inputImagesRef}
                  multiple
                  onChange={handleImages}
                />
              </FormGroup>
              <Col md="2">
                <ReturnButton to="/images" />
              </Col>
              <Col md="2">{!processing && <AddButton action={handleClick} />}</Col>
              <Col md="2">
                {!processing && (
                  <Button
                    color="success"
                    type="submit"
                    disabled={loading || errors.some((e) => e) || images.length === 0}
                  >
                    Upload
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
          {loading && <progress value={progress} max={images.length} />}
          <h5 className="mt-4 mb-3 text-dark font-weight-bold">To Upload</h5>
          <Row className="mb-3">
            <ImagesUploadTable
              images={images}
              errors={errors}
              uploads={uploads}
              processing={processing}
              remove={removeImage}
            />
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(ImagesForm, {
  onRedirecting: () => <Loading />,
});
