import { useEffect, useCallback, useState } from 'react';
import { useActor } from '../../storage/storage';
import useMessageAlert from './useMessageAlert';
import { useRedeemReward, useRewardsByCompany } from '../../api/RewardAPI';

const useRewardList = () => {
  const { message, showError, cleanMessage } = useMessageAlert();
  const {
    rewards: rewardsFetched,
    pageInfo: pageInfoFetched,
    error: errorFetching,
    loading,
    fetchRewardsByCompany,
  } = useRewardsByCompany();
  const { error: errorRedeem, loading: loadingRedeem, redeemReward } = useRedeemReward();
  const [rewards, setRewards] = useState([]);
  const [toMark, setToMark] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    totalRowCount: 0,
    hasNextPage: false,
    pageSize: 10,
  });
  const actor = useActor();

  useEffect(() => {
    if (!actor) return;
    cleanMessage();
    fetchRewardsByCompany(actor.company_id);
  }, [fetchRewardsByCompany, actor]);

  useEffect(() => {
    if (!errorFetching && !errorRedeem) return;
    showError(errorFetching || errorRedeem);
  }, [errorFetching, errorRedeem, showError]);

  useEffect(() => {
    if (loading) return;
    setRewards(rewardsFetched);
    setPageInfo(pageInfoFetched);
  }, [rewardsFetched, pageInfoFetched, loading]);

  useEffect(() => {
    setDisabled(toMark.length === 0 || loadingRedeem);
  }, [toMark, loadingRedeem]);

  const redeem = async (rewardId) => {
    try {
      await redeemReward(rewardId);
      setRewards(rewards.filter((reward) => reward.reward_id !== rewardId));
      setPageInfo((prev) => ({ ...prev, totalRowCount: prev.totalRowCount - 1 }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleRedeem = useCallback(async () => {
    if (toMark.length === 0) return;
    cleanMessage();
    for (const rewardId of toMark) {
      await redeem(rewardId);
    }
  }, [toMark, redeem, cleanMessage]);

  return {
    message,
    loading,
    rewards,
    pageInfo,
    disabled,
    setToMark,
    handleRedeem,
  };
};

export default useRewardList;
